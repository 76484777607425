import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import * as datajson from '../../constans/json';

import axios from 'axios';
import { useForm, Controller, Feedback, set } from 'react-hook-form';
import canaletica from '../../asses/img/canalEtica.png';
import ayuda from '../../asses/img/icon_ayuda.png';
import UploadEvidence from './uploadEvidence';

import Select from 'react-select';
import { URL_AWS, URL_EMPLOYEE } from '../../constans/servidor';
import generateNumberCase from '../../utils/generateCodeCase';
const Denuncia = () => {
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [testigo, setTestigo] = useState('');
  const [anonimo, setAnonimo] = useState('');
  const [ayudaI, setAyuda] = useState(false);
  const [response, setResponse] = useState();
  const [politicas, setPoliticas] = useState('');
  const [respuesta, setRespuesta] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [name, setName] = useState([]);
  const [archivos, setUrl] = useState();
  const [termsConditions, setTermsConditions] = useState(false);

  const handleEnlaceClick = (event) => {
    // Evita el comportamiento predeterminado del enlace
    event.preventDefault();
    // Cambiar la variable a verdadero cuando el enlace es clicado
    setAyuda(true);
  };

  const containsOnlyLetters = (nameAnonimus) => {
    return (
      /^[a-zA-Z\s]*$/.test(nameAnonimus) && /\d/.test(nameAnonimus) === false
    );
  };

  const onSubmit = async (data) => {
    setShowLoading(true);
    if (!termsConditions) {
      toast.error('Debes aceptar nuestros terminos y condiciones!');
      setShowLoading(false);
      return;
    }
    if (anonimo === true) {
      if (data.celularPersona.length !== 10) {
        toast.error(`Numero de celular del denunciante no valido!`);
        setShowLoading(false);
        return;
      }
      if (data.celularPersona.charAt(0) !== '3') {
        toast.error(`Numero de celular del denunciante no valido!`);
        setShowLoading(false);
        return;
      }
      if (!containsOnlyLetters(data.nombrePersona)) {
        toast.error(`Ingrese su nombre con solo letras!`);
        setShowLoading(false);
        return;
      }
    }
    if (testigo === true) {
      if (data.celular.length !== 10) {
        toast.error(`Numero de celular del testigo no valido!`);
        setShowLoading(false);
        return;
      }
      if (data.celular.charAt(0) !== '3') {
        toast.error(`Numero de celular del testigo no valido!`);
        setShowLoading(false);
        return;
      }
      if (!containsOnlyLetters(data.nombres)) {
        toast.error(`Ingrese el nombre del testigo solo con letras!`);
        setShowLoading(false);
        return;
      }
    }
    const numberCase = await generateNumberCase();
    const parameters = {
      city: data.ciudad.label,
      relationshipWithTheCompany: data.relacion.value,
      complaintCategory: data.categoria.value,
      witnessComplaint: testigo === true ? 'true' : 'false',
      name: data.nombrePersona,
      phone: data.celularPersona,
      detailComplaint: data.mensaje,
      anonymous: anonimo === true ? 'true' : 'false',
      witnessName: data.nombres,
      witnessPhone: data.celular,
      email: data.correo,
      numberCase,
    };
    if (name.length) {
      let dataS3 = name.map(() => ({
        type: 'CANAL DE ETICA',
        numberCase: numberCase,
      }));
      const uploadS3 = await axios.post(
        `${URL_EMPLOYEE}/outsource/getUploadInvoice`,
        dataS3
      );

      if (uploadS3.data.statusCode === 200) {
        for (let i = 0; i < name.length; i++) {
          const file = name[i];
          if (name[i].size > 10 * 1024 * 1024) {
            toast.error(
              `El archivo ${name[i].name} excede el límite de tamaño (10MB)`
            );
            setShowLoading(false);
            return;
          }
          const url = uploadS3.data.data[i].urlSignature;

          const fileContent = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => {
              reject(new Error('Error al leer el archivo'));
            };
            reader.readAsArrayBuffer(file);
          });

          let fileType;

          if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
            fileType = 'image/jpeg';
          } else if (file.type === 'image/png') {
            fileType = 'image/png';
          } else if (file.type === 'application/pdf') {
            fileType = 'application/pdf';
          }

          let blobData = new Blob([fileContent], {
            type: fileType,
          });

          await axios.put(url, blobData, {
            headers: {
              'Content-Type': fileType,
              'Access-Control-Allow-Origin': '*',
            },
          });
        }
      }
    }
    console.log('parameters', parameters);
    // reset({ data });
    const response = await axios.post(
      // `http://localhost:3000/dev/sendMail`,
      `${URL_AWS}/ContactCategory/sendMail`,
      parameters
    );
    if (response.data.statusCode === 200) {
      setResponse(response.data.numberCase);
      setRespuesta(true);
      reset({
        data,
        name,
      });
      setTestigo(false);
      setShowLoading(false);
    } else {
      toast.error(response.data.message);
      setShowLoading(false);
      return;
    }
  };
  return (
    <>
      <Container
        className=" etica_body"
        style={{
          marginBottom: '60px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: 'white',
          padding: '10px ',
          borderRadius: '20px',
          marginBottom: '10px',
          boxShadow: 'box-shadow: 15px 10px 10px black',
        }}
      >
        <h3
          className="text-center"
          style={{ width: '60%', marginBottom: '60px' }}
        >
          Estamos aquí para ayudarte.
        </h3>
        <p
          className="text-center"
          style={{ width: '60%', marginBottom: '60px' }}
        >
          Mantengamos la comunicación abierta y transparente. Tu opinión y tus
          preocupaciones son importantes para nosotros.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '60%', marginBottom: '60px' }}
        >
          <Form.Group
            controlId="formBasicCheckbox"
            style={{ marginBottom: '10px' }}
          >
            <Controller
              name="politicas"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Check
                  type="checkbox"
                  label="Acepta nuestras"
                  onChange={(evt) => {
                    setTermsConditions(evt.target.checked);
                    onChange(evt.target.checked); // Actualiza el valor en el controlador
                  }}
                  checked={termsConditions}
                />
              )}
            />
            <Link className="enlace-check" to="/terminosycondiciones">
              politicas de tratamiento de datos
            </Link>
            {errors.politicas && (
              <Form.Text id="passwordHelpBlock" className="error">
                es obligatorio
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group
            controlId="formBasicPassword"
            style={{ marginBottom: '20px' }}
          >
            <Form.Label className="text-testigo">¿Denuncia anónima?</Form.Label>
            <Controller
              name="anonimo"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Check
                  type="checkbox"
                  label="No"
                  onChange={(evt) => {
                    setAnonimo(evt.target.checked);
                    onChange(evt.target.checked); // Actualiza el valor en el controlador
                  }}
                  checked={anonimo}
                />
              )}
            />
          </Form.Group>
          {anonimo ? (
            <>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginBottom: '20px' }}
                  >
                    <Form.Label>Nombres</Form.Label>
                    <Controller
                      name="nombrePersona"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'El campo es obligatorio',
                        },
                      }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="text"
                          onBlur={onBlur}
                          onChange={(data) => onChange(data)}
                          value={value}
                          maxLength={30}
                        />
                      )}
                    />
                    {errors.nombrePersona && (
                      <Form.Text id="passwordHelpBlock" className="error">
                        Este campo es obligatorio
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginBottom: '20px' }}
                  >
                    <Form.Label>Número de Celular</Form.Label>
                    <Controller
                      name="celularPersona"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'El campo es obligatorio',
                        },
                        pattern: {
                          value: /^3[0-9]{9}$/,
                          message:
                            'El número de teléfono debe comenzar con 3 y tener exactamente 10 dígitos',
                        },
                      }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="number"
                          onBlur={onBlur}
                          onChange={(data) => onChange(data)}
                          value={value}
                          minLength={10}
                        />
                      )}
                    />
                    {errors.celularPersona && (
                      <Form.Text className="error">
                        {errors.celularPersona.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginBottom: '20px' }}
                  >
                    <Form.Label>Correo Electronico</Form.Label>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          required: 'El campo es obligatorio',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Correo electrónico no válido',
                          },
                        },
                      }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="email"
                          onBlur={onBlur}
                          onChange={(data) => onChange(data)}
                          value={value}
                          maxLength={30}
                        />
                      )}
                    />
                    {errors.email && (
                      <Form.Text className="error">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : null}
          <Form.Group
            controlId="formBasicEmail"
            style={{ marginBottom: '20px' }}
          >
            <Form.Label>Relación con la compañía</Form.Label>
            <Controller
              name="relacion"
              control={control}
              defaultValue={{ value: 'Colaborador', label: 'Colaborador' }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  options={datajson.vinculoCompañia}
                  className="custon-select"
                  classNamePrefix="custon-select"
                  onChange={(data) => onChange(data)}
                  value={value}
                  defaultValue={{
                    value: 'Colaborador',
                    label: 'Colaborador',
                  }}
                />
              )}
            />
            {errors.relacion && (
              <Form.Text id="passwordHelpBlock" className="error">
                La ciudad es obligatoria
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group
            controlId="formBasicEmail"
            style={{ marginBottom: '20px' }}
          >
            <Form.Label>Ciudad</Form.Label>
            <Controller
              name="ciudad"
              control={control}
              defaultValue={{ value: '11001', label: 'BOGOTÁ D.C' }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  options={datajson.ciudaddes}
                  className="custon-select"
                  classNamePrefix="custon-select"
                  onChange={(data) => onChange(data)}
                  value={value}
                  defaultValue={{ value: '11001', label: 'BOGOTÁ D.C' }}
                />
              )}
            />
            {errors.ciudad && (
              <Form.Text id="passwordHelpBlock" className="error">
                La ciudad es obligatoria
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group
            controlId="formBasicEmail"
            style={{ marginBottom: '20px' }}
          >
            <Form.Label>Categoría de denuncia</Form.Label>
            <Controller
              name="categoria"
              control={control}
              defaultValue={{
                value: 'Comportamientos poco éticos',
                label: 'Comportamientos poco éticos',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div>
                  <Select
                    options={datajson.categoriaDenuncia}
                    className="custon-select"
                    classNamePrefix="custon-select"
                    onChange={(data) => onChange(data)}
                    value={value}
                    style={{ marginBottom: '10px' }}
                    defaultValue={{
                      value: 'Comportamientos poco éticos',
                      label: 'Comportamientos poco éticos',
                    }}
                  />
                  {value &&
                    value.value === 'Otra' && ( // Modificado
                      <Form.Control
                        onBlur={(e) =>
                          onChange({
                            value: e.target.value,
                            label: e.target.value,
                          })
                        }
                        type="text"
                        placeholder="Escribe otra categoria"
                      />
                    )}
                </div>
              )}
            />
            {errors.categoria && (
              <Form.Text id="passwordHelpBlock" className="error">
                La ciudad es obligatoria
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group
            controlId="formBasicPassword"
            style={{ marginBottom: '20px' }}
          >
            <Form.Label>
              Describe detalladamente la denuncia que deseas realizar (fecha,
              rango horario, nombre, lugar exacto - nombre o ubicación del
              parqueadero o área administrativa).
            </Form.Label>
            <Controller
              name="mensaje"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'El mensaje es obligatorio',
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  onChange={(data) => onChange(data)}
                  as="textarea"
                  value={value}
                  placeholder="Detalle de la denuncia"
                  defaultValue=" "
                />
              )}
            />
            {errors.mensaje && (
              <Form.Text id="passwordHelpBlock" className="error">
                El mensaje es obligatorio
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group
            controlId="formFile"
            className="mb-3"
            style={{ marginBottom: '20px' }}
          >
            <Form.Label>
              En caso de tener documentos o fotografías que respalden tu
              denuncia, adjúntalos aquí.
              <br></br>Seleccionar Archivo (máx. 2, 10MB cada uno)
            </Form.Label>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Text id="passwordHelpBlock" className="eligedoc">
                  Elíge los documentos (PNG, JPG, PDF)
                </Form.Text>
                <UploadEvidence
                  className="container-file"
                  archivo={archivos}
                  value={name}
                  onChange={(e) => setName(e)}
                  setName={setName}
                  isNameEmpty={name}
                />
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group
            controlId="formBasicPassword"
            style={{ marginBottom: '20px' }}
          >
            <Form.Label className="text-testigo">
              ¿Tienes información de otras personas que puedan ampliar esta
              denuncia?
            </Form.Label>
            <Controller
              name="testigo"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Check
                  type="checkbox"
                  label="Si"
                  onChange={(evt) => {
                    setTestigo(evt.target.checked);
                    onChange(evt.target.checked); // Actualiza el valor en el controlador
                  }}
                  checked={testigo}
                />
              )}
            />
          </Form.Group>
          {testigo ? (
            <>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginBottom: '20px' }}
                  >
                    <Form.Label>Nombres</Form.Label>
                    <Controller
                      name="nombres"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'El campo es obligatorio',
                        },
                      }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="text"
                          onBlur={onBlur}
                          onChange={(data) => onChange(data)}
                          value={value}
                          maxLength={30}
                        />
                      )}
                    />
                    {errors.nombres && (
                      <Form.Text id="passwordHelpBlock" className="error">
                        {errors.nombres.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginBottom: '20px' }}
                  >
                    <Form.Label>Número de Celular</Form.Label>
                    <Controller
                      name="celular"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'El campo es obligatorio',
                        },
                        pattern: {
                          value: /^3[0-9]{9}$/,
                          message:
                            'El número de teléfono debe comenzar con 3 y tener exactamente 10 dígitos',
                        },
                      }}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="number"
                          onBlur={onBlur}
                          onChange={(data) => onChange(data)}
                          value={value}
                          minLength={10}
                        />
                      )}
                    />
                    {errors.celular && (
                      <Form.Text className="error">
                        {errors.celular.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : null}
          {showLoading ? (
            <div className="container-sniper"></div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                type="submit"
                variant="primary"
                className="mtop-20 mbottom-20"
                style={{ width: '300px' }}
              >
                Enviar
              </Button>
            </div>
          )}
        </form>
      </Container>
      <Modal show={ayudaI} onHide={() => setAyuda(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Para tener en cuenta</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            En este canal usted podrá reportar sospechas de conductas delictivas
            tales como robo, soborno, conflictos de interés,  estafa, fraude,
            acoso laboral, entre otros, que atenten contra el Reglamento Interno
            de Parking International S.A.S., el Código Ético y las normas
            legales vigentes.
          </p>
          <p>IMPORTANTE</p>
          <ol>
            <li>
              Para solicitudes que no estén relacionadas con el Código
              Ético como quejas, siniestros, por favor diríjase a los canales
              habilitados de atención al cliente o al empleado.
            </li>
            <li>
              Los informes realizados a través de esta línea serán tratados con
              la máxima confidencialidad y se tomarán las medidas apropiadas
              para abordar cada caso.
            </li>
            <li>
              Es importante destacar que la línea ética está disponible las 24
              horas del día, los 7 días de la semana.
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setAyuda(false);
            }}
            variant="secondary"
          >
            Atras
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={respuesta} onHide={() => setRespuesta(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Caso N° {response}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Hemos recibido tu denuncia. Tu número de caso es {response}.
            Estaremos en contacto pronto para dar seguimiento al proceso.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setRespuesta(false);
            }}
            variant="primary"
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Denuncia;
