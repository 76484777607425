
import React, { useState, useContext, useEffect } from 'react';
import { Link, Router, useHistory } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import NavDropdown from "react-bootstrap/NavDropdown";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import { BsArrowLeft, BsPlusCircle } from "react-icons/bs";
import { BiKey } from "react-icons/bi";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import iconUser from '../../asses/img/icon-user.png';
import iconUser2x from '../../asses/img/icon-user@2x.png';
import iconUser3x from '../../asses/img/icon-user@3x.png';
import iconUserAzul from '../../asses/img/icon-user-azul.png';
import iconUserAzul2x from '../../asses/img/icon-user-azul@2x.png';
import iconUserAzul3x from '../../asses/img/icon-user-azul@3x.png';

const PerfilUsuariosComponent = (props) => {
    const { resultado } = props

    return (
        <>
            <div className={resultado['extracto']['categoria'] === "GREEN" ? "be-info mtop-20" : "be-info mtop-20 azul"}>

                <Row>
                    <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                        <h3 className="texto-titulo mtop-20">Informacion del Usuario</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="caja-perfil">
                            <div className="caja-perfil-header">
                                <p>Datos</p>
                                <div className="icon-content">
                                {resultado['extracto']['categoria'] === "GREEN" ?
                                        <img src={iconUser} className="icon-icon" srcSet={`${iconUser2x} 2x, ${iconUser3x} 3x`} alt="Informacion del usuario" />
                                        :
                                        <img src={iconUserAzul} className="icon-icon" srcSet={`${iconUserAzul2x} 2x, ${iconUserAzul3x} 3x`} alt="Informacion del usuario" />
                                        }
                                </div>
                            </div>
                            <div className="caja-perfil-body">
                                <Row>
                                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Nombres</Form.Label>
                                            <div className="control-icon">
                                                <Form.Control
                                                    type="text"
                                                    defaultValue={resultado['nombre']}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Apellidos</Form.Label>
                                            <div className="control-icon">
                                                <Form.Control
                                                    type="text"
                                                    value={resultado['apellido']}
                                                    disabled={true}
                                                    placeholder="Ingresa el número de tu identificación"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Fecha de Nacimiento</Form.Label>
                                            <div className="control-icon">
                                                <Form.Control
                                                    type="text"
                                                    value={resultado['fechaNacimiento']}
                                                    disabled={true}
                                                    placeholder="Ingresa el número de tu identificación"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Correo Electronico</Form.Label>
                                            <div className="control-icon">
                                                <Form.Control
                                                    type="text"
                                                    value={resultado['email']}
                                                    disabled={true}
                                                    placeholder="Ingresa el número de tu identificación"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Dirección</Form.Label>
                                            <div className="control-icon">
                                                <Form.Control
                                                    type="text"
                                                    value={resultado['direccion']}
                                                    disabled={true}
                                                    placeholder="Ingresa el número de tu identificación"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Genero</Form.Label>
                                            <div className="control-icon">
                                                <Form.Control
                                                    as="select"
                                                    disabled={true}
                                                    value={resultado['genero']}
                                                    placeholder="Elige una opción"
                                                >
                                                    <option>Elige una opción</option>
                                                    <option value="M">Másculino</option>
                                                    <option value="F">Femenino</option>
                                                </Form.Control>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Número de Celular</Form.Label>
                                            <div className="control-icon">
                                                <Form.Control
                                                    type="text"
                                                    value={resultado['celular']}
                                                    disabled={true}
                                                    placeholder="Ingresa el número de tu identificación"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={5} lg={5} xl={5}>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Número de Identificación</Form.Label>
                                            <div className="control-icon">
                                                <Form.Control
                                                    type="text"
                                                    value={resultado['cedula']}
                                                    disabled={true}
                                                    placeholder="Ingresa el número de tu identificación"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default PerfilUsuariosComponent;