import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import axios from 'axios';

import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import FooterComponen from '../../components/footer';
import { db, stg, analytic } from '../../server/firebase';

import nosotros from '../../asses/img/nosotros1.png';
import nosotros2 from '../../asses/img/nosotros2.png';
import nosotros3 from '../../asses/img/nosotros3.png';
import nosotros4 from '../../asses/img/nosotros4.png';
import nosotros5 from '../../asses/img/nosotros5-1.png';
import icono1 from '../../asses/img/nosotrosicono.png';
import icono2 from '../../asses/img/nosotrosicono2.png';
import icono3 from '../../asses/img/nosotrosicono3.png';
import apple from '../../asses/img/apple.png';
import android from '../../asses/img/android.png';

const TerminosPage = () => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Terminos y condiciones Parking` });
  }, []);

  return (
    <>
      <Container className="mtop-30">
        <Row
          className="justify-content-center mtop-50 mbottom-50"
          id="contacto"
        >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="texto-titulo">Terminos y Condiciones</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 style={{ fontWeight: 600, marginBottom: '1rem' }}>
              POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES
            </h4>
            <p>
              <strong>PARKING INTERNATIONAL S.A.S.,</strong> en cumplimiento de
              la Ley 1581 de 2012, el Decreto Reglamentario 1377 de 2013 y demás
              normativas que complementan o modifican el régimen de protección
              de datos personales en Colombia, ha implementado su Política de
              Tratamiento y Protección de Datos Personales. Esta política tiene
              como objetivo regular la recolección, almacenamiento, uso,
              circulación, transmisión y eliminación de los datos personales
              bajo su responsabilidad, garantizando la protección de los
              derechos de los titulares y asegurando un tratamiento adecuado de
              la información, de acuerdo con los principios de confidencialidad,
              seguridad y legalidad exigidos por la normativa vigente.
            </p>
            <p
              style={{
                background: 'rgba(128, 162, 108)',
                color: 'white',
                alignContent: 'center',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              1. RESPONSABLE DEL TRATAMIENTO Y PROTECCIÓN DE DATOS
            </p>
            <p>
              <strong>PARKING INTERNATIONAL S.A.S.,</strong> sociedad comercial
              legalmente constituida, identificada con el NIT. 860.058.760-1,
              con domicilio principal en Carrera 14 No. 89 - 48 oficina 202
              Edificio Novanta de la ciudad de Bogotá D.C., República de
              Colombia, actúa como responsables del Tratamiento y Protección de
              Datos Personales. Para cualquier consulta, solicitud o reclamo
              relacionado con el tratamiento de datos personales, se establecen
              los siguientes medios de contacto:
            </p>
            <ul>
              <li>Teléfono:57(1) 5190077</li>
              <li>
                Correo electrónico:
                <a href="mailto:oficial.protecciondatos@parking.net.co">
                  oficial.protecciondatos@parking.net.co
                </a>
                ,
                <a href="mailto:administracion@parking.net.co">
                  administracion@parking.net.co
                </a>
              </li>
              <li>
                Dirección física: Carrera 14 No. 89 – 48, oficina 202, edificio
                Novanta, Bogotá D.C.
              </li>
              <li>
                Página web:
                <a
                  href="https://www.parking.net.co/#/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.parking.net.co/#/
                </a>
              </li>
            </ul>
            <p
              style={{
                background: 'rgba(128, 162, 108)',
                color: 'white',
                alignContent: 'center',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              2. OBJETIVO
            </p>
            <p>
              <strong>PARKING INTERNATIONAL S.A.S.,</strong> implementará todas
              las acciones que tenga a su alcance para el cumplimiento de la
              protección y tratamiento de datos personales de los que sea
              responsable, en especial para proteger los derechos a la
              privacidad, la intimidad y el buen nombre y el derecho a conocer,
              actualizar y rectificar los datos de los titulares recogidos en
              las bases de datos propias o tratadas por encargo de terceros.
            </p>
            <p
              style={{
                background: 'rgba(128, 162, 108)',
                color: 'white',
                alignContent: 'center',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              3. ALCANCE
            </p>
            <p>
              El alcance de esta política abarca todos los procedimientos
              relacionados con el Tratamiento de Datos Personales llevados a
              cabo por <strong>PARKING INTERNATIONAL S.A.S.</strong> Esto
              incluye los datos de clientes, trabajadores, proveedores y demás
              partes interesadas, así como cualquier información personal que la
              empresa reciba, genere o maneje en el curso de sus operaciones.
              Esta política se aplica a toda la información personal registrada
              en las bases de datos de{' '}
              <strong>PARKING INTERNATIONAL S.A.S.</strong> quien actúa como
              responsable del tratamiento de los datos personales, con el
              objetivo de asegurar el cumplimiento de la normativa colombiana en
              protección de datos y garantizar la seguridad y privacidad de la
              información.
            </p>
            <p
              style={{
                background: 'rgba(128, 162, 108)',
                color: 'white',
                alignContent: 'center',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              OBLIGACIONES
            </p>
            <p>
              Las obligaciones de <strong>PARKING INTERNATIONAL S.A.S.,</strong>{' '}
              son las siguientes:
            </p>
            <div>
              <ol type="a">
                <li>
                  Garantizar al Titular, en todo tiempo, el pleno y efectivo
                  ejercicio del derecho de Hábeas Data.
                </li>
                <li>
                  Solicitar y conservar, en las condiciones previstas en la
                  presente ley, copia de la respectiva autorización otorgada por
                  el Titular.
                </li>
                <li>
                  Informar debidamente al Titular sobre la finalidad de la
                  recolección y los derechos que le asisten por virtud de la
                  autorización otorgada.
                </li>
                <li>
                  Conservar la información bajo las condiciones de seguridad
                  necesarias para impedir su adulteración, pérdida, consulta,
                  uso o acceso no autorizado o fraudulento.
                </li>
                <li>
                  Garantizar que la información que se suministre al Encargado
                  del Tratamiento sea veraz, completa, exacta, actualizada,
                  comprobable y comprensible.
                </li>
                <li>
                  Actualizar la información, comunicando de forma oportuna al
                  Encargado del Tratamiento, todas las novedades respecto de los
                  datos que previamente le haya suministrado y adoptar las demás
                  medidas necesarias para que la información suministrada a este
                  se mantenga actualizada.
                </li>
                <li>
                  Rectificar la información cuando sea incorrecta y comunicar lo
                  pertinente al Encargado del Tratamiento.
                </li>
                <li>
                  Suministrar al Encargado del Tratamiento, según el caso,
                  únicamente datos cuyo Tratamiento esté previamente autorizado
                  de conformidad con lo previsto en la presente ley.
                </li>
                <li>
                  Exigir al Encargado del Tratamiento en todo momento, el
                  respeto a las condiciones de seguridad y privacidad de la
                  información del Titular.
                </li>
                <li>
                  Tramitar las consultas y reclamos formulados en los términos
                  señalados en la presente ley.
                </li>
                <li>
                  Adoptar un manual interno de políticas y procedimientos para
                  garantizar el adecuado cumplimiento de la presente ley y en
                  especial, para la atención de consultas y reclamos.
                </li>
                <li>
                  Informar al Encargado del Tratamiento cuando determinada
                  información se encuentra en discusión por parte del Titular,
                  una vez se haya presentado la reclamación y no haya finalizado
                  el trámite respectivo.
                </li>
                <li>
                  Informar a solicitud del Titular sobre el uso dado a sus
                  datos.
                </li>
                <li>
                  Informar a la autoridad de protección de datos cuando se
                  presenten violaciones a los códigos de seguridad y existan
                  riesgos en la administración de la información de los
                  Titulares.
                </li>
                <li>
                  Cumplir las instrucciones y requerimientos que imparta la
                  Superintendencia de Industria y Comercio.
                </li>
              </ol>
            </div>
            <p
              style={{
                background: 'rgba(128, 162, 108)',
                color: 'white',
                alignContent: 'center',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              TRATAMIENTO Y FINALIDAD
            </p>
            <p>
              En cumplimiento de las autorizaciones brindadas de manera previa,
              explícita e informada, exceptuando aquellos casos en los que la
              ley dispense tal autorización,{' '}
              <strong>PARKING INTERNATIONAL S.A.S.,</strong>
              procederá a la recopilación, almacenamiento, custodia,
              procesamiento, circulación, transmisión o transferencia, y
              tratamiento de los datos personales con las siguientes finalidades
              generales:
            </p>
            <p style={{ fontWeight: 600 }}>
              Finalidades del Tratamiento para Clientes
            </p>
            <div>
              <ol type="a">
                <li>
                  Ejecución y gestión de contratos con clientes, incluyendo la
                  entrega de productos o servicios, facturación y gestión de
                  pagos.
                </li>
                <li>
                  Efectuar encuestas de satisfacción respecto de los bienes y
                  servicios ofrecidos por{' '}
                  <strong>PARKING INTERNATIONAL S.A.S.,</strong>
                </li>
                <li>
                  Contactar al Titular a través de medios telefónicos para
                  realizar encuestas, estudios y/o confirmación de datos
                  personales necesarios para la ejecución de una relación
                  contractual.
                </li>
                <li>
                  Contactar al Titular a través de medios electrónicos – SMS o
                  chat para el envío de noticias relacionadas con campañas de
                  fidelización o mejora de servicio.
                </li>
                <li>
                  Contactar al Titular a través de correo electrónico para el
                  envío de extractos, estados de cuenta o facturas en relación
                  con las obligaciones derivadas del contrato celebrado entre
                  las partes.
                </li>
                <li>
                  Cumplir con obligaciones legales y regulatorias, como la
                  emisión de facturas y la prevención de LA/FT/FPADM.
                </li>
                <li>
                  Enviar comunicaciones comerciales, promociones y ofertas
                  personalizadas, siempre y cuando se obtenga el consentimiento
                  previo del cliente o exista una base legal que lo permita.
                </li>
                <li>
                  Gestionar consultas, reclamaciones y solicitudes de los
                  clientes, así como proporcionar soporte técnico y atención al
                  cliente.
                </li>
                <li>
                  Realizar análisis de mercado, estudios de satisfacción del
                  cliente y mejora continua de los productos y servicios
                  ofrecidos.
                </li>
                <li>
                  Garantizar la seguridad de los sistemas y la protección de la
                  información, así como prevenir el fraude, el acceso no
                  autorizado y otros riesgos para la empresa y los clientes.
                </li>
                <li>Mantener un relacionamiento con los clientes.</li>
                <li>
                  Verificar el cumplimiento de los compromisos adquiridos por
                  los clientes y con los clientes.
                </li>
                <li>Actualizar los datos de los clientes.</li>
                <li>
                  Mantener una relación comercial estable y duradera con los
                  clientes.
                </li>
                <li>Cumplir con las obligaciones contractuales.</li>
                <li>
                  Enviar información sobre actualizaciones o modificaciones al
                  contrato.
                </li>
                <li>
                  Efectuar estudios en relación con los intereses de los
                  Clientes para crear y/o mejorar productos y servicios con base
                  en dichos intereses.
                </li>
                <li>
                  Los datos personales podrán ser transferidos a la entidad
                  controlante de la empresa, debidamente inscrita en la Cámara
                  de Comercio, con el fin de asegurar el cumplimiento de las
                  obligaciones contractuales y comerciales.
                </li>
                <li>
                  Los datos personales podrán ser transmitidos a la entidad
                  controlante de la empresa, inscrita en la Cámara de Comercio,
                  para garantizar la correcta prestación de los servicios y el
                  desarrollo de las actividades empresariales.
                </li>
                <li>
                  Consultar en listas restrictivas y bases de datos públicas de
                  carácter nacional e internacional, que incluyen, pero no se
                  limitan, a las siguientes: i) OFAC, ii) Comité de Sanciones
                  del Consejo de Seguridad de las Naciones Unidas, iii) más
                  buscados del FBI, iv) Interpol, v) DEA, vi) Policía Nacional
                  de Colombia, vii) Contraloría General de la República, viii)
                  Procuraduría General de la Nación y, ix) todas aquellas que
                  sean requeridas para la prevención de lavado de activos,
                  financiación del terrorismo y soborno trasnacional.
                </li>
              </ol>
            </div>
            <p style={{ fontWeight: 600 }}>
              Finalidades del Tratamiento para Proveedores
            </p>
            <div>
              <ol type="a">
                <li>
                  Gestionar los proveedores y realizar verificación del
                  cumplimiento de los acuerdos y compromisos establecidos en
                  función de la relación comercial existente.
                </li>
                <li>
                  Contactar al Titular a través de correo electrónico para el
                  envío de extractos, estados de cuenta o facturas en relación
                  con las obligaciones derivadas del contrato celebrado entre
                  las partes.
                </li>
                <li>
                  Cumplir con obligaciones legales y regulatorias, como la
                  emisión de facturas.
                </li>
                <li>
                  Creación de bases de datos adaptadas a las características y
                  perfiles de los titulares de datos personales, conforme a lo
                  establecido en la legislación vigente.
                </li>
                <li>
                  Se podrán obtener y tratar datos personales para cumplir con
                  otras finalidades necesarias para el cumplimiento de los
                  objetivos de la empresa, siempre en cumplimiento de la
                  normativa vigente.
                </li>
                <li>
                  Presentación de quejas, denuncias o reportes: Se efectuarán
                  ante las autoridades competentes en caso de incumplimiento de
                  contratos.
                </li>
                <li>
                  Solicitar el abastecimiento de bienes y servicios necesarios
                  para el desarrollo del objeto social.
                </li>
                <li>
                  Realizar los pagos por la venta de bienes o la prestación de
                  servicios.
                </li>
                <li>
                  Gestionar los certificados tributarios por las retenciones
                  tributarias.
                </li>
                <li>Actualizar los datos de los proveedores.</li>
                <li>
                  Mantener una relación comercial estable y duradera con los
                  proveedores.
                </li>
                <li>
                  Los datos personales podrán ser transferidos a la entidad
                  controlante de la empresa, debidamente inscrita en la Cámara
                  de Comercio, con el fin de asegurar el cumplimiento de las
                  obligaciones contractuales y comerciales.
                </li>
                <li>
                  Los datos personales podrán ser transmitidos a la entidad
                  controlante de la empresa, inscrita en la Cámara de Comercio,
                  para garantizar la correcta prestación de los servicios y el
                  desarrollo de las actividades empresariales.
                </li>
                <li>
                  Consultar en listas restrictivas y bases de datos públicas de
                  carácter nacional e internacional, que incluyen, pero no se
                  limitan, a las siguientes: i) OFAC, ii) Comité de Sanciones
                  del Consejo de Seguridad de las Naciones Unidas, iii) más
                  buscados del FBI, iv) Interpol, v) DEA, vi) Policía Nacional
                  de Colombia, vii) Contraloría General de la República, viii)
                  Procuraduría General de la Nación y, ix) todas aquellas que
                  sean requeridas para el cumplimiento de las Políticas de
                  prevención de lavado de activos, financiación del terrorismo y
                  soborno trasnacional.
                </li>
              </ol>
            </div>
            <p style={{ fontWeight: 600 }}>
              Finalidades del Tratamiento para Trabajadores, Practicantes y
              Extrabajadores
            </p>
            <div>
              <ol type="a">
                <li>
                  Dar cumplimiento a las obligaciones contraídas por PARKING
                  INTERNATIONAL S.A.S., con el Titular de la Información, con
                  relación al pago de salarios, prestaciones sociales y demás
                  retribuciones consagradas en el contrato de trabajo o según lo
                  disponga la ley.
                </li>
                <li>
                  Efectuar las gestiones pertinentes, comerciales y/o
                  publicitarias para el desarrollo del objeto social de la
                  compañía en lo que tiene que ver con el cumplimiento del
                  objeto del contrato de trabajo celebrado con el Titular de la
                  información.
                </li>
                <li>
                  Ofrecer programas de bienestar corporativo y planificar
                  actividades empresariales, para el titular y sus beneficiarios
                  (hijos, cónyuge, compañero permanente).
                </li>
                <li>
                  Suministrar la información a terceros con los cuales{' '}
                  <strong>PARKING INTERNATIONAL S.A.S.,</strong> tenga relación
                  contractual y que sea necesario entregársela para el
                  cumplimiento del objeto contratado.
                </li>
                <li>
                  Suministrar información de contacto a la fuerza comercial y/o
                  red de distribución, telemercadeo, investigación de mercados y
                  cualquier tercero con el cual{' '}
                  <strong>PARKING INTERNATIONAL S.A.S.,</strong>
                  tenga un vínculo contractual para el desarrollo de actividades
                  de ese tipo (investigación de mercados y telemercadeo, etc)
                  para la ejecución de estas.
                </li>
                <li>
                  Gestionar la información para conceder a los trabajadores las
                  licencias correspondientes, según lo establecido en las leyes
                  laborales y en el Reglamento Interno de Trabajo de la Empresa.
                </li>
                <li>
                  Gestionar la información para expedir certificaciones a los
                  trabajadores.
                </li>
                <li>
                  Gestionar la información para proceder con el pago de
                  prestaciones sociales y aportes al sistema de seguridad
                  social.
                </li>
                <li>Actualizar los datos de los trabajadores.</li>
                <li>Conceder vacaciones.</li>
                <li>
                  Enviar información sobre actualizaciones o modificaciones al
                  contrato.
                </li>
                <li>
                  Cumplir con las disposiciones de los sistemas de gestión de la
                  Empresa, entre los que se incluye, pero no se limita al
                  Sistema de Gestión de Seguridad y Salud en el Trabajo (SST).
                </li>
                <li>
                  Efectuar los estudios de seguridad que resulten necesarios.
                </li>
                <li>Programar Capacitaciones.</li>
                <li>Realización de evaluaciones de desempeño.</li>
                <li>Ejecución de procesos disciplinarios.</li>
                <li>Contactar a familiares en casos de emergencia.</li>
                <li>
                  Implementación de sistemas biométricos y sistemas de circuito
                  cerrado de televisión de la empresa. El tratamiento de dichos
                  datos se realiza únicamente con el fin de garantizar la
                  seguridad de las personas e instalaciones de la EMPRESA.
                </li>
                <li>Todas aquellas que se deriven de la relación laboral.</li>
                <li>
                  Procesos de certificaciones laborales de extrabajadores.
                </li>
                <li>
                  Los datos personales podrán ser transferidos a la entidad
                  controlante de la empresa, debidamente inscrita en la Cámara
                  de Comercio, con el fin de asegurar el cumplimiento de las
                  obligaciones contractuales y comerciales.
                </li>
                <li>
                  Los datos personales podrán ser transmitidos a la entidad
                  controlante de la empresa, inscrita en la Cámara de Comercio,
                  para garantizar la correcta prestación de los servicios y el
                  desarrollo de las actividades empresariales.
                </li>
                <li>
                  Consultar en listas restrictivas y bases de datos públicas de
                  carácter nacional e internacional, que incluyen, pero no se
                  limitan, a las siguientes: i) OFAC, ii) Comité de Sanciones
                  del Consejo de Seguridad de las Naciones Unidas, iii) más
                  buscados del FBI, iv) Interpol, v) DEA, vi) Policía Nacional
                  de Colombia, vii) Contraloría General de la República, viii)
                  Procuraduría General de la Nación y, ix) todas aquellas que
                  sean requeridas para la prevención de lavado de activos,
                  financiación del terrorismo y soborno trasnacional.
                </li>
              </ol>
            </div>
            <p style={{ fontWeight: 600 }}>
              Finalidades del Tratamiento para Candidatos a Trabajadores,
              Contratistas, Proveedores o Practicantes.
            </p>
            <div>
              <ol type="a">
                <li>Establecer contacto dentro del proceso de selección.</li>
                <li>
                  Enviar información de los avances del proceso de selección.
                </li>
                <li>
                  Realizar estudios y análisis de seguridad o confiabilidad al
                  candidato.
                </li>
                <li>
                  Realizar exámenes médicos de preingreso para el caso de
                  candidatos a trabajadores.
                </li>
                <li>
                  Enviar información sobre la contratación o demás obligaciones
                  a las que haya lugar.
                </li>
                <li>
                  Consultar en listas restrictivas y bases de datos públicas de
                  carácter nacional e internacional, que incluyen, pero no se
                  limitan, a las siguientes: i) OFAC, ii) Comité de Sanciones
                  del Consejo de Seguridad de las Naciones Unidas, iii) más
                  buscados del FBI, iv) Interpol, v) DEA, vi) Policía Nacional
                  de Colombia, vii) Contraloría General de la República, viii)
                  Procuraduría General de la Nación y, ix) todas aquellas que
                  sean requeridas para la prevención de lavado de activos,
                  financiación del terrorismo y soborno trasnacional.
                </li>
                <li>
                  Acceder en forma gratuita, a través de los canales dispuestos
                  por LA EMPRESA, a sus Datos Personales que hayan sido objeto
                  de tratamiento.
                </li>
              </ol>
            </div>
            <p
              style={{
                background: 'rgba(128, 162, 108)',
                color: 'white',
                alignContent: 'center',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES
            </p>
            <div>
              <p>
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> en calidad de
                responsable del tratamiento de datos personales, obtiene la
                autorización previa, expresa, informada y libre de los titulares
                a través de diversos medios que garanticen el consentimiento
                inequívoco. Estos medios incluyen documentos físicos, formatos
                electrónicos, llamadas telefónicas (que serán grabadas para
                guardar la prueba de la autorización), sitios web y aplicaciones
                a través de casillas de verificación, para confirmar la
                aceptación del titular. Previamente,{' '}
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> informará a los
                titulares sobre las finalidades específicas del tratamiento de
                sus datos personales.
              </p>
              <p>
                Cuando <strong>PARKING INTERNATIONAL S.A.S.,</strong> actúe como
                ENCARGADO del tratamiento, verificará que los responsables de
                los datos personales hayan obtenido la autorización necesaria de
                los titulares, conforme a lo dispuesto en la normativa vigente.
              </p>
              <p>
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> implementará
                mecanismos claros y accesibles para la obtención de esta
                autorización, como la aceptación mediante formularios, conductas
                inequívocas en medios electrónicos (casillas de verificación o
                “opt-in”), grabación de llamadas, o la firma de documentos. En
                ausencia de esta autorización, los datos no serán tratados ni
                almacenados.
              </p>
              <p>
                Los titulares de los datos personales podrán, en cualquier
                momento, revocar la autorización otorgada o solicitar la
                supresión de sus datos, siempre que no exista una obligación
                legal o contractual que lo impida.{' '}
                <strong>PARKING INTERNATIONAL S.A.S.,</strong>
                ofrecerá medios sencillos para que los titulares puedan ejercer
                estos derechos, al menos por el mismo medio en el que se otorgó
                la autorización. La revocatoria podrá ser total, cesando todo
                tratamiento de los datos, o parcial, aplicándose solo a ciertas
                finalidades, en cuyo caso{' '}
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> podrá continuar
                tratando los datos para las finalidades no revocadas.
              </p>
            </div>
            <p
              style={{
                background: 'rgba(128, 162, 108)',
                color: 'white',
                alignContent: 'center',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              DERECHOS DE LOS TITULARES
            </p>
            <div>
              <p>
                Los Titulares del Tratamiento de Datos cuentan con los
                siguientes derechos:
              </p>
              <ol type="a">
                <li>
                  Como titular de sus datos personales Usted tiene derecho a:
                </li>
                <li>
                  Acceder de forma gratuita a los datos proporcionados que hayan
                  sido objeto de tratamiento.
                </li>
                <li>
                  Conocer, actualizar y rectificar su información frente a datos
                  parciales, inexactos, incompletos, fraccionados, que induzcan
                  a error, o aquellos cuyo tratamiento esté prohibido o no haya
                  sido autorizado.
                </li>
                <li>Solicitar prueba de la autorización otorgada.</li>
                <li>
                  Presentar ante la Superintendencia de Industria y Comercio
                  (SIC) quejas por infracciones a lo dispuesto en la
                  normatividad vigente.
                </li>
                <li>
                  Revocar la autorización y/o solicitar la supresión del dato,
                  siempre que no exista un deber legal o contractual que impida
                  eliminarlos.
                </li>
                <li>
                  Abstenerse de responder las preguntas sobre datos sensibles.
                </li>
                <li>
                  Tendrá carácter facultativo las respuestas que versen sobre
                  datos sensibles o sobre datos de las niñas y niños y
                  adolescentes.
                </li>
              </ol>
            </div>
            <p
              style={{
                background: 'rgba(128, 162, 108)',
                color: 'white',
                alignContent: 'center',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              CONFIDENCIALIDAD Y SEGURIDAD DE LAS BASES DE DATOS
            </p>
            <div>
              <p>
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> pone todos los
                recursos humanos, técnicos y tecnológicos que estén a su
                alcance, haciendo su mejor esfuerzo, para brindar seguridad y
                confidencialidad a los datos personales de los cuales es
                responsable o encargado del tratamiento.
              </p>
              <p>
                En cuanto a la confidencialidad,{' '}
                <strong>PARKING INTERNATIONAL S.A.S.,</strong>
                se compromete a suscribir acuerdos de confidencialidad con
                terceros en los eventos que se celebren convenios para compartir
                datos personales para la oferta de servicios de valor agregado
                de carácter jurídico, comercial, de fidelización y de servicios.
              </p>
            </div>
            <div>
              <p
                style={{
                  background: 'rgba(128, 162, 108)',
                  color: 'white',
                  alignContent: 'center',
                  textAlign: 'center',
                  fontWeight: 700,
                }}
              >
                TRANSMISIÓN DE DATOS PERSONALES
              </p>
              <p>
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> podrá transmitir
                los datos personales a terceros encargados del tratamiento,
                tanto en Colombia como en el exterior, para cumplir con las
                finalidades autorizadas por los titulares. Antes de obtener la
                autorización,
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> informará
                claramente si los datos personales serán objeto de transmisión a
                terceros encargados. Estos terceros estarán obligados a cumplir
                con las medidas de seguridad y protección exigidas por la Ley
                1581 de 2012 y demás normativas aplicables. Una vez cumplidas
                las finalidades para las cuales se transmitieron los datos,
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> solicitará la
                eliminación de dicha información de las bases de datos de los
                encargados, salvo que exista una disposición legal o contractual
                que justifique su conservación.
              </p>
            </div>
            <div>
              <p
                style={{
                  background: 'rgba(128, 162, 108)',
                  color: 'white',
                  alignContent: 'center',
                  textAlign: 'center',
                  fontWeight: 700,
                }}
              >
                TRANSFERENCIA DE DATOS PERSONALES
              </p>
              <p>
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> podrá transferir
                los datos personales a terceros, tanto en Colombia como en el
                exterior, en desarrollo de su objeto social y para cumplir con
                las finalidades autorizadas por los titulares. Previamente,{' '}
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> informará al
                titular si los datos serán objeto de transferencia, para que el
                titular otorgue su autorización informada. Los terceros
                receptores de los datos se comprometen a adoptar las medidas
                técnicas, humanas y administrativas necesarias para proteger la
                integridad, confidencialidad y seguridad de los datos
                transferidos, conforme a lo establecido en la Ley 1581 de 2012 y
                demás normativas vigentes. Cualquier uso o tratamiento de los
                datos fuera de las finalidades autorizadas estará prohibido,
                salvo que se obtenga una nueva autorización del titular.{' '}
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> supervisará el
                cumplimiento de estas medidas por parte de los terceros
                receptores de los datos.
              </p>
            </div>
            <div>
              <p
                style={{
                  background: 'rgba(128, 162, 108)',
                  color: 'white',
                  alignContent: 'center',
                  textAlign: 'center',
                  fontWeight: 700,
                }}
              >
                DATOS DE NIÑOS, NIÑAS Y ADOLESCENTES
              </p>
              <p>
                En el tratamiento de datos personales{' '}
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> asegurará el
                respeto a los derechos prevalentes de los menores (niños, niñas,
                y adolescentes). En caso de recaudarlos dará cumplimiento a lo
                señalado en el artículo 7 de la Ley 1581 de 2012.
              </p>
            </div>
            <div>
              <p
                style={{
                  background: 'rgba(128, 162, 108)',
                  color: 'white',
                  alignContent: 'center',
                  textAlign: 'center',
                  fontWeight: 700,
                }}
              >
                ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS
              </p>
              <p>
                El área de Protección de Datos Personales es la dependencia que
                tiene a cargo dar trámite a las solicitudes de los titulares
                para hacer efectivos sus derechos.
              </p>
              <p>
                En <strong>PARKING INTERNATIONAL S.A.S.,</strong> hemos
                designado canales para atender peticiones, consultas y reclamos
                sobre datos personales, a través de los cuales se asegura una
                respuesta rápida y efectiva, cumpliendo con la normativa de
                protección de datos:
              </p>
              <ul>
                <li>Teléfono:57(1) 5190077</li>
                <li>
                  Correo electrónico:
                  <a href="mailto:oficial.protecciondatos@parking.net.co">
                    oficial.protecciondatos@parking.net.co
                  </a>{' '}
                  -{' '}
                  <a href="mailto:administracion@parking.net.co">
                    administracion@parking.net.co
                  </a>
                </li>
                <li>
                  Dirección física: Carrera 14 No. 89 – 48, oficina 202,
                  edificio Novanta, Bogotá D.C.
                </li>
              </ul>
            </div>
            <div>
              <p
                style={{
                  background: 'rgba(128, 162, 108)',
                  color: 'white',
                  alignContent: 'center',
                  textAlign: 'center',
                  fontWeight: 700,
                }}
              >
                PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA
              </p>
              <p>
                En cumplimiento de las normas sobre protección de datos
                personales, <strong>PARKING INTERNATIONAL S.A.S.,</strong>{' '}
                presenta el procedimiento y requisitos mínimos para el ejercicio
                de sus derechos:
              </p>
              <p>
                Para la radicación en los correos:{' '}
                <a href="mailto:oficial.protecciondatos@parking.net.co">
                  oficial.protecciondatos@parking.net.co
                </a>{' '}
                -{' '}
                <a href="mailto:administracion@parking.net.co">
                  administracion@parking.net.co
                </a>{' '}
                y posterior atención de su solicitud le solicitamos suministrar
                la siguiente información:
              </p>
              <ol type="a">
                <li>Nombre completo y apellidos</li>
                <li>
                  Datos de contacto (Dirección física y/o electrónica y
                  teléfonos de contacto)
                </li>
                <li>Medios para recibir respuesta a su solicitud</li>
                <li>
                  Motivo(s)/hecho(s) que dan lugar al reclamo con una breve
                  descripción del derecho que desea ejercer (conocer,
                  actualizar, rectificar, solicitar prueba de la autorización
                  otorgada, revocarla, suprimir, acceder a la información)
                </li>
                <li>Firma (si aplica) y número de identificación</li>
              </ol>
            </div>
            <div>
              <ol>
                <li>
                  <strong>
                    Procedimiento para las consultas sobre Datos Personales.
                  </strong>
                </li>
                <p>
                  Todas las solicitudes, quejas o reclamos relacionados con sus
                  datos personales serán recibidos por el área de Protección de
                  Datos Personales de{' '}
                  <strong>PARKING INTERNATIONAL S.A.S.,</strong> encargada de
                  gestionar internamente los casos y garantizar una respuesta
                  oportuna, adecuada y satisfactoria para las partes
                  interesadas.
                </p>
                <p>
                  Cuando el titular de los datos o sus causahabientes deseen
                  consultar la información almacenada en nuestras bases de
                  datos, se seguirá el siguiente procedimiento:
                </p>
                <p>
                  <strong>PARKING INTERNATIONAL S.A.S.,</strong> responderá su
                  solicitud en un plazo máximo de diez (10) días hábiles.
                </p>
                <p>
                  Si no es posible responder dentro de este plazo, le
                  informaremos los motivos de la demora y le indicaremos la
                  nueva fecha de respuesta, la cual no podrá exceder de cinco
                  (5) días hábiles adicionales.
                </p>
                <li>
                  <strong>
                    Procedimiento para Reclamos sobre Datos Personales.
                  </strong>
                </li>
                <p>
                  Si considera que la información contenida en nuestras bases de
                  datos debe ser corregida, actualizada o suprimida, o si
                  detecta un presunto incumplimiento de las obligaciones
                  establecidas en la Ley 1581 de 2012, puede presentar un
                  reclamo siguiendo estas reglas:
                </p>
                <p>
                  El reclamo debe ser presentado mediante una solicitud dirigida
                  a <strong>PARKING INTERNATIONAL S.A.S.,</strong> incluyendo:
                </p>
                <ol type="a">
                  <li>Identificación del titular.</li>
                  <li>Descripción de los hechos que motivan el reclamo.</li>
                  <li>Dirección de contacto.</li>
                  <li>Documentos de soporte que desee adjuntar.</li>
                </ol>
              </ol>
            </div>
            <div>
              <p>
                Si el reclamo está incompleto,{' '}
                <strong>PARKING INTERNATIONAL S.A.S.,</strong>
                podrá solicitar la subsanación de la información faltante en un
                plazo de cinco (5) días hábiles. Si después de dos (2) meses no
                se ha recibido la información solicitada, se entenderá que ha
                desistido del reclamo.
              </p>
              <p>
                En caso de que <strong>PARKING INTERNATIONAL S.A.S.,</strong> no
                sea competente para resolver el reclamo, trasladará el caso a la
                entidad correspondiente en un plazo de dos (2) días hábiles,
                informando al titular de dicha situación.
              </p>
              <p>
                El plazo máximo para atender el reclamo será de quince (15) días
                hábiles a partir del día siguiente a la recepción del mismo. Si
                no es posible atender el reclamo en ese plazo, se informará al
                titular las razones de la demora y la nueva fecha de resolución,
                la cual no podrá exceder de ocho (8) días hábiles adicionales.
              </p>
              <p>
                Una vez cumplidos los términos señalados por la Ley 1581 de 2012
                y las demás normas que la reglamenten o complementen, el Titular
                al que se deniegue, total o parcialmente, el ejercicio de los
                derechos de acceso, actualización, rectificación, supresión y
                revocación podrá poner su caso en conocimiento de la
                Superintendencia de Industria y Comercio Delegatura para la
                Protección de Datos Personales.
              </p>
              <p>
                Con el fin de garantizar la correcta identificación y resolución
                de consultas o reclamos relacionados con la protección de datos
                personales, el área de Protección de Datos de{' '}
                <strong>PARKING INTERNATIONAL S.A.S.,</strong> realizará una
                verificación mensual en conjunto con todas las áreas de la
                empresa. Este proceso permitirá evidenciar cualquier solicitud
                presentada por los titulares y asegurar el cumplimiento de las
                normativas vigentes en materia de protección de datos.
              </p>
            </div>
            <div>
              <p
                style={{
                  background: 'rgba(128, 162, 108)',
                  color: 'white',
                  alignContent: 'center',
                  textAlign: 'center',
                  fontWeight: 700,
                }}
              >
                VIGENCIA DE LAS BASES DE DATOS
              </p>
              <p>
                Las bases de datos en las que se registrarán los datos
                personales tendrán una vigencia igual al tiempo en que se
                mantenga y utilice la información para las finalidades descritas
                en esta política.
              </p>
              <p>
                Una vez se cumplan esas finalidades y siempre que no exista un
                deber legal o contractual de conservar su información, sus datos
                serán eliminados de nuestras bases de datos.
              </p>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <p
                style={{
                  background: 'rgba(128, 162, 108)',
                  color: 'white',
                  alignContent: 'center',
                  textAlign: 'center',
                  fontWeight: 700,
                }}
              >
                VIGENCIA DE LA POLÍTICA
              </p>
              <p>
                La presente Política para el Tratamiento de Datos Personales
                rige a partir del 16 de septiembre del 2024.
              </p>
              <p
                style={{
                  margin: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <strong>CARLOS EDUARDO GONZALEZ MEDAGLIA</strong>
              </p>
              <p
                style={{
                  margin: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <strong>C.C. No. 79.947.969 DE BOGOTÁ D.C.</strong>
              </p>
              <p
                style={{
                  margin: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <strong>REPRESENTANTE LEGAL</strong>
              </p>
              <p
                style={{
                  margin: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <strong>PARKING INTERNATIONAL S.A.S.</strong>
              </p>
              <p
                style={{
                  margin: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <strong>NIT. 860.058.760-1</strong>
              </p>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <p
                style={{
                  background: 'rgba(128, 162, 108)',
                  color: 'white',
                  alignContent: 'center',
                  textAlign: 'center',
                  fontWeight: 700,
                }}
              >
                CONTROL DE CAMBIOS
              </p>
              <table border="1">
                <thead style={{ backgroundColor: 'rgba(128, 162, 108)' }}>
                  <tr>
                    <th style={{ textAlign: 'center', color: 'white' }}>
                      Versión
                    </th>
                    <th style={{ textAlign: 'center', color: 'white' }}>
                      Descripción
                    </th>
                    <th style={{ textAlign: 'center', color: 'white' }}>
                      Motivo
                    </th>
                    <th style={{ textAlign: 'center', color: 'white' }}>
                      Fecha
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center' }}>1</td>
                    <td style={{ textAlign: 'center' }}>
                      Creación de la Política de Tratamiento y Protección de
                      Datos Personales
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      Cumplimiento a requisitos legales sobre regulación de
                      Habeas Data
                    </td>
                    <td style={{ textAlign: 'center' }}>16 de enero de 2018</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>2</td>
                    <td style={{ textAlign: 'center' }}>
                      Actualización de la Política de Tratamiento y Protección
                      de Datos Personales
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      Actualización de los requisitos legales sobre regulación
                      de Habeas Data
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      16 de septiembre de 2024
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterComponen />
    </>
  );
};

export default TerminosPage;
