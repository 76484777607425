import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({
  data,
  pageLimit,
  dataLimit,
  setDataPagination,
}) => {
  const [pages] = useState(Math.round(data?.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState();

  const goToNextPage = () => {
    setCurrentPage((page) => page + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((page) => page - 1);
  };

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    setDataPagination(data?.slice(startIndex, endIndex));
    return data?.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(totalItem / dataLimit);

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    const totalPages = Math.ceil(totalItem / dataLimit);

    return new Array(pageLimit).fill().map((_, idx) => {
      const pageNumber = start + idx + 1;
      const hasData = pageNumber <= totalPages; // Verificar si la página tiene datos
      return { pageNumber, hasData };
    });
    // return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  useEffect(() => {
    setTotalItem(data?.length);
    getPaginatedData();
    getPaginationGroup();
  }, [data, currentPage]);

  console.log(Math.round(totalItem / pageLimit) + ' - ' + currentPage);

  return (
    <Pagination>
      {currentPage > 1 ? <Pagination.Prev onClick={goToPreviousPage} /> : null}
      {getPaginationGroup().map(({ pageNumber, hasData }, index) => {
        if (hasData) {
          return (
            <Pagination.Item
              key={index}
              active={pageNumber === currentPage}
              onClick={changePage}
            >
              {pageNumber}
            </Pagination.Item>
          );
        } else {
          return null; // No mostrar páginas vacías
        }
      })}
      {totalPages === currentPage ? null : (
        <Pagination.Next onClick={goToNextPage} />
      )}
    </Pagination>
  );
};

export default PaginationComponent;
