import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { useForm, Controller, Feedback } from 'react-hook-form';
import Select from 'react-select';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { analytic } from '../../server/firebase';
import FooterComponen from '../../components/footer';
import facturacionBanner from '../../asses/img/factura-electronica-banner.png';
import { URL_SERVICIO, URL_EMPLOYEE } from '../../constans/servidor';

import ayuda from '../../asses/img/icon_ayuda.png';

import * as datajson from '../../constans/json';
import * as ROUTES from '../../constans/rutas';
import FilesInvoiceElectronic from './FilesInvoiceElectronic';

const ValidacionClienteFacturaPage = () => {
  const [loading, setLoading] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);

  const [loadingData, setLoadingData] = useState(false);
  const [dataUser, setDataUser] = useState();
  const [placa, setPlaca] = useState();
  const [plate, setPlate] = useState('');
  const [id, setId] = useState('');
  const [modal, setModal] = useState(false);
  const [respuesta, setRespuesta] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageB, setErrorMessageB] = useState('');
  const [errorMessageC, setErrorMessageC] = useState('');
  const [errorMessageD, setErrorMessageD] = useState('');
  // const [datosCorrectos, setdatosCorrectos] = useState(false);
  const [datosCorrectosA, setdatosCorrectosA] = useState(false);

  const [datosCorrectosB, setdatosCorrectosB] = useState(false);
  const [datosCorrectosC, setdatosCorrectosC] = useState(false);
  const [datosCorrectosD, setdatosCorrectosD] = useState(false);
  const [vertipo, setVertipo] = useState(0);
  const [name, setName] = useState();
  const [archivos, setUrl] = useState();
  const [invoice, setInvoice] = useState('');
  const [codTerminal, setCodTerminal] = useState('');

  const datosCorrectos = [
    datosCorrectosA,
    datosCorrectosB,
    datosCorrectosC,
    datosCorrectosD,
  ];

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const regExpCarro = /^[a-z A-Z]{3}[0-9]{3}$/;
  const regExpCarroR = /^[a-z A-Z]{1}[0-9]{4}$/;
  const regExpCarroA = /^[a-z A-Z]{2}[0-9]{4}$/;
  const regExpCarroV = /^[a-z A-Z]{1}[0-9]{5}$/;
  const regExpMoto = /^[a-z A-Z]{3}[0-9]{2}[a-z A-Z]{1}$/;
  const regExpMotoAntigua = /^[a-z A-Z]{3}[0-9]{2}$/;
  const regExpBici = /^BICI[0-9]{3}$/;

  const verificarPlaca = (placa) => {
    console.log(placa);
    if (
      regExpCarro.test(placa) ||
      regExpCarroR.test(placa) ||
      regExpCarroA.test(placa) ||
      regExpCarroV.test(placa) ||
      regExpMoto.test(placa) ||
      regExpMotoAntigua.test(placa) ||
      regExpBici.test(placa)
    ) {
      setPlaca(true);
    } else {
      setPlaca(false);
    }
  };
  const selectionPersona = (val) => {
    setVertipo(parseInt(val.value));
  };

  const sendImageS3 = async () => {
    setLoadingData(true);
    try {
      if (!name) {
        toast.error('ADJUNTE UNA IMAGEN PARA CONTINUAR');
        setLoadingData(false);
        return;
      }

      const body = name.map((fileName, index) => ({
        type: 'Invoice Electronic',
        invoice: `${invoice}-${codTerminal.toUpperCase()}`,
      }));

      const result = await axios.post(
        `${URL_EMPLOYEE}/outsource/getUploadInvoice`,
        body
      );
      if (result.data.statusCode === 200) {
        for (let i = 0; i < name.length; i++) {
          const file = name[i];
          const url = result.data.data[0].urlSignature;

          const fileContent = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => {
              reject(new Error('Error al leer el archivo'));
            };
            reader.readAsArrayBuffer(file);
          });
          let fileType;

          if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
            fileType = 'image/jpeg';
          } else if (file.type === 'image/png') {
            fileType = 'image/png';
          } else if (file.type === 'application/pdf') {
            fileType = 'application/pdf';
          }

          let blobData = new Blob([fileContent], {
            type: fileType,
          });

          await axios.put(url, blobData, {
            headers: {
              'Content-Type': fileType,
              'Access-Control-Allow-Origin': '*',
            },
          });

          const getInvoiceUrl = await axios.post(
            `${URL_EMPLOYEE}/outsource/getInvoiceUrl`,
            { invoice: invoice }
          );

          if (getInvoiceUrl.data.statusCode === 200) {
            let data = {
              identificacion: id,
              numeroFactura: invoice,
              terminal: codTerminal,
              placa: plate,
              url: getInvoiceUrl.data.data,
            };
            const resultInserInvoice = await axios.post(
              `${URL_EMPLOYEE}/outsource/insertInvoice`,
              data
            );

            if (resultInserInvoice.data.estado === true) {
              toast.success(`Factura cargado con éxito!`, 'success');
              setLoadingData(false);
              setModal(false);
              setCodTerminal('');
              setPlate('');
              setInvoice('');
              setId('');
            } else {
              toast.error('Hubo un error al cargar la factura');
              setLoadingData(false);
            }
          } else {
            toast.error('Hubo un error al cargar la factura');
            setLoadingData(false);
          }
        }
      } else {
        toast.error(result.data.message);
        setLoadingData(false);
        return;
      }
    } catch (error) {
      console.log('ERROR: ', error);
      toast.error('Hubo un error al subir el archivo!');
      return;
    }
  };

  const onSubmit = async (data) => {
    console.log('Submit button clicked', failedAttempts);
    setLoadingData(true);

    const parametros = {
      identificacion: data.numero_id,
      numeroFactura: data.numero_factura,
      terminal: data.terminal,
      placa: data.placa,
    };
    console.log('datos enviados');
    console.log(parametros);

    try {
      const resultado = await axios.post(
        `${URL_EMPLOYEE}/outsource/invoice`,
        parametros
      );
      console.log(resultado.data);
      if (resultado.data.estado) {
        setRespuesta(resultado.data);
        setModal(true);
      } else {
        const mensajeError = resultado.data.mensaje.toLowerCase().trim();
        if (mensajeError.includes('no existe usuario')) {
          toast.error(resultado.data.mensaje);
        } else if (mensajeError.includes('no existe factura')) {
          toast.error(resultado.data.mensaje);
          setFailedAttempts((prevAttempts) => prevAttempts + 1);
          if (failedAttempts === 1) {
            setModal(true);
            reset({
              numero_id: '',
              numero_factura: '',
              terminal: '',
              placa: '',
            });
          }
        } else if (
          mensajeError.includes(
            'la factura electronica para los datos ingresados ya fue solicitada'
          )
        ) {
          toast.error(resultado.data.mensaje);
        } else if (
          mensajeError.includes(
            'ya existe una solicitud con este número de factura'
          )
        ) {
          toast.error(resultado.data.mensaje);
        }
      }
      setLoadingData(false);
      if (failedAttempts === 0) {
        reset({
          numero_id: '',
          numero_factura: '',
          terminal: '',
          placa: '',
        });
      }
    } catch (error) {
      toast.error('Algo salió mal..');
      setLoadingData(false);
    }
  };

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Landing validar factura` });
    userData();
  }, []);

  const userData = async () => {
    setLoading(true);
    const rest = await axios('https://ipapi.co/json/');
    setDataUser(rest.data);
    setLoading(false);
  };

  const confirmarFactura = async () => {
    setLoadingData(true);
    const parametros = {
      identificacion:
        respuesta.dataUser[0].tipo_identificacion === 1
          ? respuesta.dataUser[0].identificacion
          : respuesta.dataUser[0].numero_nit,
      numeroFactura: respuesta.dataFactura[0].num_factura.toString(),
      terminal: respuesta.dataFactura[0].terminal,
      placa: respuesta.dataFactura[0].placa,
      url: 'NULL',
    };
    try {
      const resultado = await axios.post(
        `${URL_EMPLOYEE}/outsource/insertInvoice`,
        parametros
      );
      if (resultado.data.estado) {
        setRespuesta();
        setModal(false);
        toast.success(resultado.data.mensaje);
      } else {
        toast.error(resultado.data.mensaje);
        setModal(false);
      }
      setLoadingData(false);
    } catch (error) {
      toast.error('Algo salio mal...');
      setModal(false);
      setLoadingData(false);
    }
  };

  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <div className="banner-principal">
        <div className="position-relative">
          <img
            className="d-block w-100"
            src={facturacionBanner}
            alt="Banner facturación electrónica"
          />
        </div>
      </div>

      <Container>
        <div className="position-absolute top-50 start-50 translate-middle">
          <Row className="justify-content-center mtop-20 mbottom-40">
            <Col
              className="justify-content-center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <p className="guiaregistro">
                Si aún no te has registrado para facturación electrónica,
                ingresa
                <Link className="bottomregister" to={ROUTES.LANDINGFACTURACION}>
                  {' '}
                  AQUÍ
                </Link>
              </p>
            </Col>
          </Row>
        </div>
        <Row
          className="justify-content-center mtop-40 mbottom-40"
          id="contacto"
        >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <Row className="justify-content-center mtop-20 mbottom-40">
                                <Col className='justify-content-center' xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <p  className="text-left texto-slogan ">Si aun no te has registrado para facturación electronica, ingresa a:</p>
                                    <Link
                                        className="btn btn-primary btn-sm"
                                        to={ROUTES.LANDINGFACTURACION}
                                    >
                                        Registrarse
                                    </Link>
                                </Col>
                            </Row> */}
            <div className="position-absolute top-50 start-50 translate-middle">
              <Row className="justify-content-center mtop-20 mbottom-40">
                <Col
                  className="justify-content-center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <p className="guiaregistro">
                    Si aún no te has registrado para facturación electrónica,
                    ingresa
                    <Link
                      className="bottomregister"
                      to={ROUTES.LANDINGFACTURACION}
                    >
                      {' '}
                      AQUÍ
                    </Link>
                  </p>
                </Col>
              </Row>
            </div>
            <h3 className="texto-titulo">
              DILIGENCIA EL FORMULARIO PARA SOLICITAR LA FACTURACIÓN ELECTRÓNICA
            </h3>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Tipo de Identificación</Form.Label>
                  <Controller
                    name="tipopersona"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        options={[
                          { value: '1', label: 'NIT' },
                          { value: '2', label: 'Cédula' },
                        ]}
                        className="custon-select"
                        classNamePrefix="custon-select"
                        value={value}
                        onChange={(data) => {
                          onChange(data);
                          selectionPersona(data);
                        }}
                      />
                    )}
                  />
                  {errors.tipopersona && (
                    <Form.Text id="passwordHelpBlock" className="error">
                      El tipo de documento es obligatorio
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mtop-40">
                {vertipo === 1 || vertipo === 2 ? (
                  <>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Form.Group className="control-numero">
                        {vertipo === 1 ? (
                          <Form.Label>Número de NIT</Form.Label>
                        ) : (
                          <Form.Label>Número de Identificación</Form.Label>
                        )}
                        <Controller
                          name="numero_id"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'El campo es obligatorio',
                            },
                            minLength: {
                              value: 3,
                              message: 'Mínimo 3 caracteres',
                            },
                          }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Form.Control
                              type="number"
                              onBlur={onBlur}
                              onChange={(value) => {
                                onChange(value);
                                setId(value.target.value);
                              }}
                              value={value}
                              maxLength={20}
                              onKeyUp={(event) => {
                                if (
                                  event.target.value.length >= 20 ||
                                  event.target.value.length >= 3
                                ) {
                                  setdatosCorrectosA(true);
                                } else {
                                  setdatosCorrectosA(false);
                                }
                                if (event.target.value.length >= 20) {
                                  event.preventDefault();
                                  onChange(event.target.value.substr(0, 20));
                                  setErrorMessage(
                                    'Ya ingresó el número total de caracteres'
                                  );
                                } else {
                                  setErrorMessage('');
                                }
                              }}
                            />
                          )}
                        />
                        {vertipo === 1 ? (
                          <Form.Label>
                            {' '}
                            <span className="correcto">
                              NIT sin digito de verificación
                            </span>
                          </Form.Label>
                        ) : null}
                        {errors.numero_id && (
                          <Form.Text id="passwordHelpBlock" className="error">
                            {errors.numero_id.message}
                          </Form.Text>
                        )}
                        {errorMessage && (
                          <Form.Text
                            id="passwordHelpBlock"
                            className="correcto"
                          >
                            {errorMessage}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Form.Group className="inputplaca">
                        <Form.Label>
                          Placa<span>Ingrese solo números y letras</span>
                        </Form.Label>
                        <Controller
                          name="placa"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'El campo es obligatorio',
                            },
                          }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Form.Control
                              type="text"
                              onBlur={onBlur}
                              onChange={(value) => {
                                onChange(value);
                                verificarPlaca(value.target.value);
                                setPlate(value.target.value);
                              }}
                              value={value}
                              maxLength={6}
                              onKeyUp={(event) => {
                                if (
                                  event.target.value.length >= 6 ||
                                  event.target.value.length >= 5
                                ) {
                                  setdatosCorrectosB(true);
                                } else {
                                  setdatosCorrectosB(false);
                                }
                                if (event.target.value.length >= 6) {
                                  setErrorMessageB(
                                    'Ya ingresó el número total de caracteres'
                                  );
                                } else {
                                  setErrorMessageB('');
                                }
                              }}
                            />
                          )}
                        />
                        {errors.placa && (
                          <Form.Text id="passwordHelpBlock" className="error">
                            {errors.placa.message}
                          </Form.Text>
                        )}
                        {errorMessageB && (
                          <Form.Text
                            id="passwordHelpBlock"
                            className="correcto"
                          >
                            {errorMessageB}
                          </Form.Text>
                        )}
                        {!placa && (
                          <Form.Text id="passwordHelpBlock" className="error">
                            Ingrese una placa válida
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Form.Group className="inputmayuscula">
                        <Form.Label>Número de factura</Form.Label>
                        <Controller
                          name="numero_factura"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'El campo es obligatorio',
                            },
                          }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Form.Control
                              type="text"
                              onBlur={onBlur}
                              onChange={(value) => {
                                onChange(value);
                                setInvoice(value.target.value);
                              }}
                              value={value}
                              maxLength={20}
                              onKeyUp={(event) => {
                                if (
                                  event.target.value.length >= 20 ||
                                  event.target.value.length >= 1
                                ) {
                                  setdatosCorrectosC(true);
                                } else {
                                  setdatosCorrectosC(false);
                                }
                                if (event.target.value.length >= 20) {
                                  setErrorMessageC(
                                    'Ya ingresó el número total de caracteres'
                                  );
                                } else {
                                  setErrorMessageC('');
                                }
                              }}
                            />
                          )}
                        />
                        {errors.numero_factura && (
                          // setdatosCorrectos(false),
                          <Form.Text id="passwordHelpBlock" className="error">
                            {errors.numero_factura.message}
                          </Form.Text>
                        )}
                        {errorMessageC && (
                          <Form.Text
                            id="passwordHelpBlock"
                            className="correcto"
                          >
                            {errorMessageC}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Form.Group className="inputmayuscula">
                        <Form.Label>Terminal</Form.Label>
                        <Controller
                          name="terminal"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'El campo es obligatorio',
                            },
                            maxLength: {
                              value: 4,
                              message: '4 caracteres máximo',
                            },
                          }}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Form.Control
                              type="text"
                              onBlur={onBlur}
                              onChange={(value) => {
                                onChange(value);
                                setCodTerminal(value.target.value);
                              }}
                              value={value}
                              maxLength={4}
                              onKeyUp={(event) => {
                                if (
                                  event.target.value.length >= 4 ||
                                  event.target.value.length >= 1
                                ) {
                                  setdatosCorrectosD(true);
                                } else {
                                  setdatosCorrectosD(false);
                                }
                                if (event.target.value.length >= 4) {
                                  setErrorMessageD(
                                    'Ya ingresó el número total de caracteres'
                                  );
                                } else {
                                  setErrorMessageD('');
                                }
                              }}
                            />
                          )}
                        />
                        {errors.terminal && (
                          <Form.Text id="passwordHelpBlock" className="error">
                            {errors.terminal.message}
                          </Form.Text>
                        )}
                        {errorMessageD && (
                          <Form.Text
                            id="passwordHelpBlock"
                            className="correcto"
                          >
                            {errorMessageD}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </>
                ) : null}
              </Row>
              {vertipo === 1 || vertipo === 2 ? (
                <>
                  <Row className="justify-content-end">
                    <Button
                      type="submit"
                      variant="primary"
                      className="mtop-20"
                      onClick={() => {
                        if (
                          !datosCorrectosA ||
                          !datosCorrectosB ||
                          !datosCorrectosC ||
                          !datosCorrectosD
                        ) {
                          toast.error(
                            'Algunos datos estan mal diligenciados o incompletos,corrígelos y dale aceptar para continuar'
                          );
                        }
                      }}
                    >
                      {loadingData ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        'Aceptar'
                      )}
                    </Button>
                  </Row>
                  <Row className="justify-content-start mtop-40">
                    <Link
                      target="_blank"
                      className="enlace-ayuda"
                      to={ROUTES.LANDINGAYUDAFACTURACIONELECTRONICA}
                    >
                      <img className="img-ayuda" src={ayuda}></img>
                      ¿Cómo diligenciar el formulario?
                    </Link>
                  </Row>
                </>
              ) : null}
              {/* <Row className="justify-content-start mtop-40"  >
                                <p>Tenga encuenta que esta pagina solo funciona en los siguientes navegadores: </p>
                            </Row> */}
            </form>
          </Col>
        </Row>

        <Modal show={modal} onHide={() => setModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Solicitud factura electrónica</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {respuesta ? (
              <>
                <Row className="align-items-center">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p>
                      Revise que los datos a continuación corresponden con los
                      de su factura antes de confirmar la solicitud.
                    </p>
                  </Col>
                </Row>
                <Row>
                  {respuesta.dataUser[0].tipo_identificacion === 1 ? (
                    <>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Form.Group className="inputplaca">
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control
                            type="text"
                            value={respuesta.dataUser[0].nombre}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Form.Group className="inputplaca">
                          <Form.Label>Apellido</Form.Label>
                          <Form.Control
                            type="text"
                            value={respuesta.dataUser[0].apellido}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Form.Group>
                          <Form.Label>Número de Identificación</Form.Label>
                          <Form.Control
                            type="number"
                            value={respuesta.dataUser[0].identificacion}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group className="inputplaca">
                          <Form.Label>Razón social</Form.Label>
                          <Form.Control
                            type="text"
                            value={respuesta.dataUser[0].razon_social}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Form.Group>
                          <Form.Label>Nit</Form.Label>
                          <Form.Control
                            type="number"
                            value={respuesta.dataUser[0].numero_nit}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="inputplaca">
                      <Form.Label>Placa</Form.Label>
                      <Form.Control
                        type="text"
                        value={respuesta.dataFactura[0].placa}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="inputmayuscula">
                      <Form.Label>Número de factura</Form.Label>
                      <Form.Control
                        type="text"
                        value={respuesta.dataFactura[0].num_factura}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="inputmayuscula">
                      <Form.Label>Parqueadero</Form.Label>
                      <Form.Control
                        type="text"
                        value={respuesta.dataFactura[0].nombre_parqueadero}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="inputmayuscula">
                      <Form.Label>Tipo vehiculo</Form.Label>
                      <Form.Control
                        type="text"
                        value={respuesta.dataFactura[0].tipo_vehiculo}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="inputmayuscula">
                      <Form.Label>Valor</Form.Label>
                      <Form.Control
                        type="text"
                        value={respuesta.dataFactura[0].total}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="align-items-center">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p>
                      Revise que los datos a continuación corresponden con los
                      de su factura antes de confirmar la solicitud
                    </p>
                  </Col>
                </Row>
                <Row>
                  <>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Form.Group>
                        <Form.Label>Número de Identificación</Form.Label>
                        <Form.Control type="number" value={id} disabled />
                      </Form.Group>
                    </Col>
                  </>

                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="inputplaca">
                      <Form.Label>Placa</Form.Label>
                      <Form.Control type="text" value={plate} disabled />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="inputmayuscula">
                      <Form.Label>Número de factura</Form.Label>
                      <Form.Control type="text" value={invoice} disabled />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="inputmayuscula">
                      <Form.Label>Terminal</Form.Label>
                      <Form.Control type="text" value={codTerminal} disabled />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Text id="passwordHelpBlock" className="eligedoc">
                        Elíge los documentos (PNG, JPG)
                      </Form.Text>
                      <FilesInvoiceElectronic
                        className="container-file"
                        archivo={archivos}
                        value={name}
                        onChange={(e) => setName(e)}
                        setName={setName}
                        isNameEmpty={name}
                      />

                      <Form.Text id="passwordHelpBlock" className="messageform">
                        POR FAVOR ADJUNTE LA IMÁGEN DE SU FACTURA
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setModal(false);
              }}
              variant="secondary"
            >
              Atrás
            </Button>
            {failedAttempts > 0 ? (
              <Button
                onClick={() => {
                  sendImageS3();
                }}
                variant="primary"
              >
                {loadingData ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Confirmar'
                )}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  confirmarFactura();
                }}
                variant="primary"
              >
                {loadingData ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Confirmar'
                )}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Container>

      <FooterComponen />
    </>
  );
};

export default ValidacionClienteFacturaPage;
