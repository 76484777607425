import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import clienteAxios from '../../config/axios';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const onDropRejected = () => {
  toast.error('No pudimos cargar la imagen...');
};

const Dropzone = ({ respuesta, archivo, uploader, firebase }) => {
  const [imas, setImas] = useState();
  const [cargando, setUpLoader] = useState(0);

  useEffect(() => {
    setImas(archivo);
  }, [archivo]);

  const onDropAccepted = useCallback(async (acceptedFiles) => {
    console.log(acceptedFiles);
    const formData = new FormData();
    formData.append('archivo', acceptedFiles[0]);
    const uiImg = uuidv4();

    console.log(formData);

    let task;
    task = firebase.stg.ref().child(`Imagenes/${uiImg}`).put(acceptedFiles[0]);

    task.on(
      'state_changed',
      (snapshot) => {
        let porcentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUpLoader(porcentage);
        uploader(porcentage);
      },
      (error) => {
        toast.error('No se pudo subir el archivo, intentalo de nuevo');
        console.log(error);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("XDXDXDX",acceptedFiles[0].path, uiImg);
          setUpLoader(0);
          setImas(downloadURL);
          respuesta(downloadURL, acceptedFiles[0].path, uiImg);
        });
      }
    );
  }, []);

  // const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
  //   useDropzone({ onDropAccepted, onDropRejected, maxSize: 5000000 });

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({onDropAccepted,
     accept: 'application/pdf, image/png, image/jpg', // acepta solo archivos .pdf y .png
  });

  const archivos = acceptedFiles.map((archivo) => (
    <p key={archivo.lastModified}>{archivo.name}</p>
  ));

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      className={
        !isDragActive
          ? 'container-dropzone d-flex'
          : 'container-dropzone activo d-flex'
      }
    >
      {isDragActive ? (
        <p>Suelta el archivo</p>
      ) : (
        <>
          {acceptedFiles.length !== 0 ? (
            <>{archivos}</>
          ) : (
            <p>Selecciona o arrastra el archivo aqui</p>
          )}
        </>
      )}
      {imas && <img className="dropzone-img" src={imas} alt={imas} />}

      <input className="h-100" {...getInputProps()} />
      <div className="cargador" style={{ width: `${cargando}%` }}></div>
    </div>
  );
};

export default Dropzone;
