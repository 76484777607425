import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { analytic, db, stg, fun } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import CabezeraComponent from '../../components/header/cabezera';
import EditServicios from '../../components/modal/modalServicios';
import GoogleMaps from '../../components/mapa';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';

import contacto from '../../asses/img/Password.png';
import contacto2x from '../../asses/img/Password@2x.png';
import contacto3x from '../../asses/img/Password@3x.png';
import contactoAzul from '../../asses/img/Password-azul.png';
import contactoAzul2x from '../../asses/img/Password-azul@2x.png';
import contactoAzul3x from '../../asses/img/Password-azul@3x.png';

const ContrasenabePage = () => {
  const authContex = useContext(AuthContex);
  const { usuario, cerrarSesion } = authContex;
  const [loading, setLoading] = useState(false);
  const [actual, setActual] = useState();
  const [nueva, setNueva] = useState();
  const [confirmar, setConfirmar] = useState();
  const [error, setError] = useState(false);
  const [key, setKey] = useState('home');
  const [windowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };
  const enviarEmail = async (event) => {
    event.preventDefault();
    if (actual && nueva && confirmar) {
      try {
        const datos = {
          passAntigua: actual,
          passNueva: nueva,
          identificacion: usuario.cedula,
          email: usuario.email,
        };
        const result = await axios.post(
          `${URL_AWS}/beParking/cambiarPassword`,
          datos
        );
        setActual();
        setNueva();
        setConfirmar();
        toast.success(result.data.mensaje);
        cerrarSesion();
      } catch (error) {
        toast.error(error.response.data.mensaje);
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    analytic.logEvent('page_view', { page: 'Contraseña Beparking' });
  }, []);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <div
          className={
            usuario?.extracto?.categoria === 'GREEN'
              ? 'be-tubeparking mtop-40'
              : 'be-tubeparking mtop-40 azul'
          }
        >
          <div className="be-cajas-blancas mtop-40 mbottom-40 padding-50">
            <Row className="justify-content-center" id="contacto">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h3 className="texto-titulo">Cambia tu contraseña</h3>
              </Col>
            </Row>
            <Row className="pbottom-40">
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
                <p className="text-left">
                  Rellena los campos a continuación para poder realizar el
                  cambio de tu contraseña
                </p>
                {usuario?.extracto?.categoria === 'GREEN' ? (
                  <img
                    src={contacto}
                    className="img-candado"
                    srcSet={`${contacto2x} 2x, ${contacto3x} 3x`}
                    alt="contactate con parkin"
                  />
                ) : (
                  <img
                    src={contactoAzul}
                    className="img-candado"
                    srcSet={`${contactoAzul2x} 2x, ${contactoAzul3x} 3x`}
                    alt="contactate con parkin"
                  />
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form onSubmit={enviarEmail} className="mtop-50">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Contraseña actual</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Escribe tu contraseña actual"
                      onChange={(text) => setActual(text.target.value)}
                      value={actual}
                    />
                    {error && !actual ? (
                      <Form.Text id="passwordHelpBlock" className="error">
                        Este campo es obligatorio
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Nueva contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Escribe tu nueva contraseña"
                      onChange={(text) => setNueva(text.target.value)}
                      value={nueva}
                    />
                    {error && !nueva ? (
                      <Form.Text id="passwordHelpBlock" className="error">
                        Este campo es obligatorio
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Confirma nueva contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Escribe nuevamente tu nueva contraseña"
                      onChange={(text) => setConfirmar(text.target.value)}
                      value={confirmar}
                    />
                    {(error && !confirmar) || confirmar !== nueva ? (
                      <Form.Text id="passwordHelpBlock" className="error">
                        Este campo es obligatorio y debe coincidir con la nueva
                        contraseña
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                  <Button variant="primary" size="sm" type="submit">
                    Acepta cambios
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <FooterBeparking />
    </>
  );
};

export default ContrasenabePage;
