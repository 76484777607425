import React, { useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import AuthContex from '../../context/autenticacion/authContex';
import * as ROUTES from "../../constans/rutas";
import iconUser from '../../asses/img/icon-user.png';

import { db, stg, analytic } from '../../server/firebase';

const PerfilPage = () => {

    const authContex = useContext(AuthContex);
    const { usuario, usuarioAutenticado, cerrarSesion } = authContex;

    useEffect(() => {
        usuarioAutenticado();
    }, [])

    useEffect(() => {
        analytic.logEvent('page_view', { page: `Perfil Parking` });
    }, []);

    return (
        <Container className="mtop-40">
            <Row className="justify-content-end mtop-20 mbottom-20">
                <Button variant="primary" onClick={() => cerrarSesion()}>
                    cerrar sesion
                </Button>

            </Row>
            <Row className="justify-content-center mtop-20 mbottom-20">
                
                    <img src={iconUser}></img>
            </Row>
            <Row className="justify-content-center mtop-20 mbottom-20">
                    <h3 className="text-left texto-slogan ">Bienvenid@  <spam className="texto-titulo" > {usuario ? usuario.nombre : null}</spam></h3>

            </Row>
            <Row className="justify-content-center mtop-20 mbottom-20">
                    <Link to={ROUTES.BEUSUARIOS} className="btn btn-primary btn-sm"> Usuarios beParking </Link>

            </Row>
            <Row className="justify-content-center mtop-20 mbottom-20">
                    <Link to={ROUTES.REGISTRO} className="btn btn-primary btn-sm"> Registrar usuario </Link>
            </Row>
        </Container>

    );
}

export default PerfilPage;