import app from 'firebase/app';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfigDev = {
    apiKey: "AIzaSyA-9YHaMG2J-_FYytCk72y7ltcIgWXrJx8",
    authDomain: "parkingpaginawebdesarrollo.firebaseapp.com",
    projectId: "parkingpaginawebdesarrollo",
    storageBucket: "parkingpaginawebdesarrollo.appspot.com",
    messagingSenderId: "780100718009",
    appId: "1:780100718009:web:a647e64ac57fdc3d3b3a68",
    measurementId: "G-E8V5NX7FCY"
};

const firebaseConfig = {
    apiKey: "AIzaSyDzG2Gb_2Yxxzj2eB3-dIX3ymIFpmkaXIs",
    authDomain: "parkingpaginawebproduccion.firebaseapp.com",
    projectId: "parkingpaginawebproduccion",
    storageBucket: "parkingpaginawebproduccion.appspot.com",
    messagingSenderId: "212342791622",
    appId: "1:212342791622:web:1584063f7f154b869de6c7",
    measurementId: "G-HNNQ7YLT93"
};

app.initializeApp(firebaseConfig);


export const auth = app.auth();
export const db = app.firestore();
export const stg = app.storage();
export const fun = app.functions();
export const analytic = app.analytics();

