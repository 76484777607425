import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsArrowLeft, BsPencil } from "react-icons/bs";
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditServicios from '../../components/modal/modalServicios';

import BannerBe from '../../asses/img/Banner_Be_Parking.png';
import estrella from '../../asses/img/icon-estrella.png';
import estrella2x from '../../asses/img/icon-estrella@2x.png';
import estrella3x from '../../asses/img/icon-estrella@3x.png';
import estrellah from '../../asses/img/icon-estrella-hover.png';
import estrellah2x from '../../asses/img/icon-estrella-hover@2x.png';
import estrellah3x from '../../asses/img/icon-estrella-hover@3x.png';
import parkito from '../../asses/img/parkito.png';
import parkito2x from '../../asses/img/parkito@2x.png';
import parkito3x from '../../asses/img/parkito@3x.png';
import imgNoticia from '../../asses/img/1-Hora-Gratis-V.png';

const BeDetallePage = (props) => {
    let { id } = useParams();
    const history = useHistory();
    const authContex = useContext(AuthContex);
    const { usuario } = authContex;
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState();

    const [editBanner, setEditBanner] = useState(false);
    const [editServivios, setEditServicios] = useState(false);
    const [modalNoticia, setModalNoticia] = useState(false);
    const [editAction, setEditAction] = useState('');
    const [dataBaner, setDataBaner] = useState({id: null, url: null, nombre: '', nombre_original: ''});
    const [servicios, setServicios] = useState([]);
    const [alianzas, setAlianzas] = useState([]);
    const [noticias, setNoticias] = useState([]);
    const [parqueaderos, setParqueaderos] = useState([]);
    const [zonas, setZonas] = useState([]);
    const [updateBanner, setUpdateBanner] = useState(false);
    const [updateServicio, setUpdateservicios] = useState(false);
    const [updateAlianzas, setUpdateAlianzas] = useState(false);
    const [updateNoticias, setUpdateNoticias] = useState(false);
    const [noticia, setNoticia] = useState();
    const [nombre, setNombre] = useState();
    const [telefono, setTelefono] = useState();
    const [email, setEmail] = useState();
    const [mensaje, setMensaje] = useState();
    const [acepto, setAcepto] = useState(false);
    const [error, setError] = useState(false);
    const [key, setKey] = useState('home');
    const [windowWidth, setWindowWidth] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    const consultarBeneficio = () => {
        db.collection('Beneficios').doc(id).get()
        .then( (doc) => {
            if (doc.exists) {
                setItem(doc.data());
            }
        }).catch( error => {
            toast.error("Algo salio mal cargando la noticia...")
        })
    }

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    useEffect(() => {
        if(props.location.item){
            setItem(props.location.item);
        } else {
            consultarBeneficio();
        }
    }, [props])
    
    useEffect(() => {
        analytic.logEvent('page_view', {page: `${item?.nombre} - Detalle Beparking`});
    }, []);


    return ( 
        loading ? 
            <div className="cargando">
                <div class="loader" id="loader">Loading...</div>
            </div>
        :
        <>
            <Container>
                <Row className="mtop-50 justify-content-between">
                    <Col xs={12} sm={6} md={4} lg={4} xl={4} className="mbottom-20">
                        <div className="be-cajas-blancas">
                            <img src={item?.imagen} alt="noticia" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={item?.tipo === "Blue" ? "t-detalle azul" : "t-detalle"}>
                        <h3 className="t-detalle-titulo">{item?.nombre}</h3>
                        <div className="t-detalle-wisy" dangerouslySetInnerHTML={{ __html: item?.noticia }}></div>
                        <h4 className="t-detalle-terminos">Términos y Condiciones</h4>
                        <div className="t-detalle-wisy" dangerouslySetInnerHTML={{ __html: item?.termino }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mbottom-40">
                        <Button onClick={() => { history.goBack(); }} className="azul"><BsArrowLeft/> Regresar</Button>
                    </Col>
                </Row>
            </Container>


            <Modal show={modalNoticia} onHide={()=> setModalNoticia(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Alianza</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {noticia ? 
                        <Row className="align-items-center">
                            {/* <Col xs={12} sm={6} md={4} lg={4} xl={4} >
                                <img className="w100" src={noticia.imagen} alt={noticia.nombre} />
                            </Col> */}
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <h1 className="texto-subtitulo">{noticia.nombre}</h1>
                                <p>{noticia.descripcion}</p>
                            </Col>
                        </Row>
                        : null
                    }
                </Modal.Body>
            </Modal>

            <FooterBeparking />

            {/* componentes CMS */}

            { usuario && usuario.cms ?
            <>
                <EditServicios 
                    show={editServivios}
                    title={editAction === 'servicios' ? "Editar servicios" : editAction === 'alianzas' ? "Editar alianzas" : editAction === 'noticias' ? "Editar noticias" : editAction === 'banner' ? 'Editar banner' : null}
                    text={editAction === 'servicios' ? "Agrega, edita o elimina servicios." : editAction === 'alianzas' ? "Agrega, edita o elimina alianzas." : editAction === 'noticias' ? "Agrega, edita o elimina noticias." : editAction === 'banner' ? 'Agrega, edita o elimina banner' : null}
                    textPrimary="Finalizar"
                    textSecundary="Cancelar"
                    onSecondary={ () => setEditServicios(false)}
                    onPrimary={ () => editAction === "servicios" ? setUpdateservicios(!updateServicio) : editAction === 'alianzas' ? setUpdateAlianzas(!updateAlianzas) : editAction === 'noticias' ? setUpdateNoticias(!updateNoticias) : editAction === 'banner' ? setUpdateBanner(!updateBanner) : null}
                    action={editAction}
                    data={editAction === 'servicios' ? servicios : editAction === 'alianzas' ? alianzas : editAction === 'noticias' ? noticias : editAction === 'banner' ? dataBaner : []}
                    firebase={{db, stg}}
                />
            </>
            : null
            }
        </>
     );
}
 
export default BeDetallePage;

