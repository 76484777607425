import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditBeparking from '../../components/modal/modalBeparking';

import * as ROUTES from '../../constans/rutas';

import BannerBe from '../../asses/img/Banner_Be_Parking.png';
import cardimg from '../../asses/img/cardimg.png';

const BePromocionesPage = () => {
    const authContex = useContext(AuthContex);
    const { usuario } = authContex;
    const [loading, setLoading] = useState(false);
    const [editBanner, setEditBanner] = useState(false);
    const [editServivios, setEditServicios] = useState(false);
    const [modalNoticia, setModalNoticia] = useState(false);
    const [editAction, setEditAction] = useState('');
    const [dataBaner, setDataBaner] = useState({id: null, url: null, nombre: '', nombre_original: ''});
    const [servicios, setServicios] = useState([]);
    const [alianzas, setAlianzas] = useState([]);
    const [noticias, setNoticias] = useState([]);
    const [parqueaderos, setParqueaderos] = useState([]);
    const [zonas, setZonas] = useState([]);
    const [updateBanner, setUpdateBanner] = useState(false);
    const [updateServicio, setUpdateservicios] = useState(false);
    const [updateAlianzas, setUpdateAlianzas] = useState(false);
    const [updateNoticias, setUpdateNoticias] = useState(false);
    const [updatePromociones, setUpdatePromociones] = useState(false);
    const [promociones, setPromociones] = useState([]);
    const [windowWidth, setWindowWidth] = useState(0);

    const [tipobe, setTipobe] = useState('');

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        analytic.logEvent('page_view', {page: 'promociones'});
    }, [])

    const consultarBanner = async () => {
        setLoading(true);
        try {
            const resultado = await db.collection('BannerBeP').orderBy("orden").get();
            const items = []
            resultado.docs.forEach( doc => {
                const item = doc.data();
                items.push(item)
            })
            setDataBaner(items);
            setLoading(false);
        } catch (error) {
            toast.error("Algo salio mal cargando el banner...")
            console.log(error.message);
            setLoading(false);
        }
    }

    const consultarBeneficios = async () => {
        setLoading(true);
        try {
            const resultado = await db.collection('Promociones').orderBy("orden").get();
            const items = []
            resultado.docs.forEach( doc => {
                const item = doc.data();
                items.push(item)
            })
            setPromociones(items);
            setLoading(false);
        } catch (error) {
            toast.error("Algo salio mal cargando las noticias...")
            console.log(error.message);
            setLoading(false);
        }
    }
    
    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    useEffect(() => {
        consultarBanner();
    }, [updateBanner]);

    useEffect(() => {
        consultarBeneficios();
    }, [updatePromociones]);


    return ( 
        loading ? 
            <div className="cargando">
                <div class="loader" id="loader">Loading...</div>
            </div>
        :
        <>
            <div className={dataBaner.length > 1 ? "banner-principal" : "banner-principal sinflecha"}>
                <Carousel>
                    { dataBaner.length > 0 && dataBaner.map( (item, index) => {
                        return(
                            <Carousel.Item key={item._id}>
                                <a href={item.nombre} target="_blank">
                                    {windowWidth <= 575?
                                        <img
                                            className="d-block w-100"
                                            src={item.imagenM}
                                            alt={item.nombre_originalM}
                                        />
                                        :
                                        <img
                                            className="d-block w-100"
                                            src={item.imagen}
                                            alt={item.nombre_original}
                                        />
                                    }
                                </a>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>

                { usuario?.cms ?
                    <Button onClick={ () => { setEditAction('bannerP'); setEditServicios(true); }} variant="outline-success" className="mtop-20 btn_banner"><AiFillEdit /> Editar</Button>
                    : null
                }
            </div>
            

            <Container>
                <Row>
                    <Col xs={12} sm={10} md={10} lg={10} xl={10}>
                        <h3 className="texto-titulo mtop-50">Promociones y Campañas</h3>
                    </Col>
                    { usuario?.cms ?
                        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-right">
                            <Button onClick={ () => { setEditAction('promociones'); setEditServicios(true); setTipobe('Green'); }} variant="outline-success" className="mtop-40 btn_banner"><AiFillEdit /> Editar</Button>
                        </Col>
                    : null}
                </Row>
                <Row className="mtop-50 mbottom-50">
                    { promociones.map(item => {
                        return(
                            <Col xs={12} sm={4} md={4} lg={4} xl={4} key={item._id}>
                                <Card >
                                    <Card.Img variant="top" src={item.imagen} />
                                    <Card.Body>
                                        <Card.Title>{item.nombre}</Card.Title>
                                        <Card.Text>{item.descripcion}</Card.Text>
                                        <Link className="btn btn-primary" to={{pathname:`${ROUTES.BEDETALLES}/${item._id}`, item}}>Leer más</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Container>

            <FooterBeparking />

            {/* componentes CMS */}

            { usuario?.cms ?
            <>
                <EditBeparking 
                    show={editServivios}
                    title={'Editar Promociones'}
                    text={"Agrega, edita o elimina promociones."}
                    textPrimary="Finalizar"
                    textSecundary="Cancelar"
                    onSecondary={ () => setEditServicios(false)}
                    onPrimary={ () => editAction === "bannerP" ? setUpdateBanner(!updateBanner) : setUpdatePromociones(!updatePromociones)}
                    action={editAction}
                    data={editAction === "bannerP" ? dataBaner : promociones}
                    firebase={{db, stg}}
                    tipoBe={tipobe}
                />
            </>
            : null}
        </>
     );
}
 
export default BePromocionesPage;

