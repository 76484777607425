import React, { useState, useContext, useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterComponen from '../../components/footer';
import { FaFacebook, FaWhatsapp, FaLinkedin } from "react-icons/fa";
import { Helmet } from 'react-helmet';
const setOGMetaTags = (data) => {
  console.log('Data received for meta tags:', data);

  const metaTags = [
    { property: 'og:title', content: data.nombre || 'Default Title' },
    { property: 'og:description', content: data.descripcion || 'Default Description' },
    { property: 'og:image', content: data.imagen || 'Default Image URL' },
    { property: 'og:url', content: window.location.href || 'Default URL' },
  ];
  metaTags.forEach((tag) => {
    const existingTag = document.querySelector(`meta[property="${tag.property}"]`);

    if (existingTag) {
      existingTag.setAttribute('content', tag.content);
    } else {
      const newTag = document.createElement('meta');
      newTag.setAttribute('property', tag.property);
      newTag.setAttribute('content', tag.content);
      document.head.appendChild(newTag);
    }
  });
};

const NoticiaPage = (props) => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const { id, nombre } = useParams();
  const [item, setItem] = useState();
  const location = useLocation();
  const { state } = useLocation();
  const item_prop = props.location.item;
  const f = new Date(item?.creado);
  const mes = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic',
  ];
  const fecha = `${mes[f.getMonth()]}, ${f.getDate()} ${f.getFullYear()}`;

  const [metaTagsConfiguradas, setMetaTagsConfiguradas] = useState(false);

  useEffect(() => {
    if (item && !metaTagsConfiguradas) {
      setOGMetaTags(item);  // Llamar a la función con el objeto de la noticia
      setMetaTagsConfiguradas(true);
    }
  }, [item, metaTagsConfiguradas]);

  const consultarNoticia = () => {
    db.collection('Noticias')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setItem(doc.data());
          setOGMetaTags(doc.data()); // Llamamos a la función aquí para actualizar las metaetiquetas
        }
      })
      .catch((error) => {
        toast.error('Algo salió mal cargando la noticia...');
      });
  };

  useEffect(() => {
    consultarNoticia();
  }, [id]);

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Noticias Parking` });

    return () => {
      // Limpiar las metaetiquetas al desmontar el componente
      const metaTags = ['og:title', 'og:description', 'og:image', 'og:url'];
      metaTags.forEach((tag) => {
        const existingTag = document.querySelector(`meta[property="${tag}"]`);
        if (existingTag) {
          existingTag.remove();
        }
      });
    };
  }, []);

  return (
    <>
      {item ? (
        <>
          <Container className="banner_noticias_titulo">
            <Row>
              <h3 className="texto-titulo_N">Noticias</h3>
            </Row>
          </Container>
          <Container className="banner_noticias_titulo">
            <Row className="container-Noticias">
              <h3 className="texto-titulo">{item.nombre}</h3>
            </Row>
          </Container>
          <Container className="container-Noticias">
            <p className="card-text_G">
              <i>
                {(item?.descripcion).length > 120
                  ? item.descripcion.substring(0, 120 - 3) + '...'
                  : item.descripcion}
              </i>
            </p>
          </Container>
          <Container className="container-Noticias">
            <img className="banner-magen" src={item.imagen}></img>
          </Container>

          <Helmet>
            <meta property="og:title" content={item.nombre} />
            <meta property="og:description" content={item.descripcion} />
            <meta property="og:image" content={item.imagen } />
            <meta property="og:url" content={window.location.href} />
          </Helmet>

          <Container className='shareOn'>
            <a className="card-shareOn">Compartir en:</a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
            >
              <FaFacebook size={25} className="social-icon" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?text=Descubre las últimas noticias en Parking ${encodeURIComponent(window.location.href)}`}>
              <FaWhatsapp size={25} className="social-icon" color='#90D400' />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(`${window.location.origin}/#${location.pathname}`)}`}>
              <FaLinkedin size={25} className="social-icon" />
            </a>
          </Container>

          <Container>
            <h3 className="card-date_G">{item.creado}</h3>
          </Container>
          <Container>
            <Row className="align-items-center mtop-30 mbottom-30 container-noticia">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  className="contenido_noticias"
                  dangerouslySetInnerHTML={{ __html: item.noticia }}
                ></div>
              </Col>
            </Row>
          </Container>
        </>
      ) : null}
      <FooterComponen />
    </>
  );
};

export default NoticiaPage;