import React, { useEffect, useState } from 'react';
import { AiFillEdit, AiFillDelete, AiOutlinePlus } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import { useForm, Controller, Feedback } from 'react-hook-form';
import axios from 'axios';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';



const ModalCategorias = ({ show, handleClose }) => {

    const {
        control,
        watch,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const crearCategorias = async (data) => {
        console.log(data);
        try {
            const body = {
                category: data.categoria,
                email: data.correo,
                copyEmail: data.cc
            }
            const resultado = await axios.post(
                `${URL_AWS}/ContactCategory/createCategory`, body
            );
            console.log(resultado.data);
            if (resultado.data.status) {
                toast.success(resultado.data.message)
                setTimeout(() => {

                    window.location.reload()
                }, 3000);
            }else{
                toast.error(resultado.data.message)
            }
        } catch (error) {
            if (error.response) {
                toast.error('Algo salio mal cargando los parqueaderos...');
                console.log(error.response);
            } else if (error.request) {
                toast.error('Algo salio mal cargando los parqueaderos...');
                console.log(error.request);
            } else {
                toast.error('Algo salio mal cargando los parqueaderos...');
                console.log('Error', error.message);
            }
        }
    }
    return (
        <Modal show={show} onHide={handleClose} size="md" className="be-modal">
            <Modal.Header closeButton >
                <Modal.Title >Agregar Categorias</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <form onSubmit={handleSubmit(crearCategorias)} className="conten-login" >
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Categoria</Form.Label>
                        <Controller
                            name="categoria"
                            control={control}

                            rules={{
                                required: {
                                    value: true,
                                    message: 'El campo es obligatorio',
                                }
                            }}
                            defaultValue=''
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Form.Control
                                    type="text"
                                    placeholder='Escribe la catergoria'
                                    onBlur={onBlur}
                                    onChange={(value) => {
                                        onChange(value);
                                    }
                                    }
                                    value={value}
                                />
                            )}
                        />
                        {errors.categoria && (
                            <Form.Text id="passwordHelpBlock" className="error">
                                {errors.message}
                            </Form.Text>
                        )}

                    </Form.Group>

                    <Form.Group controlId="formBasicEmail" >
                        <Form.Label>Correo</Form.Label>

                        <Controller
                            name="correo"
                            control={control}

                            rules={{
                                required: {
                                    value: true,
                                    message: 'El campo es obligatorio',
                                }
                            }}
                            defaultValue=''
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Form.Control
                                    type="correo"
                                    placeholder='Escribe el correo asociado'
                                    onBlur={onBlur}
                                    onChange={(value) => {
                                        onChange(value);
                                    }
                                    }
                                    value={value}
                                />
                            )}
                        />
                        {errors.correo && (
                            <Form.Text id="passwordHelpBlock" className="error">
                                {errors.message}
                            </Form.Text>
                        )}

                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" >
                        <Form.Label>CC</Form.Label>

                        <Controller
                            name="cc"
                            control={control}

                            defaultValue=''
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Form.Control
                                    type="correo"
                                    placeholder='Escribe el correo copia asociado'
                                    onBlur={onBlur}
                                    onChange={(value) => {
                                        onChange(value);
                                    }
                                    }
                                    value={value}
                                />
                            )}
                        />
                        {errors.correo && (
                            <Form.Text id="passwordHelpBlock" className="error">
                                {errors.message}
                            </Form.Text>
                        )}

                    </Form.Group>
                    <div className="text-center">
                        <Button className="btn-borde-verde" size="sm" type="submit">
                            Agregar
                        </Button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    );
};

export default ModalCategorias;