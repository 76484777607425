import { REGISTRO_EXITOSO, REGISTRO_ERROR, OBTENER_USUARIO, LOGIN_EXITOSO, LOGIN_ERROR, CERRAR_SESION } from "../../types";

export default (state, action) => {
    switch (action.type) {
        case REGISTRO_EXITOSO:
        case LOGIN_EXITOSO:
            localStorage.setItem('token', action.payload);
            return({
                ...state,
                autenticado: true,
                mensaje: null,
                cargando: false
            })
        case OBTENER_USUARIO:
            return({
                ...state,
                autenticado: true,
                cargando: false,
                usuario: action.payload
            })
        case CERRAR_SESION:
        case LOGIN_ERROR:
        case REGISTRO_ERROR:
            localStorage.removeItem('token');
            return({
                ...state,
                token: null,
                usuario: null,
                autenticado: false,
                cargando: false,
                mensaje: action.payload
            })
        default:
            return state;
    }
}