import React, { useState, useContext, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import AlertaContext from '../../context/alertas/alertaContext';
import AuthContex from '../../context/autenticacion/authContex';
import ForgotPass from '../../components/modal/modalForgotPassword';
import Files from './files';
import ModalBuscar from './modalBuscar';
import ModalActivate from './modalActived';
import axios from 'axios';
import { toast } from 'react-toastify';
import { URL_EMPLOYEE } from '../../constans/servidor';
const UploadDoc = (props, onOpen) => {
  const [loading, setLoading] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;
  const authContext = useContext(AuthContex);
  const { mensaje, autenticado, usuario } = authContext;
  const [archivos, setUrl] = useState();
  const [idArchivo, setIdIArchivo] = useState();
  const [nombre_original, setNombre_original] = useState();
  const [show, setShow] = useState(false);
  const [actived, setActived] = useState(false);
  const [identificacion, setIdentificacion] = useState('');
  const [type, setType] = useState();
  const [name, setName] = useState();
  const [data, setData] = useState('');

  const resulArchivos = (result, path, id) => {
    setUrl(result);
    setNombre_original(path);
    setIdIArchivo(id);
  };
  useEffect(() => {
    if (autenticado === null) {
      return;
    }

    if (autenticado) {
      if (usuario) {
        setTimeout(() => {
          setLoading(false);
          setIdentificacion(usuario?.NIT);
        }, 2000);
        props.history.push('/retentionCertificates/upload_doc');
      }
    } else {
      props.history.push('/retentionCertificates/admin_retention');
    }
    if (mensaje) {
      console.log(mensaje);
      mostrarAlerta(mensaje.msg, 'danger');
    }
  }, [mensaje, autenticado, usuario, props.history]);
  console.log(mensaje, autenticado, usuario);

  const consultClientInactivate = async () => {
    try {
      let typeUser = localStorage.getItem('typeUser');
      let token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const data = {
        userActived: '0',
        typeUser: typeUser,
      };

      const result = await axios.post(
        URL_EMPLOYEE + `/Suppliers/consultClientInactivate`,
        data,
        {
          headers: headers,
        }
      );

      console.log(result);

      if (result.data.status === true) {
        toast.success(result.data.message);
        setActived(true);
        setData(result.data.data);
      } else {
        toast.error(result.data.message);
        setData([])
      }
    } catch (error) {
      console.log(
        'Hubo un error al cosultar los clientes inactivos!',
        error.message
      );
      toast.error(
        'Hubo un error al cosultar los clientes inactivos!',
        'danger'
      );
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // validar que no hayan campos vacios
    if (!type || !name) {
      toast.error('Todos los campos son obligatorios', 'danger');
      return;
    }

    if (type && name) {
      const body = name.map((fileName, index) => ({
        type: type,
        name: fileName.name,
      }));
      try {
        const result = await axios.post(
          URL_EMPLOYEE + `/Suppliers/uploadFilePdf`,
          body
        );

        if (result.data.status === true) {
          try {
            for (let i = 0; i < name.length; i++) {
              const file = name[i];
              const url = result.data.data[i].urlSignature;

              const fileContent = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.readAsArrayBuffer(file);
              });

              let blobData = new Blob([fileContent], {
                type: 'application/pdf',
              });

              await axios.put(url, blobData, {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                },
              });

              toast.success(
                `Archivo ${file.name} cargado con éxito!`,
                'success'
              );
            }
            setName([]);
            setType('');
          } catch (error) {
            console.log(error.message);
            toast.error('Error al cargar los archivos', 'danger');
          }
        } else {
          toast.error(`${result.data.message}`, 'danger');
        }
      } catch (error) {
        console.log(error.message);
        toast.error('Error al cargar los archivos', 'danger');
      }
    } else {
      toast.error('Todos los campos son obligatorios', 'danger');
    }
  };

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        {alerta ? (
          <Alert variant={alerta.categoria} transition={true}>
            {alerta.msg}
          </Alert>
        ) : null}
        <Row className="justify-content-center mtop-40">
          <Col xs={12} sm={12} md={12} lg={12} xl={8}>
            <Jumbotron className="retention">
              <div className="form-usuario">
                <h3 className="text-retention  mbottom-20">
                  Cargar certificados de retención de proveedores{' '}
                </h3>
                <br />
                <Col className="text-center">
                  <Button
                    className='btn-activateUser'
                    variant="primary"
                    type="submit"
                    size="sm"
                    onClick={() => consultClientInactivate()}
                  >
                    ACTIVAR USUARIOS
                  </Button>
                </Col>
                <br />
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Tipo de certificado</Form.Label>
                  <Form.Control
                    as="select"
                    type="string"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option>Elige una opción</option>
                    <option>Certificado de retención en la fuente</option>
                    <option>Certificado de retención ICA</option>
                    <option>Certificado de retención IVA</option>
                  </Form.Control>
                </Form.Group>
                <Form onSubmit={onSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Elige los documentos</Form.Label>
                    <Files
                      className="container-file"
                      archivo={archivos}
                      value={name}
                      onChange={(e) => setName(e)}
                      setName={setName}
                      isNameEmpty={name}
                    />
                  </Form.Group>

                  <Col className="buscador p-0">
                    <Button variant="primary" type="submit" size="sm">
                      SUBIR
                    </Button>
                  </Col>
                  <br/>
                  <p className='text-info-n'>NOMENCLATURA PARA SUBIR CERTIFICADOS DE RETENCIÓN, ejemplo:<br/>
                    FUENTE : CERTIFICADO DE RETENCIÓN EN LA FUENTE AÑO 2023-1<br/>
                    IVA : CERTIFICADO DE RETENCIÓN IVA 1 BIM-4<br/>
                    ICA : CERTIFICADO DE RETENCIÓN ICA 5 BIM-44</p>
                  <br/>
                  <br/>
                  <br/>

                  <p className="text-info-upload">
                    Click{' '}
                    <a className="Clic" onClick={() => setShow(true)}>
                      {' '}
                      aquí{' '}
                    </a>{' '}
                    si deseas editar algun documento
                  </p>
                </Form>
                <ModalBuscar open={show} onOpen={(estado) => setShow(estado)} />
                {data && data.length > 0 ? (
                  <ModalActivate
                    data={data}
                    activate={actived}
                    onActivate={(estado) => setActived(estado)}
                  />
                ) : null}
              </div>
            </Jumbotron>
          </Col>
        </Row>

        <ForgotPass show={showPass} onOpen={(estado) => setShowPass(estado)} />
      </Container>
    </>
  );
};

export default UploadDoc;