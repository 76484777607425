import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import * as datajson from '../../constans/json';
// import OtpInput from 'react-otp-input';

import axios from 'axios';
import { useForm, Controller, Feedback, set } from 'react-hook-form';
import canaletica from '../../asses/img/canalEtica.png';
import ayuda from '../../asses/img/icon_ayuda.png';
import UploadEvidence from './uploadEvidence';

import Select from 'react-select';
import { URL_AWS, URL_EMPLOYEE } from '../../constans/servidor';
import generateNumberCase from '../../utils/generateCodeCase';

const ConsultarDenuncia = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [respuesta, setRespuesta] = useState(false);
  const [dataRequired, setDataRequired] = useState([]);
  // const [otp, setOtp] = useState('');
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setRespuesta(false);
    if (!data.caso) {
      toast.error('se requiere el numero de caso!');
      return;
    }
    if (isNaN(data.caso)) {
      toast.error('Solo se admite numeros!');
      return;
    }
    if (data.caso.length <= 5) {
      toast.error('por lo menos deben ser 6 digitos!');
      return;
    }
    const dataEthics = {
      numberCase: data.caso,
    };

    const getCase = await axios.post(
      `${URL_AWS}/ContactCategory/getCase`,
      dataEthics
    );
    if (getCase.data.status === true) {
      setDataRequired(getCase.data.data);
      toast.success(getCase.data.message);
      setRespuesta(true);
    } else {
      toast.error(getCase.data.message);
      reset({
        data,
      });
      setDataRequired({});
      setRespuesta(false);
      return;
    }
  };

  return (
    <>
      <Container
        className=" etica_body"
        style={{
          marginBottom: '60px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: 'white',
          padding: '10px ',
          borderRadius: '20px',
          marginBottom: '10px',
          boxShadow: 'box-shadow: 15px 10px 10px black',
        }}
      >
        <h3
          className="text-center"
          style={{ width: '60%', marginBottom: '60px' }}
        >
          Estamos aquí para ayudarte.
        </h3>
        <p
          className="text-center"
          style={{ width: '60%', marginBottom: '60px' }}
        >
          Mantengamos la comunicación abierta y transparente. Tu opinión y tus
          preocupaciones son importantes para nosotros.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '60%', marginBottom: '60px' }}
        >
          <Form.Group
            controlId="formBasicEmail"
            style={{
              marginBottom: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Form.Label>Número de caso</Form.Label>
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
              <OtpInput
                inputType="tel"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle={{
                  width: '2em',
                  marginTop: '5px',
                  border: '1px solid rgba(0, 0, 0, 0.4)',
                  borderRadius: '4px',
                  fontWeight: '600',
                }}
                renderSeparator={
                  <span style={{ color: 'transparent' }}>-</span>
                }
                renderInput={(props) => (
                  <input style={{ placeholder: '-' }} {...props} />
                )}
              />
            </div> */}
            <Controller
              name="caso"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'El campo es obligatorio',
                },
              }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  type="text"
                  placeholder="Digite el numero de caso"
                  onBlur={onBlur}
                  onChange={(data) => onChange(data)}
                  value={value}
                  maxLength={8}
                />
              )}
            />
            {errors.caso && (
              <Form.Text id="passwordHelpBlock" className="error">
                {errors.caso.message}
              </Form.Text>
            )}
          </Form.Group>
          {showLoading ? (
            <div className="container-sniper"></div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                type="submit"
                variant="primary"
                className="mtop-20 mbottom-20"
                style={{ width: '300px' }}
              >
                Consultar
              </Button>
            </div>
          )}
        </form>
      </Container>
      {respuesta ? (
        <>
          <Container
            className=" etica_body"
            style={{
              background: 'white',
              padding: '10px ',
              borderRadius: '20px',
              marginBottom: '10px',
              boxShadow: 'box-shadow: 15px 10px 10px black',
            }}
          >
            <>
              <h4
                style={{
                  fontSize: '16px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Detalle de la denuncia realizada
              </h4>
              <p>Categoria: {dataRequired.complaint_category}</p>
              <p>{dataRequired.detail_complaint}</p>
            </>
          </Container>
          <Container
            className="etica_p"
            style={{
              background: 'white',
              padding: '10px ',
              borderRadius: '20px',
              marginBottom: '10px',
              boxShadow: 'box-shadow: 15px 10px 10px black',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: '0' }}>
              Estado de la denuncia:{' '}
              <span style={{ color: 'black' }}>
                {dataRequired.complaint_status}
              </span>
            </p>
          </Container>
          {dataRequired.responseCase ? (
            <>
              <Container
                className=" etica_body"
                style={{
                  background: 'white',
                  padding: '10px ',
                  borderRadius: '20px',
                  marginBottom: '10px',
                  boxShadow: 'box-shadow: 15px 10px 10px black',
                }}
              >
                <h4
                  style={{
                    fontSize: '16px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Respuesta de la denuncia
                </h4>
                <p>{dataRequired.responseCase}</p>
              </Container>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};
export default ConsultarDenuncia;
