import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import clienteAxios from '../../config/axios';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const onDropRejected = () => {
  toast.error('No pudimos cargar el (los) archivo(s)...');
};

const Files = ({ archivo, setName, isNameEmpty }) => {
  const [showLoading, setShowLoading] = useState(true);
  const [imas, setImas] = useState();
  const [cargando, setUpLoader] = useState(0);

  useEffect(() => {
    setImas(archivo);
  }, [archivo]);

  useEffect(() => {
    let timer;
    if (isNameEmpty === undefined || isNameEmpty.length === 0) {
      setShowLoading(true);
      timer = setTimeout(() => {
        setShowLoading(false);
      }, 1500);
    } else {
      setShowLoading(false);
    }

    return () => clearTimeout(timer);
  }, [isNameEmpty]);

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      console.log(acceptedFiles);
      const formData = new FormData();
      formData.append('archivo', acceptedFiles[0]);

      const nameFile = acceptedFiles.map((file) => file);
      setName(nameFile);
    },
    [setName]
  );
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDropAccepted,
      onDropRejected,
      accept: 'application/pdf', // acepta solo archivos .pdf
    });

  const archivos = acceptedFiles.map((archivo) => (
    <p key={archivo.lastModified}>{archivo.name}</p>
  ));

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      className={
        !isDragActive ? 'container-file ' : 'container-file activo d-flex'
      }
    >
      {showLoading ? (
        <div className="container-sniper"></div>
      ) : (
        <>
          {isDragActive ? (
            <p>Suelta el archivo</p>
          ) : (
            <>
              {isNameEmpty === undefined ||
              isNameEmpty === '' ||
              isNameEmpty === null ||
              isNameEmpty.length === 0 ? (
                <p>
                  Selecciona o arrastra el archivo PDF aquí
                  <br />
                </p>
              ) : (
                <>{archivos}</>
              )}
            </>
          )}
        </>
      )}
      {imas && <img className="dropzone-img" src={imas} alt={imas} />}

      <input className="h-100" {...getInputProps()} />
      <div className="cargador" style={{ width: `${cargando}%` }}></div>
    </div>
  );
};


export default Files;