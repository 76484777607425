import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaEdit, FaTrash, FaSave, FaTimes, FaPlus } from 'react-icons/fa';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { animateScroll as scroll } from 'react-scroll';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterComponen from '../../components/footer';
import EditServicios from '../../components/modal/modalServicios';
import ModalDatos from '../../components/modal/modalDatos';
import ModalCategorias from '../../components/modal/modalCategorias';
import { useForm, Controller, Feedback, set } from 'react-hook-form';

import GoogleMaps from '../../components/mapa';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';

import contacto from '../../asses/img/contacto.png';
import contacto2x from '../../asses/img/contacto@2x.png';
import contacto3x from '../../asses/img/contacto@3x.png';
import * as ROUTES from '../../constans/rutas';
import { getCategorys } from '../../utils/getCategories';

const HomePage = () => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const [loading, setLoading] = useState(false);
  const [editBanner, setEditBanner] = useState(false);
  const [editServivios, setEditServicios] = useState(false);
  const [modalNoticia, setModalNoticia] = useState(false);
  const [editAction, setEditAction] = useState('');
  const [dataBaner, setDataBaner] = useState({
    id: null,
    url: null,
    nombre: '',
    nombre_original: '',
  });
  const [servicios, setServicios] = useState([]);
  const [alianzas, setAlianzas] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [parqueaderos, setParqueaderos] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [updateBanner, setUpdateBanner] = useState(false);
  const [updateServicio, setUpdateservicios] = useState(false);
  const [updateAlianzas, setUpdateAlianzas] = useState(false);
  const [updateNoticias, setUpdateNoticias] = useState(false);
  const [noticia, setNoticia] = useState();
  const [nombre, setNombre] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [mensaje, setMensaje] = useState();
  const [acepto, setAcepto] = useState(false);
  const [error, setError] = useState(false);
  const [key, setKey] = useState('home');
  const [openModal, setOpenModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [eliminar, setEliminar] = useState(false);
  const [agregar, setAgregar] = useState(false);
  const [modalTitle, setModaltitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalShow, setModalShow] = useState(true);
  const [modalShowA, setModalShowA] = useState(false);
  const [datos, setDatos] = useState([]);
  const [indexD, setIndexD] = useState();
  const [indexI, setIndexI] = useState();
  const [categoriasC, setCategorias] = useState();
  const [categoriasS, setCategoriasS] = useState();
  const [typeModal, setTypeModal] = useState('acumulativePoints');
  const history = useHistory();
  // const categorias = async () => {
  //   try {
  //     const resultado = await axios.get(
  //       `${URL_AWS}/ContactCategory/getCategorys`
  //     );
  //     if (resultado.data.data) {
  //       setDatos(resultado.data.data);
  //       const objeto = resultado.data.data;
  //       const nombresCategorias = objeto.map((convenio) => ({
  //         value: convenio.id,
  //         label: convenio.category,
  //       }));
  //       setCategorias(nombresCategorias);
  //     } else {
  //       setDatos([]);
  //       setCategorias([]);
  //       toast.error('No pudimos cargar las categorias...');
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       toast.error('Algo salio mal cargando categorias...');
  //     }
  //   }
  // };

  const [editar, setEditar] = useState(Array(datos.length).fill(false));

  const handleModalClose = () => {
    setModalShow(false);
  };
  const handleModalCloseA = () => {
    setModalShowA(false);
  };
  const handleCRUD = async (data) => {
    console.log('en editar', editar[indexI]);
    if (editar[indexI]) {
      await handleEditClick(data);
    } else {
      await handleDeleteClick(data);
    }
  };
  const handleEditClick = async (data) => {
    const body = {
      idCategory: data[`id_${indexD}`],
      nameCategory: data[`categoria${indexD}`],
      email: data[`correo${indexD}`],
      copyEmail: data[`cc${indexD}`],
    };
    try {
      const resultado = await axios.post(
        `${URL_AWS}/ContactCategory/modifyCategory`,
        body
      );
      console.log(resultado.data);
      if (resultado.data.status) {
        setEditar(false);
        console.log(resultado.data);
        toast.success(resultado.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      if (error.response) {
        toast.error('Algo salio mal modificando las categorias...');
        console.log(error.response);
      } else if (error.request) {
        toast.error('Algo salio mal modificando las categorias...');
        console.log(error.request);
      } else {
        toast.error('Algo salio mal modificando las categorias...');
        console.log('Error', error.message);
      }
    }
  };
  const handleDeleteClick = async (data) => {
    console.log('eliminar');
    const body = {
      id: data[`id_${indexD}`],
    };
    console.log(body);
    try {
      const resultado = await axios.post(
        `${URL_AWS}/ContactCategory/inactivateCategory`,
        body
      );
      console.log(resultado.data);
      if (resultado.data.status) {
        toast.success(resultado.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      if (error.response) {
        toast.error('Algo salio mal eliminado la categoria...');
        console.log(error.response);
      }
    }
  };
  const handleSaveClick = (index) => {
    const updatedDatos = [...datos];
    updatedDatos[index].editing = false;
    setDatos(updatedDatos);
  };

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const idItemsSelectable = [
    'Hfn4wX0i1TOWijvm4Od2',
    'fRoBqOBuIlYhOuibZdbr',
    'sPLOlenxknFwN2G4O4l7',
  ];

  const itemsSelectableModalInfo = [
    {
      id: 'Hfn4wX0i1TOWijvm4Od2',
      title: 'MENSUALIDADES',
      description:
        'Si eres un cliente que frecuenta un lugar cercano a nuestros puntos de servicio, aprovecha las mensualidades que te permiten estacionar un vehículo ilimitadamente sujeto a la disponibilidad de espacio, dentro del horario establecido y registrando hasta dos placas. Ten en cuenta poder realizar un solo cambio de vehículo al mes, que debes pagar por estacionar por fuera del horario establecido, el valor puede cambiar una vez termine la vigencia de 30 días, no acumularás punto en nuestro plan de fidelización, el derecho no es transferible a otros usuarios ni a otros parqueaderos y no admite devoluciones. En caso de no poder prestarte el servicio en el parqueadero donde adquiriste tu mensualidad podemos remitirte a otro parqueadero cercano.',
    },
    {
      id: 'fRoBqOBuIlYhOuibZdbr',
      title: 'BONO REGALO',
      description:
        'Con el bono de regalo tienes la oportunidad de regalar obsequiando un saldo que puedes utilizar en nuestros parqueaderos. Ten en cuenta que el valor de la carga puede ser utilizado parcial o totalmente en los parqueaderos autorizados, no es canjeable por dinero, en caso que el saldo sea insuficiente se debe complementar con otro medio de pago y si acumula puntos en Be Parking.',
    },
    {
      id: 'sPLOlenxknFwN2G4O4l7',
      title: 'VALET PARKING',
      description:
        'Parking como líder en el mercado, dentro de su portafolio de servicio cuenta con valet, donde nuestros usuarios por una tarifa fija nos haremos responsables del estado del vehículo siempre que se realice el inventario inicial, se reporten los objetos de valor. Por favor ten en cuenta que el vehículo debe solicitarse con la anticipación que se indique en cada sitio.',
    },
  ];

  const showItemModal = (id, e) => {
    const itemfound = itemsSelectableModalInfo.filter((e) => e.id === id)[0];
    setModaltitle(itemfound.title);
    setModalDescription(itemfound.description);
    setOpenModal(true);
  };

  const closeItemModal = () => {
    setOpenModal(false);
    setModaltitle('');
    setModalDescription('');
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  const settingsmodal = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    consultarBanner();
  }, [updateBanner]);
  useEffect(() => {
    const fetchCategorys = async () => {
      try {
        const categorys = await getCategorys();
        if (categorys.length > 0) {
          setCategorias(categorys);
        } else {
          toast.error('No pudimos cargar las categorias...');
          setDatos([]);
          setCategorias([]);
        }
      } catch (error) {
        console.error('Algo salio mal cargando categorias:', error);
        toast.error('Algo salio mal cargando categorias...');
      }
    };

    fetchCategorys();
  }, []);
  useEffect(() => {
    consultarServicios();
  }, [updateServicio]);

  useEffect(() => {
    consultarAlianzas();
  }, [updateAlianzas]);

  useEffect(() => {
    consultarNoticias();
  }, [updateNoticias]);

  useEffect(() => {
    consultarParqueaderos();
  }, []);

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Home Parking` });
  }, []);

  const consultarBanner = async () => {
    setLoading(true);
    try {
      const resultado = await db.collection('Banner').orderBy('orden').get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setDataBaner(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando el banner...');
      console.log(error.message);
      setLoading(false);
    }
  };

  const consultarServicios = async () => {
    setLoading(true);
    try {
      const resultado = await db.collection('Servicios').orderBy('orden').get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      console.log(items);
      setServicios(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando los servicios...');
      console.log(error.message);
      setLoading(false);
    }
  };

  const consultarAlianzas = async () => {
    setLoading(true);
    try {
      const resultado = await db.collection('Alianzas').orderBy('orden').get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setAlianzas(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando las alianzas...');
      console.log(error.message);
      setLoading(false);
    }
  };

  const consultarNoticias = async () => {
    setLoading(true);
    try {
      let resultado;
      if (usuario) {
        resultado = await db
          .collection('Noticias')
          .orderBy('creado_en', 'desc')
          .get();
      } else {
        resultado = await db
          .collection('Noticias')
          .where('estado', '==', 1)
          .orderBy('creado_en', 'desc')
          .get();
      }
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setNoticias(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando las noticias...');
      console.log('el error es ');
      console.log(error.message);
      setLoading(false);
    }
  };

  const consultarParqueaderos = async () => {
    try {
      const data = { zona: null };
      const resultado = await axios.get(
        `${URL_AWS}/serviciosPaginaWeb/rest/parqueadero/getAllParqueaderos`
      );
      setParqueaderos(resultado.data.data);
    } catch (error) {
      if (error.response) {
        toast.error('Algo salio mal cargando los parqueaderos...');
        console.log(error.response);
      } else if (error.request) {
        toast.error('Algo salio mal cargando los parqueaderos...');
        console.log(error.request);
      } else {
        toast.error('Algo salio mal cargando los parqueaderos...');
        console.log('Error', error.message);
      }
    }
  };

  // const consultarZonas = async () => {
  //     try {
  //         const resultado = await axios.get(`${URL_SERVICIO}/serviciosPaginaWeb/rest/parqueadero/getZonas`);
  //         console.log(resultado)
  //         // setZonas(resultado.data.data)
  //     } catch (error) {
  //         if (error.response) {
  //             toast.error("Algo salio mal cargando los parqueaderos...")
  //             console.log(error.response);
  //         } else if (error.request) {
  //             toast.error("Algo salio mal cargando los parqueaderos...")
  //             console.log(error.request);
  //         } else {
  //             toast.error("Algo salio mal cargando los parqueaderos...")
  //             console.log('Error', error.message);
  //         }
  //     }
  // }

  const enviarEmail = async (event) => {
    event.preventDefault();
    console.log(categoriasS);
    if (
      nombre &&
      telefono &&
      email &&
      mensaje &&
      acepto &&
      categoriasS != undefined
    ) {
      if (telefono.length === 10) {
        const body = {
          message: mensaje,
          name: nombre,
          phone: telefono,
          sendBy: email,
          category: categoriasS,
        };
        console.log(body);
        try {
          const resultado = await axios.post(
            `https://2uj5iipka9.execute-api.us-east-1.amazonaws.com/dev/ContactCategory/sendMail`,
            body
          );
          console.log(resultado);
          setNombre();
          setTelefono();
          setEmail();
          setMensaje();
          setAcepto(false);
          setCategoriasS();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          toast.success(
            'Contacto exitoso, pronto nos contactaremos con usted...'
          );
        } catch (error) {
          toast.error('Algo salio mal, intentalo mas tarde...');
        }
      } else {
        return toast.error('El numero de telefono no es valido!');
      }
    } else {
      toast.error('Algo salio mal, intentalo mas tarde...');

      setError(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const cmsBaner = async (url, nombre, nombre_original) => {
    const data = {
      url,
      nombre,
      nombre_original,
    };
    try {
      const resultado = await clienteAxios.put(
        `/api/baner/${dataBaner.id}`,
        data
      );
      setDataBaner({
        ...dataBaner,
        url: resultado.data.url,
        nombre: resultado.data.nombre,
        nombre_original: resultado.data.nombre_original,
      });
      setEditBanner(false);
      toast.success('Editado con exito');
    } catch (error) {
      if (error.response) {
        toast.error('Algo salio mal editando el banner...');
        console.log(error.response);
      } else if (error.request) {
        toast.error('Algo salio mal editando el banner...');
        console.log(error.request);
      } else {
        toast.error('Algo salio mal editando el banner...');
        console.log('Error', error.message);
      }
    }
  };

  const abrirNoticia = (item) => {
    setModalNoticia(true);
    setNoticia(item);
  };

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      {usuario ? null : (
        <ModalDatos
          show={modalShow}
          handleClose={handleModalClose}
          modalType={typeModal}
        />
      )}
      <Modal
        size="md"
        show={openModal}
        onHide={() => {
          setOpenModal(false);
        }}
        className="be-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{modalDescription}</p>
          <div className="text-center">
            <Button
              onClick={() => {
                setOpenModal(false);
              }}
              variant="link"
              className="btn-enlace-azul"
            >
              Aceptar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="banner-principal">
        <Carousel>
          {dataBaner.length > 0 &&
            dataBaner.map((item, index) => {
              return (
                <Carousel.Item key={item._id}>
                  <a href={item.nombre} target="_blank">
                    {windowWidth <= 575 ? (
                      <img
                        className="d-block w-100"
                        src={item.imagenM}
                        alt={item.nombre_originalM}
                      />
                    ) : (
                      <img
                        className="d-block w-100"
                        src={item.imagen}
                        alt={item.nombre_original}
                      />
                    )}
                  </a>
                </Carousel.Item>
              );
            })}
        </Carousel>

        {usuario?.cms ? (
          <Button
            onClick={() => {
              setEditAction('banner');
              setEditServicios(true);
            }}
            variant="outline-success"
            className="mtop-20 btn_banner"
          >
            <AiFillEdit /> Editar
          </Button>
        ) : null}
      </div>

      <Container>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={10} lg={8} xl={8}>
            <h3 className="texto-slogan">
              Parking cerca de tí, cuidando lo que valoras.
            </h3>
          </Col>
        </Row>
      </Container>

      <BannerComponent />

      <Container>
        <Row className="justify-content-center mtop-50">
          <Col xs={12} sm={10} md={9} lg={10} xl={10}>
            <h3 className="texto-titulo">Nuestros servicios.</h3>
          </Col>
          <Col
            xs={12}
            sm={2}
            md={3}
            lg={2}
            xl={2}
            className="d-flex flex-row-reverse"
          >
            {usuario && usuario.cms ? (
              <Button
                onClick={() => {
                  setEditAction('servicios');
                  setEditServicios(true);
                }}
                variant="outline-success"
                className="mtop-20"
              >
                <AiFillEdit /> Editar
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          {servicios.map((item, index) => {
            return (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                key={item._id}
                className="mbottom-20"
              >
                {idItemsSelectable.includes(item._id) ? (
                  <div
                    className="cajas-blancas"
                    onClick={(e) => showItemModal(item._id, e)}
                  >
                    <img src={item.imagen} alt={item.nombre} />
                    <h4>{item.nombre}</h4>
                    <p>
                      {item.descripcion.length > 210
                        ? item.descripcion.substring(0, 210 - 3) + '...'
                        : item.descripcion}
                    </p>
                  </div>
                ) : (
                  <div className="cajas-blancas">
                    <img src={item.imagen} alt={item.nombre} />
                    <h4>{item.nombre}</h4>
                    <p>
                      {item.descripcion.length > 210
                        ? item.descripcion.substring(0, 210 - 3) + '...'
                        : item.descripcion}
                    </p>
                  </div>
                )}
              </Col>
            );
          })}
        </Row>

        <Row className="justify-content-center mtop-50 mbottom-20">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="texto-titulo">Encuéntranos en</h3>
          </Col>
        </Row>
      </Container>

      <Container fluid className="pt-0 pb-0 sombra" id="parqueaderos">
        {/* <Row className="justify-content-center mtop-50">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {' '}<Button variant="outline-secondary" className="tab">Hola</Button>{' '}
                        {' '}<Button variant="outline-secondary" className="tab">Hola</Button>{' '}
                    </Col>
                </Row> */}
        <GoogleMaps data={parqueaderos} zonas={zonas} />
      </Container>

      <Container>
        <Row className="justify-content-center mtop-50">
          <Col xs={12} sm={10} md={9} lg={10} xl={10}>
            <h3 className="texto-titulo">Alianzas</h3>
          </Col>
          <Col
            xs={12}
            sm={2}
            md={3}
            lg={2}
            xl={2}
            className="d-flex flex-row-reverse"
          >
            {usuario && usuario.cms ? (
              <Button
                onClick={() => {
                  setEditAction('alianzas');
                  setEditServicios(true);
                }}
                variant="outline-success"
                className="mtop-20"
              >
                <AiFillEdit /> Editar
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          {alianzas.map((item, index) => {
            return (
              <Col
                key={item._id}
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="alianz"
              >
                <div className="caja-alianza">
                  <div className="alianza-imagen">
                    <img src={item.imagen} alt={item.nombre} />
                  </div>
                  <h4>{item.nombre}</h4>
                  <p>
                    {item.descripcion.length > 120
                      ? item.descripcion.substring(0, 120 - 3) + '...'
                      : item.descripcion}
                  </p>
                  <a onClick={() => abrirNoticia(item)}>Quiero saber más</a>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <div className="fondo-blanco mtop-40">
        <Container>
          <Row className="justify-content-center align-items-center mtop-40 mbottom-20">
            <Col xs={12} sm={10} md={9} lg={10} xl={10}>
              <h3 className="texto-titulo">Noticias</h3>
            </Col>
            <Col
              xs={12}
              sm={2}
              md={3}
              lg={2}
              xl={2}
              className="d-flex flex-row-reverse"
            >
              {usuario && usuario.cms ? (
                <Button
                  onClick={() => {
                    setEditAction('noticias');
                    setEditServicios(true);
                  }}
                  variant="outline-success"
                >
                  <AiFillEdit /> Editar
                </Button>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Slider {...settings}>
                {noticias.map((item, index) => {
                  const f = new Date(item.creado);
                  const mes = [
                    'ene',
                    'feb',
                    'mar',
                    'abr',
                    'may',
                    'jun',
                    'jul',
                    'ago',
                    'sep',
                    'oct',
                    'nov',
                    'dic',
                  ];
                  const fecha = `${
                    mes[f.getMonth()]
                  }, ${f.getDate()} ${f.getFullYear()}`;

                  return (
                    <div className="caja-noticias" key={item._id}>
                      <Link
                        className="card-title"
                        to={{
                          pathname: `/noticias/${item._id}
                          /${encodeURIComponent(
                            item.nombre.replace(/\s+/g, '_')
                          )}`,
                          item,
                        }}
                      >
                        <img src={item.imagen} alt={item.nombre} />
                      </Link>
                      <div className="noticias-info">
                        {usuario && usuario.cms ? (
                          <>
                            <h4>
                              {item.estado == 1
                                ? 'Noticia Activa'
                                : 'Noticia Inactiva'}
                            </h4>
                            <span>{item.creado}</span>
                            <Link
                              className="card-title"
                              to={{
                                pathname: `/noticias/${
                                  item._id
                                }/${encodeURIComponent(
                                  item.nombre.replace(/\s+/g, '_')
                                )}`,
                                item,
                              }}
                            >
                              <h4>{item.nombre}</h4>
                            </Link>

                            <p>
                              {item.descripcion.length > 120
                                ? item.descripcion.substring(0, 120 - 3) + '...'
                                : item.descripcion}
                            </p>
                            <Link
                              className="btn btn-primary btn-sm"
                              to={{
                                pathname: `/noticias/${
                                  item._id
                                }/${encodeURIComponent(
                                  item.nombre.replace(/\s+/g, '_')
                                )}`,
                                item,
                              }}
                            >
                              Leer más
                            </Link>
                          </>
                        ) : (
                          <>
                            {item.estado == 1 ? (
                              <>
                                <span>{item.creado}</span>
                                <Link
                                  className="card-title"
                                  to={{
                                    pathname: `/noticias/${
                                      item._id
                                    }/${encodeURIComponent(
                                      item.nombre.replace(/\s+/g, '_')
                                    )}`,
                                    item,
                                  }}
                                >
                                  <h4>{item.nombre}</h4>
                                </Link>

                                <p>
                                  {item.descripcion.length > 120
                                    ? item.descripcion.substring(0, 120 - 3) +
                                      '...'
                                    : item.descripcion}
                                </p>
                                <Link
                                  className="btn btn-leer btn-sm"
                                  to={{
                                    pathname: `/noticias/${
                                      item._id
                                    }/${encodeURIComponent(
                                      item.nombre.replace(/\s+/g, '_')
                                    )}`,
                                    item,
                                  }}
                                >
                                  Leer más
                                </Link>
                              </>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        {usuario && usuario.cms ? (
          <>
            <Row className="justify-content-center mtop-40" id="contacto">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h3 className="texto-titulo">Categorias de Contacto</h3>
              </Col>
            </Row>
            <Row className="pbottom-40" id="contacto">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="agregar-categoria ">
                  <div>Agregar Cateogrias</div>
                  <button
                    onClick={() => {
                      setModalShowA(true);
                    }}
                    className="add-button button-tooltip"
                    data-tooltip="Agregar"
                  >
                    <FaPlus className="icon" />
                  </button>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="App">
                  <div className="datos-input-t">
                    <div className="input-field-t"> Categoría</div>
                    <div className="input-field-t"> Correo</div>
                    <div className="input-field-t"> CC</div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {datos ? (
                  <div className="App">
                    {datos.map((item, index) => (
                      <form
                        key={index}
                        onSubmit={handleSubmit(handleCRUD)}
                        className="datos-input"
                      >
                        <Form.Group
                          controlId="formBasicEmail"
                          className="input-field"
                          style={{
                            display: 'none',
                            visibility: 'hidden',
                            width: '0',
                          }}
                        >
                          <Controller
                            name={`id_${item.id}`}
                            control={control}
                            defaultValue={item.id}
                            render={({ field }) => (
                              <Form.Control
                                disabled={!editar[index]}
                                type="text"
                                {...field}
                              />
                            )}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="formBasicEmail"
                          className="input-field"
                        >
                          <Controller
                            name={`categoria${item.id}`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'El campo es obligatorio',
                              },
                            }}
                            defaultValue={item.category}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                disabled={!editar[index]}
                                type="text"
                                onBlur={onBlur}
                                onChange={(value) => {
                                  onChange(value);
                                }}
                                value={value}
                              />
                            )}
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="formBasicEmail"
                          className="input-field"
                        >
                          <Controller
                            name={`correo${item.id}`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'El campo es obligatorio',
                              },
                            }}
                            defaultValue={item.email}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                disabled={!editar[index]}
                                type="correo"
                                onBlur={onBlur}
                                onChange={(value) => {
                                  onChange(value);
                                }}
                                value={value}
                              />
                            )}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="formBasicEmail"
                          className="input-field"
                        >
                          <Controller
                            name={`cc${item.id}`}
                            control={control}
                            defaultValue={item.copyMail}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                disabled={!editar[index]}
                                type="cc"
                                onBlur={onBlur}
                                onChange={(value) => {
                                  onChange(value);
                                }}
                                value={value}
                              />
                            )}
                          />
                        </Form.Group>
                        {editar[index] ? (
                          <>
                            <button
                              onClick={() => {
                                setIndexD(item.id);
                                setIndexI(index);
                              }}
                              type="submit"
                              className="save-button button-tooltip"
                              data-tooltip="Guardar"
                            >
                              <FaSave className="icon" />
                            </button>
                            <button
                              onClick={() => {
                                const newEdicion = [...editar];
                                newEdicion[index] = false;
                                setEditar(newEdicion);
                              }}
                              className="close-button button-tooltip"
                              data-tooltip="Cerrar"
                            >
                              <FaTimes className="icon" />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => {
                                const newEdicion = [...editar];
                                newEdicion[index] = true;
                                setEditar(newEdicion);
                              }}
                              className="edit-button button-tooltip"
                              data-tooltip="Editar"
                            >
                              <FaEdit className="icon" />
                            </button>
                            <button
                              onClick={() => {
                                setIndexD(item.id);
                                setIndexI(index);
                              }}
                              type="submit"
                              className="delete-button button-tooltip"
                              data-tooltip="Eliminar"
                            >
                              <FaTrash className="icon" />
                            </button>
                          </>
                        )}
                      </form>
                    ))}
                  </div>
                ) : null}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="justify-content-center mtop-40" id="contacto">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h3 className="texto-titulo">¿Necesitas algo?</h3>
              </Col>
            </Row>
            <Row className="pbottom-40">
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
                <p className="text-left">
                  Mantengamos el contacto.
                  <br />
                  Escríbenos y te responderemos tan pronto como sea posible.
                </p>

                <img
                  src={contacto}
                  className="img-fluid mtop-30"
                  srcSet={`${contacto2x} 2x, ${contacto3x} 3x`}
                  alt="contactate con parkin"
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form onSubmit={enviarEmail}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Escribe tu nombre"
                      onChange={(text) => setNombre(text.target.value)}
                      rules={{
                        required: {
                          value: true,
                          message: 'El campo es obligatorio',
                        },
                      }}
                    />
                    {error && !nombre ? (
                      <Form.Text id="passwordHelpBlock" className="error">
                        El nombre es obligatorio
                      </Form.Text>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Telefono</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Escribe tu teléfono"
                      onChange={(text) => setTelefono(text.target.value)}
                      rules={{
                        required: {
                          value: true,
                          message: 'El campo es obligatorio',
                        },
                      }}
                    />
                    {error && !telefono ? (
                      <Form.Text id="passwordHelpBlock" className="error">
                        El teléfono es obligatorio
                      </Form.Text>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Correo electrónico</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Escribe tu correo electrónico"
                      onChange={(text) => setEmail(text.target.value)}
                      rules={{
                        required: {
                          value: true,
                          message: 'El campo es obligatorio',
                        },
                      }}
                    />
                    {error && !email ? (
                      <Form.Text id="passwordHelpBlock" className="error">
                        El email es obligatorio
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                  {categoriasC ? (
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="texto-label">Categoria</Form.Label>
                      <Controller
                        name="categoria"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'El campo es obligatorio',
                          },
                        }}
                        // rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            onChange={(categoriasC) => {
                              setCategoriasS(categoriasC.value);
                              onChange(categoriasC);
                            }}
                            value={value}
                            // placeholder="Elige una opción"
                            options={categoriasC}
                          />
                        )}
                      />
                      {error && !categoriasS ? (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El campo es obligatorio
                        </Form.Text>
                      ) : null}
                    </Form.Group>
                  ) : null}
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Tu mensaje</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Escribe tu mensaje"
                      onChange={(text) => setMensaje(text.target.value)}
                      rules={{
                        required: {
                          value: true,
                          message: 'El campo es obligatorio',
                        },
                      }}
                    />
                    {error && !mensaje ? (
                      <Form.Text id="passwordHelpBlock" className="error">
                        El mensaje es obligatorio
                      </Form.Text>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Acepta nuestras"
                      onChange={(evt) => setAcepto(evt.target.checked)}
                    />
                    <Link className="enlace-check" to="/terminosycondiciones">
                      politicas de tratamiento de datos
                    </Link>
                    {error && !acepto ? (
                      <Form.Text id="passwordHelpBlock" className="error">
                        Es necesario aceptar nuestras politicas de tratamientos
                        de datos.
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                  <Button variant="primary" size="sm" type="submit">
                    Enviar mensaje
                  </Button>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Container>

      <ModalCategorias
        show={modalShowA}
        handleClose={handleModalCloseA}
      ></ModalCategorias>

      <Modal show={modalNoticia} onHide={() => setModalNoticia(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Alianza</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {noticia ? (
            <Row className="align-items-center">
              {/* <Col xs={12} sm={6} md={4} lg={4} xl={4} >
                                <img className="w100" src={noticia.imagen} alt={noticia.nombre} />
                            </Col> */}
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1 className="texto-subtitulo">{noticia.nombre}</h1>
                <p>{noticia.descripcion}</p>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
      </Modal>

      <FooterComponen />

      {/* componentes CMS */}

      {usuario && usuario.cms ? (
        <>
          <EditServicios
            show={editServivios}
            title={
              editAction === 'servicios'
                ? 'Editar servicios'
                : editAction === 'alianzas'
                ? 'Editar alianzas'
                : editAction === 'noticias'
                ? 'Editar noticias'
                : editAction === 'banner'
                ? 'Editar banner'
                : null
            }
            text={
              editAction === 'servicios'
                ? 'Agrega, edita o elimina servicios.'
                : editAction === 'alianzas'
                ? 'Agrega, edita o elimina alianzas.'
                : editAction === 'noticias'
                ? 'Agrega, edita o elimina noticias.'
                : editAction === 'banner'
                ? 'Agrega, edita o elimina banner'
                : null
            }
            textPrimary="Finalizar"
            textSecundary="Cancelar"
            onSecondary={() => setEditServicios(false)}
            onPrimary={() =>
              editAction === 'servicios'
                ? setUpdateservicios(!updateServicio)
                : editAction === 'alianzas'
                ? setUpdateAlianzas(!updateAlianzas)
                : editAction === 'noticias'
                ? setUpdateNoticias(!updateNoticias)
                : editAction === 'banner'
                ? setUpdateBanner(!updateBanner)
                : null
            }
            action={editAction}
            data={
              editAction === 'servicios'
                ? servicios
                : editAction === 'alianzas'
                ? alianzas
                : editAction === 'noticias'
                ? noticias
                : editAction === 'banner'
                ? dataBaner
                : []
            }
            firebase={{ db, stg }}
          />
        </>
      ) : null}
    </>
  );
};

export default HomePage;
