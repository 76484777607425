
export const ciudaddes = [
        {value:'05120', label:'CÁCERES'},
        {value:'05154', label:'CAUCASIA'},
        {value:'05250', label:'EL BAGRE'},
        {value:'05495', label:'NECHÍ'},
        {value:'05790', label:'TARAZÁ'},
        {value:'05895', label:'ZARAGOZA'},
        {value:'05142', label:'CARACOLÍ'},
        {value:'05425', label:'MACEO'},
        {value:'05579', label:'PUERTO BERRiO'},
        {value:'05585', label:'PUERTO NARE'},
        {value:'05591', label:'PUERTO TRIUNFO'},
        {value:'05893', label:'YONDÓ'},
        {value:'05031', label:'AMALFI'},
        {value:'05040', label:'ANORÍ'},
        {value:'05190', label:'CISNEROS'},
        {value:'05604', label:'REMEDIOS'},
        {value:'05670', label:'SAN ROQUE'},
        {value:'05690', label:'SANTO DOMINGO'},
        {value:'05736', label:'SEGOVIA'},
        {value:'05858', label:'VEGACHÍ'},
        {value:'05885', label:'YALÍ'},
        {value:'05890', label:'YOLOMBÓ'},
        {value:'05038', label:'ANGOSTURA'},
        {value:'05086', label:'BELMIRA'},
        {value:'05107', label:'BRICEÑO'},
        {value:'05134', label:'CAMPAMENTO'},
        {value:'05150', label:'CAROLINA'},
        {value:'05237', label:'DON MATiAS'},
        {value:'05264', label:'ENTRERRIOS'},
        {value:'05310', label:'GÓMEZ PLATA'},
        {value:'05315', label:'GUADALUPE'},
        {value:'05361', label:'ITUANGO'},
        {value:'05647', label:'SAN ANDRÉS'},
        {value:'05658', label:'SAN JOSÉ DE LA MONTAÑA'},
        {value:'05664', label:'SAN PEDRO'},
        {value:'05686', label:'SANTA ROSA de osos'},
        {value:'05819', label:'TOLEDO'},
        {value:'05854', label:'VALDIVIA'},
        {value:'05887', label:'YARUMAL'},
        {value:'05004', label:'ABRIAQUÍ'},
        {value:'05044', label:'ANZA'},
        {value:'05059', label:'ARMENIA'},
        {value:'05113', label:'BURITICÁ'},
        {value:'05138', label:'CAÑASGORDAS'},
        {value:'05234', label:'DABEIBA'},
        {value:'05240', label:'EBÉJICO'},
        {value:'05284', label:'FRONTINO'},
        {value:'05306', label:'GIRALDO'},
        {value:'05347', label:'HELICONIA'},
        {value:'05411', label:'LIBORINA'},
        {value:'05501', label:'OLAYA'},
        {value:'05543', label:'PEQUE'},
        {value:'05628', label:'SABANALARGA'},
        {value:'05656', label:'SAN JERÓNIMO'},
        {value:'05042', label:'SANTAFÉ DE ANTIOQUIA'},
        {value:'05761', label:'SOPETRaN'},
        {value:'05842', label:'URAMITA'},
        {value:'05002', label:'ABEJORRAL'},
        {value:'05021', label:'ALEJANDRÍA'},
        {value:'05055', label:'ARGELIA'},
        {value:'05148', label:'CARMEN DE VIBORAL'},
        {value:'05197', label:'COCORNÁ'},
        {value:'05206', label:'CONCEPCIÓN'},
        {value:'05313', label:'GRANADA'},
        {value:'05318', label:'GUARNE'},
        {value:'05321', label:'GUATAPE'},
        {value:'05376', label:'LA CEJA'},
        {value:'05400', label:'LA UNIÓN'},
        {value:'05440', label:'MARINILLA'},
        {value:'05483', label:'NARIÑO'},
        {value:'05541', label:'PEÑOL'},
        {value:'05607', label:'RETIRO'},
        {value:'05615', label:'RIONEGRO'},
        {value:'05649', label:'SAN CARLOS'},
        {value:'05652', label:'SAN FRANCISCO'},
        {value:'05660', label:'SAN LUIS'},
        {value:'05667', label:'SAN RAFAEL'},
        {value:'05674', label:'SAN VICENTE'},
        {value:'05697', label:'SANTUARIO'},
        {value:'05756', label:'SONSON'},
        {value:'05030', label:'AMAGa'},
        {value:'05034', label:'ANDES'},
        {value:'05036', label:'ANGELOPOLIS'},
        {value:'05091', label:'BETANIA'},
        {value:'05093', label:'BETULIA'},
        {value:'05125', label:'CAICEDO'},
        {value:'05145', label:'CARAMANTA'},
        {value:'05101', label:'CIUDAD BOLÍVAR'},
        {value:'05209', label:'CONCORDIA'},
        {value:'05282', label:'FREDONIA'},
        {value:'05353', label:'HISPANIA'},
        {value:'05364', label:'JARDÍN'},
        {value:'05368', label:'JERICÓ'},
        {value:'05390', label:'LA PINTADA'},
        {value:'05467', label:'MONTEBELLO'},
        {value:'05576', label:'PUEBLORRICO'},
        {value:'05642', label:'SALGAR'},
        {value:'05679', label:'SANTA BaRBARA'},
        {value:'05789', label:'TÁMESIS'},
        {value:'05792', label:'TARSO'},
        {value:'05809', label:'TITIRIBÍ'},
        {value:'05847', label:'URRAO'},
        {value:'05856', label:'VALPARAISO'},
        {value:'05861', label:'VENECIA'},
        {value:'05045', label:'APARTADÓ'},
        {value:'05051', label:'ARBOLETES'},
        {value:'05147', label:'CAREPA'},
        {value:'05172', label:'CHIGORODÓ'},
        {value:'05475', label:'MURINDÓ'},
        {value:'05480', label:'MUTATA'},
        {value:'05490', label:'NECOCLÍ'},
        {value:'05659', label:'SAN JUAN DE URABA'},
        {value:'05665', label:'SAN PEDRO DE URABA'},
        {value:'05837', label:'TURBO'},
        {value:'05873', label:'VIGÍA DEL FUERTE'},
        {value:'05079', label:'BARBOSA'},
        {value:'05088', label:'BELLO'},
        {value:'05129', label:'CALDAS'},
        {value:'05212', label:'COPACABANA'},
        {value:'05266', label:'ENVIGADO'},
        {value:'05308', label:'GIRARDOTA'},
        {value:'05360', label:'ITAGUI'},
        {value:'05380', label:'LA ESTRELLA'},
        {value:'05001', label:'MEDELLÍN'},
        {value:'05631', label:'SABANETA'},
        {value:'81001', label:'ARAUCA'},
        {value:'81065', label:'ARAUQUITA'},
        {value:'81220', label:'CRAVO NORTE'},
        {value:'81300', label:'FORTUL'},
        {value:'81591', label:'PUERTO RONDÓN'},
        {value:'81736', label:'SARAVENA'},
        {value:'81794', label:'TAME'},
        {value:'88564', label:'PROVIDENCIA Y SANTA CATALINA'},
        {value:'88001', label:'SAN ANDReS'},
        {value:'08001', label:'BARRANQUILLA'},
        {value:'08296', label:'GALAPA'},
        {value:'08433', label:'MALAMBO'},
        {value:'08573', label:'PUERTO COLOMBIA'},
        {value:'08758', label:'SOLEDAD'},
        {value:'08137', label:'CAMPO DE LA CRUZ'},
        {value:'08141', label:'CANDELARIA'},
        {value:'08421', label:'LURUACO'},
        {value:'08436', label:'MANATi'},
        {value:'08606', label:'REPELON'},
        {value:'08675', label:'SANTA LUCiA'},
        {value:'08770', label:'SUAN'},
        {value:'08078', label:'BARANOA'},
        {value:'08520', label:'PALMAR DE VARELA'},
        {value:'08558', label:'POLONUEVO'},
        {value:'08560', label:'PONEDERA'},
        {value:'08634', label:'Sabanagrande'},
        {value:'08638', label:'SABANALARGA'},
        {value:'08685', label:'Santo Tomas'},
        {value:'08372', label:'JUAN DE ACOSTA'},
        {value:'08549', label:'PIOJÓ'},
        {value:'08832', label:'TUBARA'},
        {value:'08849', label:'USIACURi'},
        {value:'11001', label:'BOGOTA D.C.'},
        {value:'13188', label:'CICUCO'},
        {value:'13300', label:'HATILLO DE LOBA'},
        {value:'13440', label:'MARGARITA'},
        {value:'13468', label:'MOMPÓS'},
        {value:'13650', label:'SAN FERNANDO'},
        {value:'13780', label:'TALAIGUA NUEVO'},
        {value:'13052', label:'ARJONA'},
        {value:'13062', label:'ARROYOHONDO'},
        {value:'13140', label:'CALAMAR'},
        {value:'13001', label:'CARTAGENA'},
        {value:'13222', label:'CLEMENCIA'},
        {value:'13433', label:'MAHATES'},
        {value:'13620', label:'SAN CRISTOBAL'},
        {value:'13647', label:'SAN ESTANISLAO'},
        {value:'13673', label:'SANTA CATALINA'},
        {value:'13683', label:'SANTA ROSA DE LIMA'},
        {value:'13760', label:'SOPLAVIENTO'},
        {value:'13836', label:'TURBACO'},
        {value:'13838', label:'TURBANA'},
        {value:'13873', label:'VILLANUEVA'},
        {value:'13030', label:'ALTOS DEL ROSARIO'},
        {value:'13074', label:'BARRANCO DE LOBA'},
        {value:'13268', label:'EL PEÑON'},
        {value:'13580', label:'REGIDOR'},
        {value:'13600', label:'RÍO VIEJO'},
        {value:'13667', label:'SAN MARTIN DE LOBA'},
        {value:'13042', label:'ARENAL'},
        {value:'13160', label:'CANTAGALLO'},
        {value:'13473', label:'MORALES'},
        {value:'13670', label:'SAN PABLO'},
        {value:'13688', label:'SANTA ROSA DEL SUR'},
        {value:'13744', label:'SIMITÍ'},
        {value:'13006', label:'ACHÍ'},
        {value:'13430', label:'MAGANGUÉ'},
        {value:'13458', label:'MONTECRISTO'},
        {value:'13549', label:'PINILLOS'},
        {value:'13655', label:'SAN JACINTO DEL CAUCA'},
        {value:'13810', label:'TIQUISIO'},
        {value:'13244', label:'CARMEN DE BOLÍVAR'},
        {value:'13212', label:'CÓRDOBA'},
        {value:'13248', label:'EL GUAMO'},
        {value:'13442', label:'MARÍA LA BAJA'},
        {value:'13654', label:'SAN JACINTO'},
        {value:'13657', label:'SAN JUAN NEPOMUCENO'},
        {value:'13894', label:'ZAMBRANO'},
        {value:'15232', label:'CHÍQUIZA'},
        {value:'15187', label:'CHIVATÁ'},
        {value:'15204', label:'CÓMBITA'},
        {value:'15224', label:'CUCAITA'},
        {value:'15476', label:'MOTAVITA'},
        {value:'15500', label:'OICATÁ'},
        {value:'15646', label:'SAMACÁ'},
        {value:'15740', label:'SIACHOQUE'},
        {value:'15762', label:'SORA'},
        {value:'15764', label:'SORACÁ'},
        {value:'15763', label:'SOTAQUIRÁ'},
        {value:'15814', label:'TOCA'},
        {value:'15001', label:'TUNJA'},
        {value:'15837', label:'TUTA'},
        {value:'15861', label:'VENTAQUEMADA'},
        {value:'15180', label:'CHISCAS'},
        {value:'15223', label:'CUBARÁ'},
        {value:'15244', label:'EL COCUY'},
        {value:'15248', label:'EL ESPINO'},
        {value:'15317', label:'GUACAMAYAS'},
        {value:'15332', label:'GÜICÁN'},
        {value:'15522', label:'PANQUEBA'},
        {value:'15377', label:'LABRANZAGRANDE'},
        {value:'15518', label:'PAJARITO'},
        {value:'15533', label:'PAYA'},
        {value:'15550', label:'PISBA'},
        {value:'15090', label:'BERBEO'},
        {value:'15135', label:'CAMPOHERMOSO'},
        {value:'15455', label:'MIRAFLORES'},
        {value:'15514', label:'PÁEZ'},
        {value:'15660', label:'SAN EDUARDO'},
        {value:'15897', label:'ZETAQUIRA'},
        {value:'15104', label:'BOYACÁ'},
        {value:'15189', label:'CIÉNEGA'},
        {value:'15367', label:'JENESANO'},
        {value:'15494', label:'NUEVO COLÓN'},
        {value:'15599', label:'RAMIRIQUÍ'},
        {value:'15621', label:'RONDÓN'},
        {value:'15804', label:'TIBANÁ'},
        {value:'15835', label:'TURMEQUÉ'},
        {value:'15842', label:'UMBITA'},
        {value:'15879', label:'VIRACACHÁ'},
        {value:'15172', label:'CHINAVITA'},
        {value:'15299', label:'GARAGOA'},
        {value:'15425', label:'MACANAL'},
        {value:'15511', label:'PACHAVITA'},
        {value:'15667', label:'SAN LUIS DE GACENO'},
        {value:'15690', label:'SANTA MARÍA'},
        {value:'15097', label:'BOAVITA'},
        {value:'15218', label:'COVARACHÍA'},
        {value:'15403', label:'LA UVITA'},
        {value:'15673', label:'SAN MATEO'},
        {value:'15720', label:'SATIVANORTE'},
        {value:'15723', label:'SATIVASUR'},
        {value:'15753', label:'SOATÁ'},
        {value:'15774', label:'SUSACÓN'},
        {value:'15810', label:'TIPACOQUE'},
        {value:'15106', label:'BRICEÑO'},
        {value:'15109', label:'BUENAVISTA'},
        {value:'15131', label:'CALDAS'},
        {value:'15176', label:'CHIQUINQUIRÁ'},
        {value:'15212', label:'COPER'},
        {value:'15401', label:'LA VICTORIA'},
        {value:'15442', label:'MARIPÍ'},
        {value:'15480', label:'MUZO'},
        {value:'15507', label:'OTANCHE'},
        {value:'15531', label:'PAUNA'},
        {value:'15572', label:'PUERTO BOYACa'},
        {value:'15580', label:'QUÍPAMA'},
        {value:'15632', label:'SABOYÁ'},
        {value:'15676', label:'SAN MIGUEL DE SEMA'},
        {value:'15681', label:'SAN PABLO BORBUR'},
        {value:'15832', label:'TUNUNGUÁ'},
        {value:'15022', label:'ALMEIDA'},
        {value:'15236', label:'CHIVOR'},
        {value:'15322', label:'GUATEQUE'},
        {value:'15325', label:'GUAYATÁ'},
        {value:'15380', label:'LA CAPILLA'},
        {value:'15761', label:'SOMONDOCO'},
        {value:'15778', label:'SUTATENZA'},
        {value:'15798', label:'TENZA'},
        {value:'15051', label:'ARCABUCO'},
        {value:'15185', label:'CHITARAQUE'},
        {value:'15293', label:'GACHANTIVÁ'},
        {value:'15469', label:'MONIQUIRÁ'},
        {value:'15600', label:'RÁQUIRA'},
        {value:'15638', label:'SÁCHICA'},
        {value:'15664', label:'SAN JOSÉ DE PARE'},
        {value:'15696', label:'SANTA SOFÍA'},
        {value:'15686', label:'SANTANA'},
        {value:'15776', label:'SUTAMARCHÁN'},
        {value:'15808', label:'TINJACÁ'},
        {value:'15816', label:'TOGÜÍ'},
        {value:'15407', label:'VILLA DE LEYVA'},
        {value:'15047', label:'AQUITANIA'},
        {value:'15226', label:'CUÍTIVA'},
        {value:'15272', label:'FIRAVITOBA'},
        {value:'15296', label:'GAMEZA'},
        {value:'15362', label:'IZA'},
        {value:'15464', label:'MONGUA'},
        {value:'15466', label:'MONGUÍ'},
        {value:'15491', label:'NOBSA'},
        {value:'15542', label:'PESCA'},
        {value:'15759', label:'SOGAMOSO'},
        {value:'15806', label:'TIBASOSA'},
        {value:'15820', label:'TÓPAGA'},
        {value:'15822', label:'TOTA'},
        {value:'15087', label:'BELÉN'},
        {value:'15114', label:'BUSBANZÁ'},
        {value:'15162', label:'CERINZA'},
        {value:'15215', label:'CORRALES'},
        {value:'15238', label:'DUITAMA'},
        {value:'15276', label:'FLORESTA'},
        {value:'15516', label:'PAIPA'},
        {value:'15693', label:'SAN ROSA VITERBO'},
        {value:'15839', label:'TUTAZÁ'},
        {value:'15092', label:'BETÉITIVA'},
        {value:'15183', label:'CHITA'},
        {value:'15368', label:'JERICÓ'},
        {value:'15537', label:'PAZ DE RÍO'},
        {value:'15757', label:'SOCHA'},
        {value:'15755', label:'SOCOTÁ'},
        {value:'15790', label:'TASCO'},
        {value:'17272', label:'FILADELFIA'},
        {value:'17388', label:'LA MERCED'},
        {value:'17442', label:'MARMATO'},
        {value:'17614', label:'RIOSUCIO'},
        {value:'17777', label:'SUPÍA'},
        {value:'17433', label:'MANZANARES'},
        {value:'17444', label:'MARQUETALIA'},
        {value:'17446', label:'MARULANDA'},
        {value:'17541', label:'PENSILVANIA'},
        {value:'17042', label:'ANSERMA'},
        {value:'17088', label:'BELALCÁZAR'},
        {value:'17616', label:'RISARALDA'},
        {value:'17665', label:'SAN JOSÉ'},
        {value:'17877', label:'VITERBO'},
        {value:'17174', label:'CHINCHINa'},
        {value:'17001', label:'MANIZALES'},
        {value:'17486', label:'NEIRA'},
        {value:'17524', label:'PALESTINA'},
        {value:'17873', label:'VILLAMARiA'},
        {value:'17013', label:'AGUADAS'},
        {value:'17050', label:'ARANZAZU'},
        {value:'17513', label:'PÁCORA'},
        {value:'17653', label:'SALAMINA'},
        {value:'17380', label:'LA DORADA'},
        {value:'17495', label:'NORCASIA'},
        {value:'17662', label:'SAMANÁ'},
        {value:'17867', label:'VICTORIA'},
        {value:'18029', label:'ALBANIA'},
        {value:'18094', label:'BELÉN DE LOS ANDAQUIES'},
        {value:'18150', label:'CARTAGENA DEL CHAIRÁ'},
        {value:'18205', label:'CURRILLO'},
        {value:'18247', label:'EL DONCELLO'},
        {value:'18256', label:'EL PAUJIL'},
        {value:'18001', label:'FLORENCIA'},
        {value:'18410', label:'LA MONTAÑITA'},
        {value:'18460', label:'MILaN'},
        {value:'18479', label:'MORELIA'},
        {value:'18592', label:'PUERTO RICO'},
        {value:'18610', label:'SAN JOSE DEL FRAGUA'},
        {value:'18753', label:'SAN VICENTE DEL CAGUÁN'},
        {value:'18756', label:'SOLANO'},
        {value:'18785', label:'SOLITA'},
        {value:'18860', label:'VALPARAISO'},
        {value:'85010', label:'AGUAZUL'},
        {value:'85015', label:'CHAMEZA'},
        {value:'85125', label:'HATO COROZAL'},
        {value:'85136', label:'LA SALINA'},
        {value:'85139', label:'MANÍ'},
        {value:'85162', label:'MONTERREY'},
        {value:'85225', label:'NUNCHÍA'},
        {value:'85230', label:'OROCUÉ'},
        {value:'85250', label:'PAZ DE ARIPORO'},
        {value:'85263', label:'PORE'},
        {value:'85279', label:'RECETOR'},
        {value:'85300', label:'SABANALARGA'},
        {value:'85315', label:'SÁCAMA'},
        {value:'85325', label:'SAN LUIS DE PALENQUE'},
        {value:'85400', label:'TÁMARA'},
        {value:'85410', label:'TAURAMENA'},
        {value:'85430', label:'TRINIDAD'},
        {value:'85440', label:'VILLANUEVA'},
        {value:'85001', label:'YOPAL'},
        {value:'19130', label:'CAJIBÍO'},
        {value:'19256', label:'EL TAMBO'},
        {value:'19392', label:'LA SIERRA'},
        {value:'19473', label:'MORALES'},
        {value:'19548', label:'PIENDAMO'},
        {value:'19001', label:'POPAYÁN'},
        {value:'19622', label:'ROSAS'},
        {value:'19760', label:'SOTARA'},
        {value:'19807', label:'TIMBIO'},
        {value:'19110', label:'BUENOS AIRES'},
        {value:'19142', label:'CALOTO'},
        {value:'19212', label:'CORINTO'},
        {value:'19455', label:'MIRANDA'},
        {value:'19513', label:'PADILLA'},
        {value:'19573', label:'PUERTO TEJADA'},
        {value:'19698', label:'SANTANDER DE QUILICHAO'},
        {value:'19780', label:'SUAREZ'},
        {value:'19845', label:'VILLA RICA'},
        {value:'19318', label:'GUAPI'},
        {value:'19418', label:'LOPEZ'},
        {value:'19809', label:'TIMBIQUI'},
        {value:'19137', label:'CALDONO'},
        {value:'19355', label:'INZÁ'},
        {value:'19364', label:'JAMBALO'},
        {value:'19517', label:'PAEZ'},
        {value:'19585', label:'PURACE'},
        {value:'19743', label:'Silvia'},
        {value:'19821', label:'TORIBIO'},
        {value:'19824', label:'TOTORO'},
        {value:'19022', label:'ALMAGUER'},
        {value:'19050', label:'ARGELIA'},
        {value:'19075', label:'BALBOA'},
        {value:'19100', label:'BOLÍVAR'},
        {value:'19290', label:'FLORENCIA'},
        {value:'19397', label:'LA VEGA'},
        {value:'19450', label:'MERCADERES'},
        {value:'19532', label:'PATIA'},
        {value:'19533', label:'PIAMONTE'},
        {value:'19693', label:'SAN SEBASTIAN'},
        {value:'19701', label:'SANTA ROSA'},
        {value:'19785', label:'SUCRE'},
        {value:'20045', label:'BECERRIL'},
        {value:'20175', label:'CHIMICHAGUA'},
        {value:'20178', label:'CHIRIGUANA'},
        {value:'20228', label:'CURUMANÍ'},
        {value:'20400', label:'LA JAGUA DE IBIRICO'},
        {value:'20517', label:'PAILITAS'},
        {value:'20787', label:'TAMALAMEQUE'},
        {value:'20032', label:'ASTREA'},
        {value:'20060', label:'BOSCONIA'},
        {value:'20238', label:'EL COPEY'},
        {value:'20250', label:'EL PASO'},
        {value:'20013', label:'AGUSTÍN CODAZZI'},
        {value:'20621', label:'LA PAZ'},
        {value:'20443', label:'MANAURE'},
        {value:'20570', label:'PUEBLO BELLO'},
        {value:'20750', label:'SAN DIEGO'},
        {value:'20001', label:'VALLEDUPAR'},
        {value:'20011', label:'AGUACHICA'},
        {value:'20295', label:'GAMARRA'},
        {value:'20310', label:'GONZÁLEZ'},
        {value:'20383', label:'LA GLORIA'},
        {value:'20550', label:'PELAYA'},
        {value:'20614', label:'RÍO DE ORO'},
        {value:'20710', label:'SAN ALBERTO'},
        {value:'20770', label:'SAN MARTÍN'},
        {value:'27050', label:'ATRATO'},
        {value:'27073', label:'BAGADÓ'},
        {value:'27099', label:'BOJAYA'},
        {value:'27245', label:'EL CARMEN DE ATRATO'},
        {value:'27413', label:'LLORÓ'},
        {value:'27425', label:'MEDIO ATRATO'},
        {value:'27001', label:'QUIBDÓ'},
        {value:'27600', label:'RIO QUITO'},
        {value:'27006', label:'ACANDÍ'},
        {value:'27086', label:'BELÉN DE BAJIRA'},
        {value:'27150', label:'CARMÉN DEL DARIÉN'},
        {value:'27615', label:'RIOSUCIO'},
        {value:'27800', label:'UNGUÍA'},
        {value:'27075', label:'BAHÍA SOLANO'},
        {value:'27372', label:'JURADÓ'},
        {value:'27495', label:'NUQUÍ'},
        {value:'27025', label:'ALTO BAUDÓ'},
        {value:'27077', label:'BAJO BAUDÓ'},
        {value:'27250', label:'El Litoral del San Juan'},
        {value:'27430', label:'MEDIO BAUDÓ'},
        {value:'27135', label:'CANTON DE SAN PABLO'},
        {value:'27160', label:'CERTEGUI'},
        {value:'27205', label:'CONDOTO'},
        {value:'27361', label:'ITSMINA'},
        {value:'27450', label:'MEDIO SAN JUAN'},
        {value:'27491', label:'NÓVITA'},
        {value:'27580', label:'RÍO FRÍO'},
        {value:'27660', label:'SAN JOSÉ DEL PALMAR'},
        {value:'27745', label:'SIPÍ'},
        {value:'27787', label:'TADÓ'},
        {value:'27810', label:'UNION PANAMERICANA'},
        {value:'23807', label:'TIERRALTA'},
        {value:'23855', label:'VALENCIA'},
        {value:'23168', label:'CHIMÁ'},
        {value:'23300', label:'COTORRA'},
        {value:'23417', label:'LORICA'},
        {value:'23464', label:'MOMIL'},
        {value:'23586', label:'PURÍSIMA'},
        {value:'23001', label:'MONTERÍA'},
        {value:'23090', label:'CANALETE'},
        {value:'23419', label:'LOS CÓRDOBAS'},
        {value:'23500', label:'MOÑITOS'},
        {value:'23574', label:'PUERTO ESCONDIDO'},
        {value:'23672', label:'SAN ANTERO'},
        {value:'23675', label:'SAN BERNARDO DEL VIENTO'},
        {value:'23182', label:'CHINÚ'},
        {value:'23660', label:'SAHAGÚN'},
        {value:'23670', label:'SAN ANDRÉS SOTAVENTO'},
        {value:'23068', label:'AYAPEL'},
        {value:'23079', label:'BUENAVISTA'},
        {value:'23350', label:'LA APARTADA'},
        {value:'23466', label:'MONTELÍBANO'},
        {value:'23555', label:'PLANETA RICA'},
        {value:'23570', label:'PUEBLO NUEVO'},
        {value:'23580', label:'PUERTO LIBERTADOR'},
        {value:'23162', label:'CERETÉ'},
        {value:'23189', label:'CIÉNAGA DE ORO'},
        {value:'23678', label:'SAN CARLOS'},
        {value:'23686', label:'SAN PELAYO'},
        {value:'25183', label:'CHOCONTÁ'},
        {value:'25426', label:'MACHETA'},
        {value:'25436', label:'MANTA'},
        {value:'25736', label:'SESQUILÉ'},
        {value:'25772', label:'SUESCA'},
        {value:'25807', label:'TIBIRITA'},
        {value:'25873', label:'VILLAPINZÓN'},
        {value:'25001', label:'AGUA DE DIOS'},
        {value:'25307', label:'GIRARDOT'},
        {value:'25324', label:'GUATAQUÍ'},
        {value:'25368', label:'JERUSALÉN'},
        {value:'25483', label:'NARIÑO'},
        {value:'25488', label:'NILO'},
        {value:'25612', label:'RICAURTE'},
        {value:'25815', label:'TOCAIMA'},
        {value:'25148', label:'CAPARRAPÍ'},
        {value:'25320', label:'GUADUAS'},
        {value:'25572', label:'PUERTO SALGAR'},
        {value:'25019', label:'ALBÁN'},
        {value:'25398', label:'LA PEÑA'},
        {value:'25402', label:'LA VEGA'},
        {value:'25489', label:'NIMAIMA'},
        {value:'25491', label:'NOCAIMA'},
        {value:'25592', label:'QUEBRADANEGRA'},
        {value:'25658', label:'SAN FRANCISCO'},
        {value:'25718', label:'SASAIMA'},
        {value:'25777', label:'SUPATÁ'},
        {value:'25851', label:'ÚTICA'},
        {value:'25862', label:'VERGARA'},
        {value:'25875', label:'VILLETA'},
        {value:'25293', label:'GACHALA'},
        {value:'25297', label:'GACHETA'},
        {value:'25299', label:'GAMA'},
        {value:'25322', label:'GUASCA'},
        {value:'25326', label:'GUATAVITA'},
        {value:'25372', label:'JUNÍN'},
        {value:'25377', label:'LA CALERA'},
        {value:'25839', label:'UBALÁ'},
        {value:'25086', label:'BELTRÁN'},
        {value:'25095', label:'BITUIMA'},
        {value:'25168', label:'CHAGUANÍ'},
        {value:'25328', label:'GUAYABAL DE SIQUIMA'},
        {value:'25580', label:'PULI'},
        {value:'25662', label:'SAN JUAN DE RÍO SECO'},
        {value:'25867', label:'VIANÍ'},
        {value:'25438', label:'MEDINA'},
        {value:'25530', label:'PARATEBUENO'},
        {value:'25151', label:'CAQUEZA'},
        {value:'25178', label:'CHIPAQUE'},
        {value:'25181', label:'CHOACHÍ'},
        {value:'25279', label:'FOMEQUE'},
        {value:'25281', label:'FOSCA'},
        {value:'25335', label:'GUAYABETAL'},
        {value:'25339', label:'GUTIÉRREZ'},
        {value:'25594', label:'QUETAME'},
        {value:'25841', label:'UBAQUE'},
        {value:'25845', label:'UNE'},
        {value:'25258', label:'EL PEÑÓN'},
        {value:'25394', label:'LA PALMA'},
        {value:'25513', label:'PACHO'},
        {value:'25518', label:'PAIME'},
        {value:'25653', label:'SAN CAYETANO'},
        {value:'25823', label:'TOPAIPI'},
        {value:'25871', label:'VILLAGOMEZ'},
        {value:'25885', label:'YACOPÍ'},
        {value:'25126', label:'CAJICÁ'},
        {value:'25175', label:'CHÍA'},
        {value:'25200', label:'COGUA'},
        {value:'25295', label:'GACHANCIPÁ'},
        {value:'25486', label:'NEMOCoN'},
        {value:'25758', label:'SOPÓ'},
        {value:'25785', label:'TABIO'},
        {value:'25817', label:'TOCANCIPÁ'},
        {value:'25899', label:'ZIPAQUIRÁ'},
        {value:'25099', label:'BOJACÁ'},
        {value:'25214', label:'COTA'},
        {value:'25260', label:'EL ROSAL'},
        {value:'25269', label:'FACATATIVÁ'},
        {value:'25286', label:'FUNZA'},
        {value:'25430', label:'MADRID'},
        {value:'25473', label:'MOSQUERA'},
        {value:'25769', label:'SUBACHOQUE'},
        {value:'25799', label:'TENJO'},
        {value:'25898', label:'ZIPACoN'},
        {value:'25740', label:'SIBATÉ'},
        {value:'25754', label:'SOACHA'},
        {value:'25053', label:'ARBELÁEZ'},
        {value:'25120', label:'CABRERA'},
        {value:'25290', label:'FUSAGASUGÁ'},
        {value:'25312', label:'GRANADA'},
        {value:'25524', label:'PANDI'},
        {value:'25535', label:'PASCA'},
        {value:'25649', label:'SAN BERNARDO'},
        {value:'25743', label:'SILVANIA'},
        {value:'25805', label:'TIBACUY'},
        {value:'25506', label:'VENECIA'},
        {value:'25035', label:'ANAPOIMA'},
        {value:'25040', label:'ANOLAIMA'},
        {value:'25599', label:'APULO'},
        {value:'25123', label:'CACHIPAY'},
        {value:'25245', label:'EL COLEGIO'},
        {value:'25386', label:'LA MESA'},
        {value:'25596', label:'QUIPILE'},
        {value:'25645', label:'SAN ANTONIO DE TEQUENDAMA'},
        {value:'25797', label:'TENA'},
        {value:'25878', label:'VIOTÁ'},
        {value:'25154', label:'CARMEN DE CARUPA'},
        {value:'25224', label:'CUCUNUBÁ'},
        {value:'25288', label:'FÚQUENE'},
        {value:'25317', label:'GUACHETÁ'},
        {value:'25407', label:'LENGUAZAQUE'},
        {value:'25745', label:'SIMIJACA'},
        {value:'25779', label:'SUSA'},
        {value:'25781', label:'SUTATAUSA'},
        {value:'25793', label:'TAUSA'},
        {value:'25843', label:'UBATE'},
        {value:'94343', label:'BARRANCO MINA'},
        {value:'94886', label:'CACAHUAL'},
        {value:'94001', label:'INÍRIDA'},
        {value:'94885', label:'LA GUADALUPE'},
        {value:'94663', label:'MAPIRIPaN'},
        {value:'94888', label:'MORICHAL'},
        {value:'94887', label:'PANA PANA'},
        {value:'94884', label:'PUERTO COLOMBIA'},
        {value:'94883', label:'SAN FELIPE'},
        {value:'95015', label:'CALAMAR'},
        {value:'95025', label:'EL RETORNO'},
        {value:'95200', label:'MIRAFLORES'},
        {value:'95001', label:'SAN JOSÉ DEL GUAVIARE'},
        {value:'41013', label:'AGRADO'},
        {value:'41026', label:'ALTAMIRA'},
        {value:'41298', label:'GARZÓN'},
        {value:'41306', label:'GIGANTE'},
        {value:'41319', label:'GUADALUPE'},
        {value:'41548', label:'PITAL'},
        {value:'41770', label:'SUAZA'},
        {value:'41791', label:'TARQUI'},
        {value:'41016', label:'AIPE'},
        {value:'41020', label:'ALGECIRAS'},
        {value:'41078', label:'BARAYA'},
        {value:'41132', label:'CAMPOALEGRE'},
        {value:'41206', label:'COLOMBIA'},
        {value:'41349', label:'HOBO'},
        {value:'41357', label:'IQUIRA'},
        {value:'41001', label:'NEIVA'},
        {value:'41524', label:'PALERMO'},
        {value:'41615', label:'RIVERA'},
        {value:'41676', label:'SANTA MARÍA'},
        {value:'41799', label:'TELLO'},
        {value:'41801', label:'TERUEL'},
        {value:'41872', label:'VILLAVIEJA'},
        {value:'41885', label:'YAGUARÁ'},
        {value:'41378', label:'LA ARGENTINA'},
        {value:'41396', label:'LA PLATA'},
        {value:'41483', label:'NÁTAGA'},
        {value:'41518', label:'PAICOL'},
        {value:'41797', label:'TESALIA'},
        {value:'41006', label:'ACEVEDO'},
        {value:'41244', label:'ELÍAS'},
        {value:'41359', label:'ISNOS'},
        {value:'41503', label:'OPORAPA'},
        {value:'41530', label:'PALESTINA'},
        {value:'41551', label:'PITALITO'},
        {value:'41660', label:'SALADOBLANCO'},
        {value:'41668', label:'SAN AGUSTÍN'},
        {value:'41807', label:'TIMANÁ'},
        {value:'44035', label:'ALBANIA'},
        {value:'44090', label:'DIBULLA'},
        {value:'44430', label:'MAICAO'},
        {value:'44560', label:'MANAURE'},
        {value:'44001', label:'RIOHACHA'},
        {value:'44847', label:'URIBIA'},
        {value:'44078', label:'BARRANCAS'},
        {value:'44098', label:'DISTRACCION'},
        {value:'44110', label:'EL MOLINO'},
        {value:'44279', label:'FONSECA'},
        {value:'44378', label:'HATONUEVO'},
        {value:'44420', label:'LA JAGUA DEL PILAR'},
        {value:'44650', label:'SAN JUAN DEL CESAR'},
        {value:'44855', label:'URUMITA'},
        {value:'44874', label:'VILLANUEVA'},
        {value:'47058', label:'ARIGUANÍ'},
        {value:'47170', label:'CHIBOLO'},
        {value:'47460', label:'NUEVA GRANADA'},
        {value:'47555', label:'PLATO'},
        {value:'47660', label:'SABANAS DE SAN ANGEL'},
        {value:'47798', label:'TENERIFE'},
        {value:'47030', label:'ALGARROBO'},
        {value:'47053', label:'ARACATACA'},
        {value:'47189', label:'CIÉNAGA'},
        {value:'47268', label:'EL RETEN'},
        {value:'47288', label:'FUNDACION'},
        {value:'47570', label:'PUEBLO VIEJO'},
        {value:'47980', label:'ZONA BANANERA'},
        {value:'47161', label:'CERRO SAN ANTONIO'},
        {value:'47205', label:'CONCORDIA'},
        {value:'47258', label:'EL PIÑON'},
        {value:'47541', label:'PEDRAZA'},
        {value:'47551', label:'PIVIJAY'},
        {value:'47605', label:'REMOLINO'},
        {value:'47675', label:'SALAMINA'},
        {value:'47745', label:'SITIONUEVO'},
        {value:'47960', label:'ZAPAYAN'},
        {value:'47001', label:'SANTA MARTA'},
        {value:'47245', label:'EL BANCO'},
        {value:'47318', label:'GUAMAL'},
        {value:'47545', label:'PIJIÑO DEL CARMEN'},
        {value:'47692', label:'SAN SEBASTIAN DE BUENAVISTA'},
        {value:'47703', label:'SAN ZENON'},
        {value:'47707', label:'SANTA ANA'},
        {value:'47720', label:'SANTA BARBARA DE PINTO'},
        {value:'50251', label:'EL CASTILLO'},
        {value:'50270', label:'EL DORADO'},
        {value:'50287', label:'FUENTE DE ORO'},
        {value:'50313', label:'GRANADA'},
        {value:'50350', label:'LA MACARENA'},
        {value:'50370', label:'LA URIBE'},
        {value:'50400', label:'LEJANÍAS'},
        {value:'50325', label:'MAPIRIPaN'},
        {value:'50330', label:'MESETAS'},
        {value:'50450', label:'PUERTO CONCORDIA'},
        {value:'50577', label:'PUERTO LLERAS'},
        {value:'50590', label:'PUERTO RICO'},
        {value:'50683', label:'SAN JUAN DE ARAMA'},
        {value:'50711', label:'VISTA HERMOSA'},
        {value:'50001', label:'VILLAVICENCIO'},
        {value:'50006', label:'ACACiAS'},
        {value:'50110', label:'BARRANCA DE UPIA'},
        {value:'50150', label:'CASTILLA LA NUEVA'},
        {value:'50226', label:'CUMARAL'},
        {value:'50245', label:'EL CALVARIO'},
        {value:'50318', label:'GUAMAL'},
        {value:'50606', label:'RESTREPO'},
        {value:'50680', label:'SAN CARLOS GUAROA'},
        {value:'50686', label:'SAN JUANITO'},
        {value:'50223', label:'SAN LUIS DE CUBARRAL'},
        {value:'50689', label:'SAN MARTÍN'},
        {value:'50124', label:'CABUYARO'},
        {value:'50568', label:'PUERTO GAITÁN'},
        {value:'50573', label:'PUERTO LoPEZ'},
        {value:'52240', label:'CHACHAGUI'},
        {value:'52207', label:'CONSACA'},
        {value:'52254', label:'EL PEÑOL'},
        {value:'52260', label:'EL TAMBO'},
        {value:'52381', label:'LA FLORIDA'},
        {value:'52480', label:'NARIÑO'},
        {value:'52001', label:'PASTO'},
        {value:'52683', label:'SANDONÁ'},
        {value:'52788', label:'TANGUA'},
        {value:'52885', label:'YACUANQUER'},
        {value:'52036', label:'ANCUYA'},
        {value:'52320', label:'GUAITARILLA'},
        {value:'52385', label:'LA LLANADA'},
        {value:'52411', label:'LINARES'},
        {value:'52418', label:'LOS ANDES'},
        {value:'52435', label:'MALLAMA'},
        {value:'52506', label:'OSPINA'},
        {value:'52565', label:'PROVIDENCIA'},
        {value:'52612', label:'RICAURTE'},
        {value:'52678', label:'SAMANIEGO'},
        {value:'52699', label:'SANTA CRUZ'},
        {value:'52720', label:'SAPUYES'},
        {value:'52838', label:'TUQUERRES'},
        {value:'52079', label:'BARBACOAS'},
        {value:'52250', label:'EL CHARCO'},
        {value:'52520', label:'FRANCISCO PIZARRO'},
        {value:'52390', label:'LA TOLA'},
        {value:'52427', label:'Magui'},
        {value:'52473', label:'MOSQUERA'},
        {value:'52490', label:'OLAYA HERRERA'},
        {value:'52621', label:'ROBERTO PAYAN'},
        {value:'52696', label:'SANTA BaRBARA'},
        {value:'52835', label:'TUMACO'},
        {value:'52019', label:'ALBAN'},
        {value:'52051', label:'ARBOLEDA'},
        {value:'52083', label:'BELEN'},
        {value:'52110', label:'BUESACO'},
        {value:'52203', label:'COLON'},
        {value:'52233', label:'CUMBITARA'},
        {value:'52256', label:'EL ROSARIO'},
        {value:'52258', label:'El Tablon de Gomez'},
        {value:'52378', label:'LA CRUZ'},
        {value:'52399', label:'LA UNION'},
        {value:'52405', label:'LEIVA'},
        {value:'52540', label:'POLICARPA'},
        {value:'52685', label:'SAN BERNARDO'},
        {value:'52687', label:'SAN LORENZO'},
        {value:'52693', label:'SAN PABLO'},
        {value:'52694', label:'SAN PEDRO DE CARTAGO'},
        {value:'52786', label:'TAMINANGO'},
        {value:'52022', label:'ALDANA'},
        {value:'52210', label:'CONTADERO'},
        {value:'52215', label:'CÓRDOBA'},
        {value:'52224', label:'CUASPUD'},
        {value:'52227', label:'CUMBAL'},
        {value:'52287', label:'FUNES'},
        {value:'52317', label:'GUACHUCAL'},
        {value:'52323', label:'GUALMATAN'},
        {value:'52352', label:'ILES'},
        {value:'52354', label:'IMUES'},
        {value:'52356', label:'IPIALES'},
        {value:'52560', label:'POTOSÍ'},
        {value:'52573', label:'PUERRES'},
        {value:'52585', label:'PUPIALES'},
        {value:'54051', label:'ARBOLEDAS'},
        {value:'54223', label:'CUCUTILLA'},
        {value:'54313', label:'GRAMALOTE'},
        {value:'54418', label:'LOURDES'},
        {value:'54660', label:'SALAZAR'},
        {value:'54680', label:'SANTIAGO'},
        {value:'54871', label:'VILLA CARO'},
        {value:'54109', label:'BUCARASICA'},
        {value:'54250', label:'EL TARRA'},
        {value:'54720', label:'SARDINATA'},
        {value:'54810', label:'TIBÚ'},
        {value:'54003', label:'ABREGO'},
        {value:'54128', label:'CACHIRÁ'},
        {value:'54206', label:'CONVENCIÓN'},
        {value:'54245', label:'EL CARMEN'},
        {value:'54344', label:'HACARÍ'},
        {value:'54385', label:'LA ESPERANZA'},
        {value:'54398', label:'LA PLAYA'},
        {value:'54498', label:'OCAÑA'},
        {value:'54670', label:'SAN CALIXTO'},
        {value:'54800', label:'TEORAMA'},
        {value:'54001', label:'CÚCUTA'},
        {value:'54261', label:'EL ZULIA'},
        {value:'54405', label:'LOS PATIOS'},
        {value:'54553', label:'PUERTO SANTANDER'},
        {value:'54673', label:'SAN CAYETANO'},
        {value:'54874', label:'VILLA DEL ROSARIO'},
        {value:'54125', label:'CÁCOTA'},
        {value:'54174', label:'CHITAGÁ'},
        {value:'54480', label:'MUTISCUA'},
        {value:'54518', label:'PAMPLONA'},
        {value:'54520', label:'PAMPLONITA'},
        {value:'54743', label:'SILOS'},
        {value:'54099', label:'BOCHALEMA'},
        {value:'54172', label:'CHINÁCOTA'},
        {value:'54239', label:'DURANIA'},
        {value:'54347', label:'HERRÁN'},
        {value:'54377', label:'LABATECA'},
        {value:'54599', label:'RAGONVALIA'},
        {value:'54820', label:'TOLEDO'},
        {value:'86219', label:'COLÓN'},
        {value:'86001', label:'MOCOA'},
        {value:'86320', label:'ORITO'},
        {value:'86568', label:'PUERTO ASIS'},
        {value:'86569', label:'PUERTO CAICEDO'},
        {value:'86571', label:'PUERTO GUZMAN'},
        {value:'86573', label:'PUERTO LEGUIZAMO'},
        {value:'86755', label:'SAN FRANCISCO'},
        {value:'86757', label:'SAN MIGUEL'},
        {value:'86760', label:'SANTIAGO'},
        {value:'86749', label:'SIBUNDOY'},
        {value:'86865', label:'VALLE DEL GUAMUEZ'},
        {value:'86885', label:'VILLA GARZON'},
        {value:'63001', label:'ARMENIA'},
        {value:'63111', label:'BUENAVISTA'},
        {value:'63130', label:'CALARCA'},
        {value:'63212', label:'CoRDOBA'},
        {value:'63302', label:'GeNOVA'},
        {value:'63548', label:'PIJAO'},
        {value:'63272', label:'FILANDIA'},
        {value:'63690', label:'SALENTO'},
        {value:'63190', label:'CIRCASIA'},
        {value:'63401', label:'LA TEBAIDA'},
        {value:'63470', label:'Montengro'},
        {value:'63594', label:'QUIMBAYA'},
        {value:'66170', label:'DOSQUEBRADAS'},
        {value:'66400', label:'LA VIRGINIA'},
        {value:'66440', label:'MARSELLA'},
        {value:'66001', label:'PEREIRA'},
        {value:'66682', label:'SANTA ROSA DE CABAL'},
        {value:'66045', label:'APÍA'},
        {value:'66075', label:'BALBOA'},
        {value:'66088', label:'BELÉN DE UMBRÍA'},
        {value:'66318', label:'GUÁTICA'},
        {value:'66383', label:'LA CELIA'},
        {value:'66594', label:'QUINCHiA'},
        {value:'66687', label:'SANTUARIO'},
        {value:'66456', label:'MISTRATÓ'},
        {value:'66572', label:'PUEBLO RICO'},
        {value:'68176', label:'CHIMA'},
        {value:'68209', label:'CONFINES'},
        {value:'68211', label:'CONTRATACIÓN'},
        {value:'68245', label:'EL GUACAMAYO'},
        {value:'68296', label:'GALÁN'},
        {value:'68298', label:'GAMBITA'},
        {value:'68320', label:'GUADALUPE'},
        {value:'68322', label:'GUAPOTÁ'},
        {value:'68344', label:'HATO'},
        {value:'68500', label:'OIBA'},
        {value:'68522', label:'PALMAR'},
        {value:'68524', label:'PALMAS DEL SOCORRO'},
        {value:'68720', label:'SANTA HELENA DEL OPÓN'},
        {value:'68745', label:'SIMACOTA'},
        {value:'68755', label:'SOCORRO'},
        {value:'68770', label:'SUAITA'},
        {value:'68147', label:'CAPITANEJO'},
        {value:'68152', label:'CARCASÍ'},
        {value:'68160', label:'CEPITÁ'},
        {value:'68162', label:'CERRITO'},
        {value:'68207', label:'CONCEPCIÓN'},
        {value:'68266', label:'ENCISO'},
        {value:'68318', label:'GUACA'},
        {value:'68425', label:'MACARAVITA'},
        {value:'68432', label:'MÁLAGA'},
        {value:'68468', label:'MOLAGAVITA'},
        {value:'68669', label:'SAN ANDRÉS'},
        {value:'68684', label:'SAN JOSÉ DE MIRANDA'},
        {value:'68686', label:'SAN MIGUEL'},
        {value:'68051', label:'ARATOCA'},
        {value:'68079', label:'BARICHARA'},
        {value:'68121', label:'CABRERA'},
        {value:'68167', label:'CHARALÁ'},
        {value:'68217', label:'COROMORO'},
        {value:'68229', label:'CURITÍ'},
        {value:'68264', label:'ENCINO'},
        {value:'68370', label:'JORDÁN'},
        {value:'68464', label:'MOGOTES'},
        {value:'68498', label:'OCAMONTE'},
        {value:'68502', label:'ONZAGA'},
        {value:'68533', label:'PÁRAMO'},
        {value:'68549', label:'PINCHOTE'},
        {value:'68679', label:'SAN GIL'},
        {value:'68682', label:'SAN JOAQUÍN'},
        {value:'68855', label:'VALLE DE SAN JOSÉ'},
        {value:'68872', label:'VILLANUEVA'},
        {value:'68081', label:'BARRANCABERMEJA'},
        {value:'68092', label:'BETULIA'},
        {value:'68235', label:'EL CARMEN DE CHUCURÍ'},
        {value:'68575', label:'PUERTO WILCHES'},
        {value:'68655', label:'SABANA DE TORRES'},
        {value:'68689', label:'SAN VICENTE DE CHUCURÍ'},
        {value:'68895', label:'ZAPATOCA'},
        {value:'68001', label:'BUCARAMANGA'},
        {value:'68132', label:'CALIFORNIA'},
        {value:'68169', label:'CHARTA'},
        {value:'68255', label:'EL PLAYÓN'},
        {value:'68276', label:'FLORIDABLANCA'},
        {value:'68307', label:'GIRÓN'},
        {value:'68406', label:'LEBRÍJA'},
        {value:'68418', label:'LOS SANTOS'},
        {value:'68444', label:'MATANZA'},
        {value:'68547', label:'PIEDECUESTA'},
        {value:'68615', label:'RIONEGRO'},
        {value:'68705', label:'SANTA BÁRBARA'},
        {value:'68780', label:'SURATA'},
        {value:'68820', label:'TONA'},
        {value:'68867', label:'VETAS'},
        {value:'68013', label:'AGUADA'},
        {value:'68020', label:'ALBANIA'},
        {value:'68077', label:'BARBOSA'},
        {value:'68101', label:'BOLÍVAR'},
        {value:'68179', label:'CHIPATÁ'},
        {value:'68190', label:'CIMITARRA'},
        {value:'68250', label:'EL PEÑÓN'},
        {value:'68271', label:'FLORIÁN'},
        {value:'68324', label:'GUAVATÁ'},
        {value:'68327', label:'GuEPSA'},
        {value:'68368', label:'JESÚS MARÍA'},
        {value:'68377', label:'LA BELLEZA'},
        {value:'68397', label:'LA PAZ'},
        {value:'68385', label:'LANDÁZURI'},
        {value:'68572', label:'PUENTE NACIONAL'},
        {value:'68573', label:'PUERTO PARRA'},
        {value:'68673', label:'SAN BENITO'},
        {value:'68773', label:'SUCRE'},
        {value:'68861', label:'VÉLEZ'},
        {value:'70265', label:'GUARANDA'},
        {value:'70429', label:'MAJAGUAL'},
        {value:'70771', label:'SUCRE'},
        {value:'70230', label:'CHALÁN'},
        {value:'70204', label:'COLOSO'},
        {value:'70473', label:'MORROA'},
        {value:'70508', label:'OVEJAS'},
        {value:'70001', label:'SINCELEJO'},
        {value:'70221', label:'COVEÑAS'},
        {value:'70523', label:'PALMITO'},
        {value:'70713', label:'SAN ONOFRE'},
        {value:'70820', label:'SANTIAGO DE TOLÚ'},
        {value:'70823', label:'TOLÚ VIEJO'},
        {value:'70110', label:'BUENAVISTA'},
        {value:'70215', label:'COROZAL'},
        {value:'70233', label:'EL ROBLE'},
        {value:'70235', label:'GALERAS'},
        {value:'70418', label:'LOS PALMITOS'},
        {value:'70670', label:'SAMPUÉS'},
        {value:'70702', label:'SAN JUAN BETULIA'},
        {value:'70717', label:'SAN PEDRO'},
        {value:'70742', label:'SINCÉ'},
        {value:'70124', label:'CAIMITO'},
        {value:'70400', label:'LA UNIÓN'},
        {value:'70678', label:'SAN BENITO ABAD'},
        {value:'70708', label:'SAN MARCOS'},
        {value:'73030', label:'AMBALEMA'},
        {value:'73055', label:'ARMERO'},
        {value:'73270', label:'FALAN'},
        {value:'73283', label:'FRESNO'},
        {value:'73349', label:'HONDA'},
        {value:'73443', label:'MARIQUITA'},
        {value:'73520', label:'PALOCABILDO'},
        {value:'73148', label:'CARMEN DE APICALÁ'},
        {value:'73226', label:'CUNDAY'},
        {value:'73352', label:'ICONONZO'},
        {value:'73449', label:'MELGAR'},
        {value:'73873', label:'VILLARRICA'},
        {value:'73067', label:'ATACO'},
        {value:'73168', label:'CHAPARRAL'},
        {value:'73217', label:'COYAIMA'},
        {value:'73483', label:'NATAGAIMA'},
        {value:'73504', label:'ORTEGA'},
        {value:'73555', label:'PLANADAS'},
        {value:'73616', label:'RIOBLANCO'},
        {value:'73622', label:'RONCESVALLES'},
        {value:'73675', label:'SAN ANTONIO'},
        {value:'73026', label:'ALVARADO'},
        {value:'73043', label:'ANZOÁTEGUI'},
        {value:'73124', label:'CAJAMARCA'},
        {value:'73200', label:'COELLO'},
        {value:'73268', label:'ESPINAL'},
        {value:'73275', label:'FLANDES'},
        {value:'73001', label:'IBAGUe'},
        {value:'73547', label:'PIEDRAS'},
        {value:'73624', label:'ROVIRA'},
        {value:'73678', label:'SAN LUIS'},
        {value:'73854', label:'VALLE DE SAN JUAN'},
        {value:'73024', label:'ALPUJARRA'},
        {value:'73236', label:'DOLORES'},
        {value:'73319', label:'GUAMO'},
        {value:'73563', label:'PRADO'},
        {value:'73585', label:'PURIFICACIÓN'},
        {value:'73671', label:'SALDAÑA'},
        {value:'73770', label:'SUÁREZ'},
        {value:'73152', label:'CASABIANCA'},
        {value:'73347', label:'HERVEO'},
        {value:'73408', label:'LeRIDA'},
        {value:'73411', label:'LiBANO'},
        {value:'73461', label:'MURILLO'},
        {value:'73686', label:'SANTA ISABEL'},
        {value:'73861', label:'VENADILLO'},
        {value:'73870', label:'VILLAHERMOSA'},
        {value:'76036', label:'ANDALUCÍA'},
        {value:'76111', label:'BUGA'},
        {value:'76113', label:'BUGALAGRANDE'},
        {value:'76126', label:'CALIMA'},
        {value:'76248', label:'EL CERRITO'},
        {value:'76306', label:'GINEBRA'},
        {value:'76318', label:'GUACARÍ'},
        {value:'76606', label:'RESTREPO'},
        {value:'76616', label:'RIOFRIO'},
        {value:'76670', label:'SAN PEDRO'},
        {value:'76828', label:'TRUJILLO'},
        {value:'76834', label:'TULUÁ'},
        {value:'76890', label:'YOTOCO'},
        {value:'76020', label:'ALCALa'},
        {value:'76041', label:'ANSERMANUEVO'},
        {value:'76054', label:'ARGELIA'},
        {value:'76100', label:'BOLÍVAR'},
        {value:'76147', label:'CARTAGO'},
        {value:'76243', label:'EL ÁGUILA'},
        {value:'76246', label:'EL CAIRO'},
        {value:'76250', label:'EL DOVIO'},
        {value:'76400', label:'LA UNIÓN'},
        {value:'76403', label:'LA VICTORIA'},
        {value:'76497', label:'OBANDO'},
        {value:'76622', label:'ROLDANILLO'},
        {value:'76823', label:'TORO'},
        {value:'76845', label:'ULLOA'},
        {value:'76863', label:'VERSALLES'},
        {value:'76895', label:'ZARZAL'},
        {value:'76109', label:'BUENAVENTURA'},
        {value:'76122', label:'CAICEDONIA'},
        {value:'76736', label:'SEVILLA'},
        {value:'76001', label:'CALI'},
        {value:'76130', label:'CANDELARIA'},
        {value:'76233', label:'DAGUA'},
        {value:'76275', label:'FLORIDA'},
        {value:'76364', label:'JAMUNDÍ'},
        {value:'76377', label:'LA CUMBRE'},
        {value:'76520', label:'PALMIRA'},
        {value:'76563', label:'PRADERA'},
        {value:'76869', label:'VIJES'},
        {value:'76892', label:'YUMBO'},
        {value:'97161', label:'CARURU'},
        {value:'97001', label:'MITÚ'},
        {value:'97511', label:'PACOA'},
        {value:'97777', label:'PAPUNAHUA'},
        {value:'97666', label:'TARAIRA'},
        {value:'97889', label:'YAVARATÉ'},
        {value:'99773', label:'CUMARIBO'},
        {value:'99524', label:'LA PRIMAVERA'},
        {value:'99001', label:'PUERTO CARREÑO'},
        {value:'99624', label:'SANTA ROSALÍA'}
];

export const ActividadEconomica = [
        {value: '0010' ,label: '0010 - Asalariados'},
        {value: '0020' ,label: '0020 - Pensionados'},
        {value: '0081' ,label: '0081 - Personas Naturales sin Antividad Económica'},
        {value: '0082' ,label: '0082 - Parsonas Naturales Subsidiadas por Terceros'},
        {value: '0090' ,label: '0112 - Rentistas de Capital, solo para personas naturales'},
        {value: '0111' ,label: '0111 - Cultivo de cereales (excepto arroz), legumbres y semillas oleaginosas'},
        {value: '0112' ,label: '0112 - Cultivo de arroz'},
        {value: '0113' ,label: '0113 - Cultivo de hortalizas, raíces y tubérculos'},
        {value: '0114' ,label: '0114 - Cultivo de tabaco'},
        {value: '0115' ,label: '0115 - Cultivo de plantas textiles'},
        {value: '0119' ,label: '0119 - Otros cultivos transitorios n.c.p.'},
        {value: '0121' ,label: '0121 - Cultivo de frutas tropicales y subtropicales'},
        {value: '0122' ,label: '0122 - Cultivo de plátano y banano'},
        {value: '0123' ,label: '0123 - Cultivo de café'},
        {value: '0124' ,label: '0124 - Cultivo de caña de azúcar'},
        {value: '0125' ,label: '0125 - Cultivo de flor de corte'},
        {value: '0126' ,label: '0126 - Cultivo de palma para aceite (palma africana) y otros frutos oleaginosos'},
        {value: '0127' ,label: '0127 - Cultivo de plantas con las que se preparan bebidas'},
        {value: '0128' ,label: '0128 - Cultivo de especias y de plantas aromáticas y medicinales'},
        {value: '0129' ,label: '0129 - Otros cultivos permanentes n.c.p.'},
        {value: '0130' ,label: '0130 - Propagación de plantas (actividades de los viveros, excepto viveros forestales)'},
        {value: '0141' ,label: '0141 - Cría de ganado bovino y bufalino'},
        {value: '0142' ,label: '0142 - Cría de caballos y otros equinos'},
        {value: '0143' ,label: '0143 - Cría de ovejas y cabras'},
        {value: '0144' ,label: '0144 - Cría de ganado porcino'},
        {value: '0145' ,label: '0145 - Cría de aves de corral'},
        {value: '0149' ,label: '0149 - Cría de otros animales n.c.p.'},
        {value: '0150' ,label: '0150 - Explotación mixta (agrícola y pecuaria)'},
        {value: '0161' ,label: '0161 - Actividades de apoyo a la agricultura'},
        {value: '0162' ,label: '0162 - Actividades de apoyo a la ganadería'},
        {value: '0163' ,label: '0163 - Actividades posteriores a la cosecha'},
        {value: '0164' ,label: '0164 - Tratamiento de semillas para propagación'},
        {value: '0170' ,label: '0170 - Caza ordinaria y mediante trampas y actividades de servicios conexas'},
        {value: '0210' ,label: '0210 - Silvicultura y otras actividades forestales'},
        {value: '0220' ,label: '0220 - Extracción de madera'},
        {value: '0230' ,label: '0230 - Recolección de productos forestales diferentes a la madera'},
        {value: '0240' ,label: '0240 - Servicios de apoyo a la silvicultura'},
        {value: '0311' ,label: '0311 - Pesca marítima'},
        {value: '0312' ,label: '0312 - Pesca de agua dulce'},
        {value: '0321' ,label: '0321 - Acuicultura marítima'},
        {value: '0322' ,label: '0322 - Acuicultura de agua dulce'},
        {value: '0510' ,label: '0510 - Extracción de hulla (carbón de piedra)'},
        {value: '0520' ,label: '0520 - Extracción de carbón lignito'},
        {value: '0610' ,label: '0610 - Extracción de petróleo crudo'},
        {value: '0620' ,label: '0620 - Extracción de gas natural'},
        {value: '0710' ,label: '0710 - Extracción de minerales de hierro'},
        {value: '0721' ,label: '0721 - Extracción de minerales de uranio y de torio'},
        {value: '0722' ,label: '0722 - Extracción de oro y otros metales preciosos'},
        {value: '0723' ,label: '0723 - Extracción de minerales de níquel'},
        {value: '0729' ,label: '0729 - Extracción de otros minerales metalíferos no ferrosos n.c.p.'},
        {value: '0811' ,label: '0811 - Extracción de piedra, arena, arcillas comunes, yeso y anhidrita'},
        {value: '0812' ,label: '0812 - Extracción de arcillas de uso industrial, caliza, caolín y bentonitas'},
        {value: '0820' ,label: '0820 - Extracción de esmeraldas, piedras preciosas y semipreciosas'},
        {value: '0891' ,label: '0891 - Extracción de minerales para la fabricación de abonos y productos químicos'},
        {value: '0892' ,label: '0892 - Extracción de halita (sal)'},
        {value: '0899' ,label: '0899 - Extracción de otros minerales no metálicos n.c.p.'},
        {value: '0910' ,label: '0910 - Actividades de apoyo para la extracción de petróleo y de gas natural'},
        {value: '0990' ,label: '0990 - Actividades de apoyo para otras actividades de explotación de minas y canteras'},
        {value: '1011' ,label: '1011 - Procesamiento y conservación de carne y productos cárnicos'},
        {value: '1012' ,label: '1012 - Procesamiento y conservación de pescados, crustáceos y moluscos'},
        {value: '1020' ,label: '1020 - Procesamiento y conservación de frutas, legumbres, hortalizas y tubérculos'},
        {value: '1030' ,label: '1030 - Elaboración de aceites y grasas de origen vegetal y animal'},
        {value: '1040' ,label: '1040 - Elaboración de productos lácteos'},
        {value: '1051' ,label: '1051 - Elaboración de productos de molinería'},
        {value: '1052' ,label: '1052 - Elaboración de almidones y productos derivados del almidón'},
        {value: '1061' ,label: '1061 - Trilla de café'},
        {value: '1062' ,label: '1062 - Descafeinado, tostión y molienda del café'},
        {value: '1063' ,label: '1063 - Otros derivados del café'},
        {value: '1071' ,label: '1071 - Elaboración y refinación de azúcar'},
        {value: '1072' ,label: '1072 - Elaboración de panela'},
        {value: '1081' ,label: '1081 - Elaboración de productos de panadería'},
        {value: '1082' ,label: '1082 - Elaboración de cacao, chocolate y productos de confitería'},
        {value: '1083' ,label: '1083 - Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares'},
        {value: '1084' ,label: '1084 - Elaboración de comidas y platos preparados'},
        {value: '1089' ,label: '1089 - Elaboración de otros productos alimenticios n.c.p.'},
        {value: '1090' ,label: '1090 - Elaboración de alimentos preparados para animales'},
        {value: '1101' ,label: '1101 - Destilación, rectificación y mezcla de bebidas alcohólicas'},
        {value: '1102' ,label: '1102 - Elaboración de bebidas fermentadas no destiladas'},
        {value: '1103' ,label: '1103 - Producción de malta, elaboración de cervezas y otras bebidas malteadas'},
        {value: '1104' ,label: '1104 - Elaboración de bebidas no alcohólicas, producción de aguas minerales y otras aguas embotelladas'},
        {value: '1200' ,label: '1200 - Elaboración de productos de tabaco'},
        {value: '1311' ,label: '1311 - Preparación e hilatura de fibras textiles'},
        {value: '1312' ,label: '1312 - Tejeduría de productos textiles'},
        {value: '1313' ,label: '1313 - Acabado de productos textiles'},
        {value: '1391' ,label: '1391 - Fabricación de tejidos de punto y ganchillo'},
        {value: '1392' ,label: '1392 - Confección de artículos con materiales textiles, excepto prendas de vestir'},
        {value: '1393' ,label: '1393 - Fabricación de tapetes y alfombras para pisos'},
        {value: '1394' ,label: '1394 - Fabricación de cuerdas, cordeles, cables, bramantes y redes'},
        {value: '1399' ,label: '1399 - Fabricación de otros artículos textiles n.c.p.'},
        {value: '1410' ,label: '1410 - Confección de prendas de vestir, excepto prendas de piel'},
        {value: '1420' ,label: '1420 - Fabricación de artículos de piel'},
        {value: '1430' ,label: '1430 - Fabricación de artículos de punto y ganchillo'},
        {value: '1511' ,label: '1511 - Curtido y recurtido de cueros; recurtido y teñido de pieles'},
        {value: '1512' ,label: '1512 - Fabricación de artículos de viaje, bolsos de mano y artículos similares elaborados en cuero, y fabricación de artículos de talabartería y guarnicionería'},
        {value: '1513' ,label: '1513 - Fabricación de artículos de viaje, bolsos de mano y artículos similares; artículos de talabartería y guarnicionería elaborados en otros materiales'},
        {value: '1521' ,label: '1521 - Fabricación de calzado de cuero y piel, con cualquier tipo de suela'},
        {value: '1522' ,label: '1522 - Fabricación de otros tipos de calzado, excepto calzado de cuero y piel'},
        {value: '1523' ,label: '1523 - Fabricación de partes del calzado'},
        {value: '1610' ,label: '1610 - Aserrado, acepillado e impregnación de la madera'},
        {value: '1620' ,label: '1620 - Fabricación de hojas de madera para enchapado; fabricación de tableros contrachapados, tableros laminados, tableros de partículas y otros tableros y paneles'},
        {value: '1630' ,label: '1630 - Fabricación de partes y piezas de madera, de carpintería y ebanistería para la construcción'},
        {value: '1640' ,label: '1640 - Fabricación de recipientes de madera'},
        {value: '1690' ,label: '1690 - Fabricación de otros productos de madera; fabricación de artículos de corcho, cestería y espartería'},
        {value: '1701' ,label: '1701 - Fabricación de pulpas (pastas) celulósicas; papel y cartón'},
        {value: '1702' ,label: '1702 - Fabricación de papel y cartón ondulado (corrugado); fabricación de envases, empaques y de embalajes de papel y cartón'},
        {value: '1709' ,label: '1709 - Fabricación de otros artículos de papel y cartón'},
        {value: '1811' ,label: '1811 - Actividades de impresión'},
        {value: '1812' ,label: '1812 - Actividades de servicios relacionados con la impresión'},
        {value: '1820' ,label: '1820 - Producción de copias a partir de grabaciones originales'},
        {value: '1910' ,label: '1910 - Fabricación de productos de hornos de coque'},
        {value: '1921' ,label: '1921 - Fabricación de productos de la refinación del petróleo'},
        {value: '1922' ,label: '1922 - Actividad de mezcla de combustibles'},
        {value: '2011' ,label: '2011 - Fabricación de sustancias y productos químicos básicos'},
        {value: '2012' ,label: '2012 - Fabricación de abonos y compuestos inorgánicos nitrogenados'},
        {value: '2013' ,label: '2013 - Fabricación de plásticos en formas primarias'},
        {value: '2014' ,label: '2014 - Fabricación de caucho sintético en formas primarias'},
        {value: '2021' ,label: '2021 - Fabricación de plaguicidas y otros productos químicos de uso agropecuario'},
        {value: '2022' ,label: '2022 - Fabricación de pinturas, barnices y revestimientos similares, tintas para impresión y masillas'},
        {value: '2023' ,label: '2023 - Fabricación de jabones y detergentes, preparados para limpiar y pulir; perfumes y preparados de tocador'},
        {value: '2029' ,label: '2029 - Fabricación de otros productos químicos n.c.p.'},
        {value: '2030' ,label: '2030 - Fabricación de fibras sintéticas y artificiales'},
        {value: '2100' ,label: '2100 - Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico'},
        {value: '2211' ,label: '2211 - Fabricación de llantas y neumáticos de caucho'},
        {value: '2212' ,label: '2212 - Reencauche de llantas usadas'},
        {value: '2219' ,label: '2219 - Fabricación de formas básicas de caucho y otros productos de caucho n.c.p.'},
        {value: '2221' ,label: '2221 - Fabricación de formas básicas de plástico'},
        {value: '2229' ,label: '2229 - Fabricación de artículos de plástico n.c.p.'},
        {value: '2310' ,label: '2310 - Fabricación de vidrio y productos de vidrio'},
        {value: '2391' ,label: '2391 - Fabricación de productos refractarios'},
        {value: '2392' ,label: '2392 - Fabricación de materiales de arcilla para la construcción'},
        {value: '2393' ,label: '2393 - Fabricación de otros productos de cerámica y porcelana'},
        {value: '2394' ,label: '2394 - Fabricación de cemento, cal y yeso'},
        {value: '2395' ,label: '2395 - Fabricación de artículos de hormigón, cemento y yeso'},
        {value: '2396' ,label: '2396 - Corte, tallado y acabado de la piedra'},
        {value: '2399' ,label: '2399 - Fabricación de otros productos minerales no metálicos n.c.p.'},
        {value: '2410' ,label: '2410 - Industrias básicas de hierro y de acero'},
        {value: '2421' ,label: '2421 - Industrias básicas de metales preciosos'},
        {value: '2429' ,label: '2429 - Industrias básicas de otros metales no ferrosos'},
        {value: '2431' ,label: '2431 - Fundición de hierro y de acero'},
        {value: '2432' ,label: '2432 - Fundición de metales no ferrosos'},
        {value: '2511' ,label: '2511 - Fabricación de productos metálicos para uso estructural'},
        {value: '2512' ,label: '2512 - Fabricación de tanques, depósitos y recipientes de metal, excepto los utilizados para el envase o el transporte de mercancías'},
        {value: '2513' ,label: '2513 - Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central'},
        {value: '2520' ,label: '2520 - Fabricación de armas y municiones'},
        {value: '2591' ,label: '2591 - Forja, prensado, estampado y laminado de metal; pulvimetalurgia'},
        {value: '2592' ,label: '2592 - Tratamiento y revestimiento de metales; mecanizado'},
        {value: '2593' ,label: '2593 - Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería'},
        {value: '2599' ,label: '2599 - Fabricación de otros productos elaborados de metal n.c.p.'},
        {value: '2610' ,label: '2610 - Fabricación de componentes y tableros electrónicos'},
        {value: '2620' ,label: '2620 - Fabricación de computadoras y de equipo periférico'},
        {value: '2630' ,label: '2630 - Fabricación de equipos de comunicación'},
        {value: '2640' ,label: '2640 - Fabricación de aparatos electrónicos de consumo'},
        {value: '2651' ,label: '2651 - Fabricación de equipo de medición, prueba, navegación y control'},
        {value: '2652' ,label: '2652 - Fabricación de relojes'},
        {value: '2660' ,label: '2660 - Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico'},
        {value: '2670' ,label: '2670 - Fabricación de instrumentos ópticos y equipo fotográfico'},
        {value: '2680' ,label: '2680 - Fabricación de medios magnéticos y ópticos para almacenamiento de datos'},
        {value: '2711' ,label: '2711 - Fabricación de motores, generadores y transformadores eléctricos'},
        {value: '2712' ,label: '2712 - Fabricación de aparatos de distribución y control de la energía eléctrica'},
        {value: '2720' ,label: '2720 - Fabricación de pilas, baterías y acumuladores eléctricos'},
        {value: '2731' ,label: '2731 - Fabricación de hilos y cables eléctricos y de fibra óptica'},
        {value: '2732' ,label: '2732 - Fabricación de dispositivos de cableado'},
        {value: '2740' ,label: '2740 - Fabricación de equipos eléctricos de iluminación'},
        {value: '2750' ,label: '2750 - Fabricación de aparatos de uso doméstico'},
        {value: '2790' ,label: '2790 - Fabricación de otros tipos de equipo eléctrico n.c.p.'},
        {value: '2811' ,label: '2811 - Fabricación de motores, turbinas, y partes para motores de combustión interna'},
        {value: '2812' ,label: '2812 - Fabricación de equipos de potencia hidráulica y neumática'},
        {value: '2813' ,label: '2813 - Fabricación de otras bombas, compresores, grifos y válvulas'},
        {value: '2814' ,label: '2814 - Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión'},
        {value: '2815' ,label: '2815 - Fabricación de hornos, hogares y quemadores industriales'},
        {value: '2816' ,label: '2816 - Fabricación de equipo de elevación y manipulación'},
        {value: '2817' ,label: '2817 - Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipo periférico)'},
        {value: '2818' ,label: '2818 - Fabricación de herramientas manuales con motor'},
        {value: '2819' ,label: '2819 - Fabricación de otros tipos de maquinaria y equipo de uso general n.c.p.'},
        {value: '2821' ,label: '2821 - Fabricación de maquinaria agropecuaria y forestal'},
        {value: '2822' ,label: '2822 - Fabricación de máquinas formadoras de metal y de máquinas herramienta'},
        {value: '2823' ,label: '2823 - Fabricación de maquinaria para la metalurgia'},
        {value: '2824' ,label: '2824 - Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción'},
        {value: '2825' ,label: '2825 - Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco'},
        {value: '2826' ,label: '2826 - Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros'},
        {value: '2829' ,label: '2829 - Fabricación de otros tipos de maquinaria y equipo de uso especial n.c.p.'},
        {value: '2910' ,label: '2910 - Fabricación de vehículos automotores y sus motores'},
        {value: '2920' ,label: '2920 - Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques'},
        {value: '2930' ,label: '2930 - Fabricación de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores'},
        {value: '3011' ,label: '3011 - Construcción de barcos y de estructuras flotantes'},
        {value: '3012' ,label: '3012 - Construcción de embarcaciones de recreo y deporte'},
        {value: '3020' ,label: '3020 - Fabricación de locomotoras y de material rodante para ferrocarriles'},
        {value: '3030' ,label: '3030 - Fabricación de aeronaves, naves espaciales y de maquinaria conexa'},
        {value: '3040' ,label: '3040 - Fabricación de vehículos militares de combate'},
        {value: '3091' ,label: '3091 - Fabricación de motocicletas'},
        {value: '3092' ,label: '3092 - Fabricación de bicicletas y de sillas de ruedas para personas con discapacidad'},
        {value: '3099' ,label: '3099 - Fabricación de otros tipos de equipo de transporte n.c.p.'},
        {value: '3110' ,label: '3110 - Fabricación de muebles'},
        {value: '3120' ,label: '3120 - Fabricación de colchones y somieres'},
        {value: '3210' ,label: '3210 - Fabricación de joyas, bisutería y artículos conexos'},
        {value: '3220' ,label: '3220 - Fabricación de instrumentos musicales'},
        {value: '3230' ,label: '3230 - Fabricación de artículos y equipo para la práctica del deporte'},
        {value: '3240' ,label: '3240 - Fabricación de juegos, juguetes y rompecabezas'},
        {value: '3250' ,label: '3250 - Fabricación de instrumentos, aparatos y materiales médicos y odontológicos (incluido mobiliario)'},
        {value: '3290' ,label: '3290 - Otras industrias manufactureras n.c.p.'},
        {value: '3311' ,label: '3311 - Mantenimiento y reparación especializado de productos elaborados en metal'},
        {value: '3312' ,label: '3312 - Mantenimiento y reparación especializado de maquinaria y equipo'},
        {value: '3313' ,label: '3313 - Mantenimiento y reparación especializado de equipo electrónico y óptico'},
        {value: '3314' ,label: '3314 - Mantenimiento y reparación especializado de equipo eléctrico'},
        {value: '3315' ,label: '3315 - Mantenimiento y reparación especializado de equipo de transporte, excepto los vehículos automotores, motocicletas y bicicletas'},
        {value: '3319' ,label: '3319 - Mantenimiento y reparación de otros tipos de equipos y sus componentes n.c.p.'},
        {value: '3320' ,label: '3320 - Instalación especializada de maquinaria y equipo industrial'},
        {value: '3511' ,label: '3511 - Generación de energía eléctrica'},
        {value: '3512' ,label: '3512 - Transmisión de energía eléctrica'},
        {value: '3513' ,label: '3513 - Distribución de energía eléctrica'},
        {value: '3514' ,label: '3514 - Comercialización de energía eléctrica'},
        {value: '3520' ,label: '3520 - Producción de gas; distribución de combustibles gaseosos por tuberías'},
        {value: '3530' ,label: '3530 - Suministro de vapor y aire acondicionado'},
        {value: '3600' ,label: '3600 - Captación, tratamiento y distribución de agua'},
        {value: '3700' ,label: '3700 - Evacuación y tratamiento de aguas residuales'},
        {value: '3811' ,label: '3811 - Recolección de desechos no peligrosos'},
        {value: '3812' ,label: '3812 - Recolección de desechos peligrosos'},
        {value: '3821' ,label: '3821 - Tratamiento y disposición de desechos no peligrosos'},
        {value: '3822' ,label: '3822 - Tratamiento y disposición de desechos peligrosos'},
        {value: '3830' ,label: '3830 - Recuperación de materiales'},
        {value: '3900' ,label: '3900 - Actividades de saneamiento ambiental y otros servicios de gestión de desechos'},
        {value: '4111' ,label: '4111 - Construcción de edificios residenciales'},
        {value: '4112' ,label: '4112 - Construcción de edificios no residenciales'},
        {value: '4210' ,label: '4210 - Construcción de carreteras y vías de ferrocarril'},
        {value: '4220' ,label: '4220 - Construcción de proyectos de servicio público'},
        {value: '4290' ,label: '4290 - Construcción de otras obras de ingeniería civil'},
        {value: '4311' ,label: '4311 - Demolición'},
        {value: '4312' ,label: '4312 - Preparación del terreno'},
        {value: '4321' ,label: '4321 - Instalaciones eléctricas'},
        {value: '4322' ,label: '4322 - Instalaciones de fontanería, calefacción y aire acondicionado'},
        {value: '4329' ,label: '4329 - Otras instalaciones especializadas'},
        {value: '4330' ,label: '4330 - Terminación y acabado de edificios y obras de ingeniería civil'},
        {value: '4390' ,label: '4390 - Otras actividades especializadas para la construcción de edificios y obras de ingeniería civil'},
        {value: '4511' ,label: '4511 - Comercio de vehículos automotores nuevos'},
        {value: '4512' ,label: '4512 - Comercio de vehículos automotores usados'},
        {value: '4520' ,label: '4520 - Mantenimiento y reparación de vehículos automotores'},
        {value: '4530' ,label: '4530 - Comercio de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores'},
        {value: '4541' ,label: '4541 - Comercio de motocicletas y de sus partes, piezas y accesorios'},
        {value: '4542' ,label: '4542 - Mantenimiento y reparación de motocicletas y de sus partes y piezas'},
        {value: '4610' ,label: '4610 - Comercio al por mayor a cambio de una retribución o por contrata'},
        {value: '4620' ,label: '4620 - Comercio al por mayor de materias primas agropecuarias; animales vivos'},
        {value: '4631' ,label: '4631 - Comercio al por mayor de productos alimenticios'},
        {value: '4632' ,label: '4632 - Comercio al por mayor de bebidas y tabaco'},
        {value: '4641' ,label: '4641 - Comercio al por mayor de productos textiles, productos confeccionados para uso doméstico'},
        {value: '4642' ,label: '4642 - Comercio al por mayor de prendas de vestir'},
        {value: '4643' ,label: '4643 - Comercio al por mayor de calzado'},
        {value: '4644' ,label: '4644 - Comercio al por mayor de aparatos y equipo de uso doméstico'},
        {value: '4645' ,label: '4645 - Comercio al por mayor de productos farmacéuticos, medicinales, cosméticos y de tocador'},
        {value: '4649' ,label: '4649 - Comercio al por mayor de otros utensilios domésticos n.c.p.'},
        {value: '4651' ,label: '4651 - Comercio al por mayor de computadores, equipo periférico y programas de informática'},
        {value: '4652' ,label: '4652 - Comercio al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones'},
        {value: '4653' ,label: '4653 - Comercio al por mayor de maquinaria y equipo agropecuarios'},
        {value: '4659' ,label: '4659 - Comercio al por mayor de otros tipos de maquinaria y equipo n.c.p.'},
        {value: '4661' ,label: '4661 - Comercio al por mayor de combustibles sólidos, líquidos, gaseosos y productos conexos'},
        {value: '4662' ,label: '4662 - Comercio al por mayor de metales y productos metalíferos'},
        {value: '4663' ,label: '4663 - Comercio al por mayor de materiales de construcción, artículos de ferretería, pinturas, productos de vidrio, equipo y materiales de fontanería y calefacción'},
        {value: '4664' ,label: '4664 - Comercio al por mayor de productos químicos básicos, cauchos y plásticos en formas primarias y productos químicos de uso agropecuario'},
        {value: '4665' ,label: '4665 - Comercio al por mayor de desperdicios, desechos y chatarra'},
        {value: '4669' ,label: '4669 - Comercio al por mayor de otros productos n.c.p.'},
        {value: '4690' ,label: '4690 - Comercio al por mayor no especializado'},
        {value: '4711' ,label: '4711 - Comercio al por menor en establecimientos no especializados con surtido compuesto principalmente por alimentos, bebidas (alcohólicas y no alcohólicas) o tabaco'},
        {value: '4719' ,label: '4719 - Comercio al por menor en establecimientos no especializados, con surtido compuesto principalmente por productos diferentes de alimentos (víveres en general), bebidas (alcohólicas y no alcohólicas) y tabaco'},
        {value: '4721' ,label: '4721 - Comercio al por menor de productos agrícolas para el consumo en establecimientos especializados'},
        {value: '4722' ,label: '4722 - Comercio al por menor de leche, productos lácteos y huevos, en establecimientos especializados'},
        {value: '4723' ,label: '4723 - Comercio al por menor de carnes (incluye aves de corral), productos cárnicos, pescados y productos de mar, en establecimientos especializados'},
        {value: '4724' ,label: '4724 - Comercio al por menor de bebidas y productos del tabaco, en establecimientos especializados'},
        {value: '4729' ,label: '4729 - Comercio al por menor de otros productos alimenticios n.c.p., en establecimientos especializados'},
        {value: '4731' ,label: '4731 - Comercio al por menor de combustible para automotores'},
        {value: '4732' ,label: '4732 - Comercio al por menor de lubricantes (aceites, grasas), aditivos y productos de limpieza para vehículos automotores'},
        {value: '4741' ,label: '4741 - Comercio al por menor de computadores, equipos periféricos, programas de informática y equipos de telecomunicaciones en establecimientos especializados'},
        {value: '4742' ,label: '4742 - Comercio al por menor de equipos y aparatos de sonido y de video, en establecimientos especializados'},
        {value: '4751' ,label: '4751 - Comercio al por menor de productos textiles en establecimientos especializados'},
        {value: '4752' ,label: '4752 - Comercio al por menor de artículos de ferretería, pinturas y productos de vidrio en establecimientos especializados'},
        {value: '4753' ,label: '4753 - Comercio al por menor de tapices, alfombras y recubrimientos para paredes y pisos en establecimientos especializados'},
        {value: '4754' ,label: '4754 - Comercio al por menor de electrodomésticos y gasodomésticos, muebles y equipos de iluminación en establecimientos especializados'},
        {value: '4755' ,label: '4755 - Comercio al por menor de artículos y utensilios de uso doméstico en establecimientos especializados'},
        {value: '4759' ,label: '4759 - Comercio al por menor de otros artículos domésticos en establecimientos especializados'},
        {value: '4761' ,label: '4761 - Comercio al por menor de libros, periódicos, materiales y artículos de papelería y escritorio, en establecimientos especializados'},
        {value: '4762' ,label: '4762 - Comercio al por menor de artículos deportivos, en establecimientos especializados'},
        {value: '4769' ,label: '4769 - Comercio al por menor de otros artículos culturales y de entretenimiento n.c.p. en establecimientos especializados'},
        {value: '4771' ,label: '4771 - Comercio al por menor de prendas de vestir y sus accesorios (incluye artículos de piel) en establecimientos especializados'},
        {value: '4772' ,label: '4772 - Comercio al por menor de todo tipo de calzado y artículos de cuero y sucedáneos del cuero en establecimientos especializados'},
        {value: '4773' ,label: '4773 - Comercio al por menor de productos farmacéuticos y medicinales, cosméticos y artículos de tocador en establecimientos especializados'},
        {value: '4774' ,label: '4774 - Comercio al por menor de otros productos nuevos en establecimientos especializados'},
        {value: '4775' ,label: '4775 - Comercio al por menor de artículos de segunda mano'},
        {value: '4781' ,label: '4781 - Comercio al por menor de alimentos, bebidas y tabaco, en puestos de venta móviles'},
        {value: '4782' ,label: '4782 - Comercio al por menor de productos textiles, prendas de vestir y calzado, en puestos de venta móviles'},
        {value: '4789' ,label: '4789 - Comercio al por menor de otros productos en puestos de venta móviles'},
        {value: '4791' ,label: '4791 - Comercio al por menor realizado a través de internet'},
        {value: '4792' ,label: '4792 - Comercio al por menor realizado a través de casas de venta o por correo'},
        {value: '4799' ,label: '4799 - Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados'},
        {value: '4911' ,label: '4911 - Transporte férreo de pasajeros'},
        {value: '4912' ,label: '4912 - Transporte férreo de carga'},
        {value: '4921' ,label: '4921 - Transporte de pasajeros'},
        {value: '4922' ,label: '4922 - Transporte mixto'},
        {value: '4923' ,label: '4923 - Transporte de carga por carretera'},
        {value: '4930' ,label: '4930 - Transporte por tuberías'},
        {value: '5011' ,label: '5011 - Transporte de pasajeros marítimo y de cabotaje'},
        {value: '5012' ,label: '5012 - Transporte de carga marítimo y de cabotaje'},
        {value: '5021' ,label: '5021 - Transporte fluvial de pasajeros'},
        {value: '5022' ,label: '5022 - Transporte fluvial de carga'},
        {value: '5111' ,label: '5111 - Transporte aéreo nacional de pasajeros'},
        {value: '5112' ,label: '5112 - Transporte aéreo internacional de pasajeros'},
        {value: '5121' ,label: '5121 - Transporte aéreo nacional de carga'},
        {value: '5122' ,label: '5122 - Transporte aéreo internacional de carga'},
        {value: '5210' ,label: '5210 - Almacenamiento y depósito'},
        {value: '5221' ,label: '5221 - Actividades de estaciones, vías y servicios complementarios para el transporte terrestre'},
        {value: '5222' ,label: '5222 - Actividades de puertos y servicios complementarios para el transporte acuático'},
        {value: '5223' ,label: '5223 - Actividades de aeropuertos, servicios de navegación aérea y demás actividades conexas al transporte aéreo'},
        {value: '5224' ,label: '5224 - Manipulación de carga'},
        {value: '5229' ,label: '5229 - Otras actividades complementarias al transporte'},
        {value: '5310' ,label: '5310 - Actividades postales nacionales'},
        {value: '5320' ,label: '5320 - Actividades de mensajería'},
        {value: '5511' ,label: '5511 - Alojamiento en hoteles'},
        {value: '5512' ,label: '5512 - Alojamiento en apartahoteles'},
        {value: '5513' ,label: '5513 - Alojamiento en centros vacacionales'},
        {value: '5514' ,label: '5514 - Alojamiento rural'},
        {value: '5519' ,label: '5519 - Otros tipos de alojamiento para visitantes'},
        {value: '5520' ,label: '5520 - Actividades de zonas de camping y parques para vehículos recreacionales'},
        {value: '5530' ,label: '5530 - Servicio de estancia por horas'},
        {value: '5590' ,label: '5590 - Otros tipos de alojamiento n.c.p.'},
        {value: '5611' ,label: '5611 - Expendio a la mesa de comidas preparadas'},
        {value: '5612' ,label: '5612 - Expendio por autoservicio de comidas preparadas'},
        {value: '5613' ,label: '5613 - Expendio de comidas preparadas en cafeterías'},
        {value: '5619' ,label: '5619 - Otros tipos de expendio de comidas preparadas n.c.p.'},
        {value: '5621' ,label: '5621 - Catering para eventos'},
        {value: '5629' ,label: '5629 - Actividades de otros servicios de comidas'},
        {value: '5630' ,label: '5630 - Expendio de bebidas alcohólicas para el consumo dentro del establecimiento'},
        {value: '5811' ,label: '5811 - Edición de libros'},
        {value: '5812' ,label: '5812 - Edición de directorios y listas de correo'},
        {value: '5813' ,label: '5813 - Edición de periódicos, revistas y otras publicaciones periódicas'},
        {value: '5819' ,label: '5819 - Otros trabajos de edición'},
        {value: '5820' ,label: '5820 - Edición de programas de informática (software)'},
        {value: '5911' ,label: '5911 - Actividades de producción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión'},
        {value: '5912' ,label: '5912 - Actividades de postproducción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión'},
        {value: '5913' ,label: '5913 - Actividades de distribución de películas cinematográficas, videos, programas, anuncios y comerciales de televisión'},
        {value: '5914' ,label: '5914 - Actividades de exhibición de películas cinematográficas y videos'},
        {value: '5920' ,label: '5920 - Actividades de grabación de sonido y edición de música'},
        {value: '6010' ,label: '6010 - Actividades de programación y transmisión en el servicio de radiodifusión sonora'},
        {value: '6020' ,label: '6020 - Actividades de programación y transmisión de televisión'},
        {value: '6110' ,label: '6110 - Actividades de telecomunicaciones alámbricas'},
        {value: '6120' ,label: '6120 - Actividades de telecomunicaciones inalámbricas'},
        {value: '6130' ,label: '6130 - Actividades de telecomunicación satelital'},
        {value: '6190' ,label: '6190 - Otras actividades de telecomunicaciones'},
        {value: '6201' ,label: '6201 - Actividades de desarrollo de sistemas informáticos (planificación, análisis, diseño, programación, pruebas)'},
        {value: '6202' ,label: '6202 - Actividades de consultoría informática y actividades de administración de instalaciones informáticas'},
        {value: '6209' ,label: '6209 - Otras actividades de tecnologías de información y actividades de servicios informáticos'},
        {value: '6311' ,label: '6311 - Procesamiento de datos, alojamiento (hosting) y actividades relacionadas'},
        {value: '6312' ,label: '6312 - Portales web'},
        {value: '6391' ,label: '6391 - Actividades de agencias de noticias'},
        {value: '6399' ,label: '6399 - Otras actividades de servicios de información n.c.p.'},
        {value: '6411' ,label: '6411 - Banco Central'},
        {value: '6412' ,label: '6412 - Bancos comerciales'},
        {value: '6421' ,label: '6421 - Actividades de las corporaciones financieras'},
        {value: '6422' ,label: '6422 - Actividades de las compañías de financiamiento'},
        {value: '6423' ,label: '6423 - Banca de segundo piso'},
        {value: '6424' ,label: '6424 - Actividades de las cooperativas financieras'},
        {value: '6431' ,label: '6431 - Fideicomisos, fondos y entidades financieras similares'},
        {value: '6432' ,label: '6432 - Fondos de cesantías'},
        {value: '6491' ,label: '6491 - Leasing financiero (arrendamiento financiero)'},
        {value: '6492' ,label: '6492 - Actividades financieras de fondos de empleados y otras formas asociativas del sector solidario'},
        {value: '6493' ,label: '6493 - Actividades de compra de cartera o factoring'},
        {value: '6494' ,label: '6494 - Otras actividades de distribución de fondos'},
        {value: '6495' ,label: '6495 - Instituciones especiales oficiales'},
        {value: '6496' ,label: '6496 - Capitalización'},
        {value: '6499' ,label: '6499 - Otras actividades de servicio financiero, excepto las de seguros y pensiones n.c.p.'},
        {value: '6511' ,label: '6511 - Seguros generales'},
        {value: '6512' ,label: '6512 - Seguros de vida'},
        {value: '6513' ,label: '6513 - Reaseguros'},
        {value: '6515' ,label: '6515 - Seguros de salud'},
        {value: '6521' ,label: '6521 - Servicios de seguros sociales de salud'},
        {value: '6522' ,label: '6522 - Servicios de seguros sociales en riesgos laborales'},
        {value: '6523' ,label: '6523 - Servicios de seguros sociales en riesgos familia'},
        {value: '6531' ,label: '6531 - Régimen de prima media con prestación definida (RPM)'},
        {value: '6532' ,label: '6532 - Régimen de ahorro individual con solidaridad (RAIS)'},
        {value: '6611' ,label: '6611 - Administración de mercados financieros'},
        {value: '6612' ,label: '6612 - Corretaje de valores y de contratos de productos básicos'},
        {value: '6613' ,label: '6613 - Otras actividades relacionadas con el mercado de valores'},
        {value: '6614' ,label: '6614 - Actividades de las sociedades de intermediación cambiaria y de servicios financieros especiales'},
        {value: '6615' ,label: '6615 - Actividades de los profesionales de compra y venta de divisas'},
        {value: '6619' ,label: '6619 - Otras actividades auxiliares de las actividades de servicios financieros n.c.p.'},
        {value: '6621' ,label: '6621 - Actividades de agentes y corredores de seguros'},
        {value: '6629' ,label: '6629 - Evaluación de riesgos y daños, y otras actividades de servicios auxiliares'},
        {value: '6630' ,label: '6630 - Actividades de administración de fondos'},
        {value: '6810' ,label: '6810 - Actividades inmobiliarias realizadas con bienes propios o arrendados'},
        {value: '6820' ,label: '6820 - Actividades inmobiliarias realizadas a cambio de una retribución o por contrata'},
        {value: '6910' ,label: '6910 - Actividades jurídicas'},
        {value: '6920' ,label: '6920 - Actividades de contabilidad, teneduría de libros, auditoría financiera y asesoría tributaria'},
        {value: '7010' ,label: '7010 - Actividades de administración empresarial'},
        {value: '7020' ,label: '7020 - Actividades de consultoría de gestión'},
        {value: '7111' ,label: '7111 - Actividades de arquitectura'},
        {value: '7112' ,label: '7112 - Actividades de ingeniería y otras actividades conexas de consultoría técnica'},
        {value: '7120' ,label: '7120 - Ensayos y análisis técnicos'},
        {value: '7210' ,label: '7210 - Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería'},
        {value: '7220' ,label: '7220 - Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades'},
        {value: '7310' ,label: '7310 - Publicidad'},
        {value: '7320' ,label: '7320 - Estudios de mercado y realización de encuestas de opinión pública'},
        {value: '7410' ,label: '7410 - Actividades especializadas de diseño'},
        {value: '7420' ,label: '7420 - Actividades de fotografía'},
        {value: '7490' ,label: '7490 - Otras actividades profesionales, científicas y técnicas n.c.p.'},
        {value: '7500' ,label: '7500 - Actividades veterinarias'},
        {value: '7710' ,label: '7710 - Alquiler y arrendamiento de vehículos automotores'},
        {value: '7721' ,label: '7721 - Alquiler y arrendamiento de equipo recreativo y deportivo'},
        {value: '7722' ,label: '7722 - Alquiler de videos y discos'},
        {value: '7729' ,label: '7729 - Alquiler y arrendamiento de otros efectos personales y enseres domésticos n.c.p.'},
        {value: '7730' ,label: '7730 - Alquiler y arrendamiento de otros tipos de maquinaria, equipo y bienes tangibles n.c.p.'},
        {value: '7740' ,label: '7740 - Arrendamiento de propiedad intelectual y productos similares, excepto obras protegidas por derechos de autor'},
        {value: '7810' ,label: '7810 - Actividades de agencias de gestión y colocación de empleo'},
        {value: '7820' ,label: '7820 - Actividades de empresas de servicios temporales'},
        {value: '7830' ,label: '7830 - Otras actividades de provisión de talento humano'},
        {value: '7911' ,label: '7911 - Actividades de las agencias de viaje'},
        {value: '7912' ,label: '7912 - Actividades de operadores turísticos'},
        {value: '7990' ,label: '7990 - Otros servicios de reserva y actividades relacionadas'},
        {value: '8010' ,label: '8010 - Actividades de seguridad privada'},
        {value: '8020' ,label: '8020 - Actividades de servicios de sistemas de seguridad'},
        {value: '8030' ,label: '8030 - Actividades de detectives e investigadores privados'},
        {value: '8110' ,label: '8110 - Actividades combinadas de apoyo a instalaciones'},
        {value: '8121' ,label: '8121 - Limpieza general interior de edificios'},
        {value: '8129' ,label: '8129 - Otras actividades de limpieza de edificios e instalaciones industriales'},
        {value: '8130' ,label: '8130 - Actividades de paisajismo y servicios de mantenimiento conexos'},
        {value: '8211' ,label: '8211 - Actividades combinadas de servicios administrativos de oficina'},
        {value: '8219' ,label: '8219 - Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo a oficina'},
        {value: '8220' ,label: '8220 - Actividades de centros de llamadas (call center)'},
        {value: '8230' ,label: '8230 - Organización de convenciones y eventos comerciales'},
        {value: '8291' ,label: '8291 - Actividades de agencias de cobranza y oficinas de calificación crediticia'},
        {value: '8292' ,label: '8292 - Actividades de envase y empaque'},
        {value: '8299' ,label: '8299 - Otras actividades de servicio de apoyo a las empresas n.c.p.'},
        {value: '8411' ,label: '8411 - Actividades legislativas de la administración pública'},
        {value: '8412' ,label: '8412 - Actividades ejecutivas de la administración pública'},
        {value: '8413' ,label: '8413 - Regulación de las actividades de organismos que prestan servicios de salud, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social'},
        {value: '8414' ,label: '8414 - Actividades reguladoras y facilitadoras de la actividad económica'},
        {value: '8415' ,label: '8415 - Actividades de los órganos de control y otras instituciones'},
        {value: '8421' ,label: '8421 - Relaciones exteriores'},
        {value: '8422' ,label: '8422 - Actividades de defensa'},
        {value: '8423' ,label: '8423 - Orden público y actividades de seguridad'},
        {value: '8424' ,label: '8424 - Administración de justicia'},
        {value: '8430' ,label: '8430 - Actividades de planes de seguridad social de afiliación obligatoria'},
        {value: '8511' ,label: '8511 - Educación de la primera infancia'},
        {value: '8512' ,label: '8512 - Educación preescolar'},
        {value: '8513' ,label: '8513 - Educación básica primaria'},
        {value: '8521' ,label: '8521 - Educación básica secundaria'},
        {value: '8522' ,label: '8522 - Educación media académica'},
        {value: '8523' ,label: '8523 - Educación media técnica'},
        {value: '8530' ,label: '8530 - Establecimientos que combinan diferentes niveles de educación'},
        {value: '8541' ,label: '8541 - Educación técnica profesional'},
        {value: '8542' ,label: '8542 - Educación tecnológica'},
        {value: '8543' ,label: '8543 - Educación de instituciones universitarias o de escuelas tecnológicas'},
        {value: '8544' ,label: '8544 - Educación de universidades'},
        {value: '8551' ,label: '8551 - Formación para el trabajo'},
        {value: '8552' ,label: '8552 - Enseñanza deportiva y recreativa'},
        {value: '8553' ,label: '8553 - Enseñanza cultural'},
        {value: '8559' ,label: '8559 - Otros tipos de educación n.c.p.'},
        {value: '8560' ,label: '8560 - Actividades de apoyo a la educación'},
        {value: '8610' ,label: '8610 - Actividades de hospitales y clínicas, con internación'},
        {value: '8621' ,label: '8621 - Actividades de la práctica médica, sin internación'},
        {value: '8622' ,label: '8622 - Actividades de la práctica odontológica'},
        {value: '8691' ,label: '8691 - Actividades de apoyo diagnóstico'},
        {value: '8692' ,label: '8692 - Actividades de apoyo terapéutico'},
        {value: '8699' ,label: '8699 - Otras actividades de atención de la salud humana'},
        {value: '8710' ,label: '8710 - Actividades de atención residencial medicalizada de tipo general'},
        {value: '8720' ,label: '8720 - Actividades de atención residencial, para el cuidado de pacientes con retardo mental, enfermedad mental y consumo de sustancias psicoactivas'},
        {value: '8730' ,label: '8730 - Actividades de atención en instituciones para el cuidado de personas mayores y/o discapacitadas'},
        {value: '8790' ,label: '8790 - Otras actividades de atención en instituciones con alojamiento'},
        {value: '8810' ,label: '8810 - Actividades de asistencia social sin alojamiento para personas mayores y discapacitadas'},
        {value: '8891' ,label: '8891 - Actividades de guarderías para niños y niñas'},
        {value: '8899' ,label: '8899 - Otras actividades de asistencia social sin alojamiento n.c.p.'},
        {value: '9001' ,label: '9001 - Creación literaria'},
        {value: '9002' ,label: '9002 - Creación musical'},
        {value: '9003' ,label: '9003 - Creación teatral'},
        {value: '9004' ,label: '9004 - Creación audiovisual'},
        {value: '9005' ,label: '9005 - Artes plásticas y visuales'},
        {value: '9006' ,label: '9006 - Actividades teatrales'},
        {value: '9007' ,label: '9007 - Actividades de espectáculos musicales en vivo'},
        {value: '9008' ,label: '9008 - Otras actividades de espectáculos en vivo n.c.p.'},
        {value: '9101' ,label: '9101 - Actividades de bibliotecas y archivos'},
        {value: '9102' ,label: '9102 - Actividades y funcionamiento de museos, conservación de edificios y sitios históricos'},
        {value: '9103' ,label: '9103 - Actividades de jardines botánicos, zoológicos y reservas naturales'},
        {value: '9200' ,label: '9200 - Actividades de juegos de azar y apuestas'},
        {value: '9311' ,label: '9311 - Gestión de instalaciones deportivas'},
        {value: '9312' ,label: '9312 - Actividades de clubes deportivos'},
        {value: '9319' ,label: '9319 - Otras actividades deportivas'},
        {value: '9321' ,label: '9321 - Actividades de parques de atracciones y parques temáticos'},
        {value: '9329' ,label: '9329 - Otras actividades recreativas y de esparcimiento n.c.p.'},
        {value: '9411' ,label: '9411 - Actividades de asociaciones empresariales y de empleadores'},
        {value: '9412' ,label: '9412 - Actividades de asociaciones profesionales'},
        {value: '9420' ,label: '9420 - Actividades de sindicatos de empleados'},
        {value: '9491' ,label: '9491 - Actividades de asociaciones religiosas'},
        {value: '9492' ,label: '9492 - Actividades de asociaciones políticas'},
        {value: '9499' ,label: '9499 - Actividades de otras asociaciones n.c.p.'},
        {value: '9511' ,label: '9511 - Mantenimiento y reparación de computadores y de equipo periférico'},
        {value: '9512' ,label: '9512 - Mantenimiento y reparación de equipos de comunicación'},
        {value: '9521' ,label: '9521 - Mantenimiento y reparación de aparatos electrónicos de consumo'},
        {value: '9522' ,label: '9522 - Mantenimiento y reparación de aparatos y equipos domésticos y de jardinería'},
        {value: '9523' ,label: '9523 - Reparación de calzado y artículos de cuero'},
        {value: '9524' ,label: '9524 - Reparación de muebles y accesorios para el hogar'},
        {value: '9529' ,label: '9529 - Mantenimiento y reparación de otros efectos personales y enseres domésticos'},
        {value: '9601' ,label: '9601 - Lavado y limpieza, incluso la limpieza en seco, de productos textiles y de piel'},
        {value: '9602' ,label: '9602 - Peluquería y otros tratamientos de belleza'},
        {value: '9603' ,label: '9603 - Pompas fúnebres y actividades relacionadas'},
        {value: '9609' ,label: '9609 - Otras actividades de servicios personales n.c.p.'},
        {value: '9700' ,label: '9700 - Actividades de los hogares individuales como empleadores de personal doméstico'},
        {value: '9810' ,label: '9810 - Actividades no diferenciadas de los hogares individuales como productores de bienes para uso propio'},
        {value: '9820' ,label: '9820 - Actividades no diferenciadas de los hogares individuales como productores de servicios para uso propio'},
        {value: '9900' ,label: '9900 - Actividades de organizaciones y entidades extraterritoriales'}
];
export const vinculoCompañia = [
        {value: 'Colaborador' , label :'Colaborador'},
        {value: 'Proveedor' , label :'Proveedor'},
        {value: 'Cliente' , label :'Cliente'},
]
export const categoriaDenuncia =[
        {value: 'Comportamientos poco éticos' , label :'Comportamientos poco éticos'},
        {value: 'Conflicto de interés' , label :'Conflicto de interés'},
        {value: 'Soborno, corrupción' , label :'Soborno, corrupción'},
        {value: 'Robo, fraude y/o actos ilícitos' , label :'Robo, fraude y/o actos ilícitos'},
        {value: 'Impacto ambiental negativo' , label :'Impacto ambiental negativo'},
        {value: 'Acoso o discriminación laboral' , label :'Acoso o discriminación laboral'},
        {value: 'Abuso de poder o explotacion laboral' , label :'Abuso de poder o explotacion laboral'},
        {value: 'Otra' , label :'Otra'}
]