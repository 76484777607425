import axios from "axios";

import { URL_AWS } from "../constans/servidor";

export const getCategorys = async () => {
  try {
    const result = await axios.get(`${URL_AWS}/ContactCategory/getCategorys`);
    if (result.data.data) {
      const object = result.data.data;
      const nameCategorys = object.map((convenio) => ({
        value: convenio.id,
        label: convenio.category,
      }));
      return nameCategorys;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Hubo un error al obtener las categorias", error.message)
    return [];
  }
}