import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { useForm, Controller, Feedback, set } from 'react-hook-form';
import Select from 'react-select';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterComponen from '../../components/footer';
import EditServicios from '../../components/modal/modalServicios';
import GoogleMaps from '../../components/mapa';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';

import contacto from '../../asses/img/contacto.png';
import contacto2x from '../../asses/img/contacto@2x.png';
import contacto3x from '../../asses/img/contacto@3x.png';
import { getCategorys } from '../../utils/getCategories';

const ContactoPage = () => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const [loading, setLoading] = useState(false);
  const [editBanner, setEditBanner] = useState(false);
  const [editServivios, setEditServicios] = useState(false);
  const [modalNoticia, setModalNoticia] = useState(false);
  const [editAction, setEditAction] = useState('');
  const [dataBaner, setDataBaner] = useState({
    id: null,
    url: null,
    nombre: '',
    nombre_original: '',
  });
  const [servicios, setServicios] = useState([]);
  const [alianzas, setAlianzas] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [parqueaderos, setParqueaderos] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [updateBanner, setUpdateBanner] = useState(false);
  const [updateServicio, setUpdateservicios] = useState(false);
  const [updateAlianzas, setUpdateAlianzas] = useState(false);
  const [updateNoticias, setUpdateNoticias] = useState(false);
  const [noticia, setNoticia] = useState();
  const [nombre, setNombre] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [mensaje, setMensaje] = useState();
  const [acepto, setAcepto] = useState(false);
  const [error, setError] = useState(false);
  const [key, setKey] = useState('home');
  const [datos, setDatos] = useState([]);
  const [indexD, setIndexD] = useState();
  const [indexI, setIndexI] = useState();
  const [categoriasC, setCategorias] = useState();
  const [categoriasS, setCategoriasS] = useState();
  const [windowWidth, setWindowWidth] = useState(0);
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    const fetchCategorys = async () => {
      try {
        const categorys = await getCategorys();
        if (categorys.length > 0) {
          setCategorias(categorys);
        } else {
          toast.error('No pudimos cargar las categorias...');
          setDatos([]);
          setCategorias([]);
        }
      } catch (error) {
        console.error('Error al obtener categorías:', error);
        toast.error('Algo salio mal cargando categorias...');
      }
    };

    fetchCategorys();
  }, []);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const enviarEmail = async (event) => {
    event.preventDefault();
    console.log(categoriasS);
    if (
      nombre &&
      telefono &&
      email &&
      mensaje &&
      acepto &&
      categoriasS != undefined
    ) {
      const body = {
        message: mensaje,
        name: nombre,
        phone: telefono,
        sendBy: email,
        category: categoriasS,
      };
      console.log(body);
      try {
        const resultado = await axios.post(
          `https://2uj5iipka9.execute-api.us-east-1.amazonaws.com/dev/ContactCategory/sendMail`,
          body
        );
        console.log(resultado);
        setNombre();
        setTelefono();
        setEmail();
        setMensaje();
        setAcepto(false);
        setCategoriasS();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        toast.success(
          'Contacto exitoso, pronto nos contactaremos con usted...'
        );
      } catch (error) {
        toast.error('Algo salio mal, intentalo mas tarde...');
      }
    } else {
      toast.error('Algo salio mal, intentalo mas tarde...');

      setError(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Contacto Parking` });
  }, []);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row className="justify-content-center mtop-40" id="contacto">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="texto-titulo">¿Necesitas algo?</h3>
          </Col>
        </Row>
        <Row className="pbottom-40">
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
            <p className="text-left">
              Mantengamos el contacto.
              <br />
              Escríbenos y te responderemos tan pronto como sea posible.
            </p>

            <img
              src={contacto}
              className="img-fluid mtop-30"
              srcSet={`${contacto2x} 2x, ${contacto3x} 3x`}
              alt="contactate con parkin"
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form onSubmit={enviarEmail}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Escribe tu nombre"
                  onChange={(text) => setNombre(text.target.value)}
                  rules={{
                    required: {
                      value: true,
                      message: 'El campo es obligatorio',
                    },
                  }}
                />
                {error && !nombre ? (
                  <Form.Text id="passwordHelpBlock" className="error">
                    El nombre es obligatorio
                  </Form.Text>
                ) : null}
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Telefono</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Escribe tu teléfono"
                  onChange={(text) => setTelefono(text.target.value)}
                  rules={{
                    required: {
                      value: true,
                      message: 'El campo es obligatorio',
                    },
                  }}
                />
                {error && !telefono ? (
                  <Form.Text id="passwordHelpBlock" className="error">
                    El teléfono es obligatorio
                  </Form.Text>
                ) : null}
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Escribe tu correo electrónico"
                  onChange={(text) => setEmail(text.target.value)}
                  rules={{
                    required: {
                      value: true,
                      message: 'El campo es obligatorio',
                    },
                  }}
                />
                {error && !email ? (
                  <Form.Text id="passwordHelpBlock" className="error">
                    El email es obligatorio
                  </Form.Text>
                ) : null}
              </Form.Group>
              {categoriasC ? (
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="texto-label">Categoria</Form.Label>
                  <Controller
                    name="categoria"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'El campo es obligatorio',
                      },
                    }}
                    // rules={{ required: true }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        onChange={(categoriasC) => {
                          setCategoriasS(categoriasC.value);
                          onChange(categoriasC);
                        }}
                        value={value}
                        // placeholder="Elige una opción"
                        options={categoriasC}
                      />
                    )}
                  />
                  {error && !categoriasS ? (
                    <Form.Text id="passwordHelpBlock" className="error">
                      El campo es obligatorio
                    </Form.Text>
                  ) : null}
                </Form.Group>
              ) : null}
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Tu mensaje</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Escribe tu mensaje"
                  onChange={(text) => setMensaje(text.target.value)}
                  rules={{
                    required: {
                      value: true,
                      message: 'El campo es obligatorio',
                    },
                  }}
                />
                {error && !mensaje ? (
                  <Form.Text id="passwordHelpBlock" className="error">
                    El mensaje es obligatorio
                  </Form.Text>
                ) : null}
              </Form.Group>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Acepta nuestras"
                  onChange={(evt) => setAcepto(evt.target.checked)}
                />
                <Link className="enlace-check" to="/terminosycondiciones">
                  politicas de tratamiento de datos
                </Link>
                {error && !acepto ? (
                  <Form.Text id="passwordHelpBlock" className="error">
                    Es necesario aceptar nuestras politicas de tratamientos de
                    datos.
                  </Form.Text>
                ) : null}
              </Form.Group>
              <Button variant="primary" size="sm" type="submit">
                Enviar mensaje
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      <FooterComponen />
    </>
  );
};

export default ContactoPage;
