import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import AlertaContext from '../../context/alertas/alertaContext';
import AuthContex from '../../context/autenticacion/authContex';
import iconUser from '../../asses/img/contacto.png';


const RegistroPage = (props) => {
    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;
    const authContext = useContext(AuthContex);
    const { mensaje, autenticado, registrarUsuario } = authContext;

    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmar, setConfirmar] = useState('');

    useEffect(() => {
        if (mensaje) {
            console.log(mensaje)
            mostrarAlerta(mensaje.msg, 'danger');
        }
    }, [mensaje, autenticado, props.history]);

    const onSubmit = e => {
        e.preventDefault();

        // validar que no hayan campos vacios
        if (nombre.trim() === '' || email.trim() === '' || password.trim() === '' || confirmar.trim() === '') {
            mostrarAlerta('Todos los campos son obligatorios', 'danger');
            return;
        }

        //paswor minimo 6 caracteres
        if (password.length < 6) {
            mostrarAlerta('La contraseña debe tener minimo 6 caracteres', 'danger');
            return;
        }

        // pasword y confirmar no son iguales
        if (password !== confirmar) {
            mostrarAlerta('Las contraseñas no son iguales', 'danger');
            return;
        }

        registrarUsuario({
            nombre,
            email,
            password
        });
    }

    return (
        <Container>
            {alerta ?
                <Alert variant={alerta.categoria} transition={true}>
                    {alerta.msg}
                </Alert>
                : null
            }
            <Row className="justify-content-center mtop-40">
                <Col className="justify-content-center mtop-40" xs={12} sm={6} md={6} lg={6} xl={6}>
                    <img src={iconUser}></img>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Jumbotron>
                        <div className=" justify-content-center form-usuario">
                            <h1 className=' texto-titulo  mbottom-20'>Registrar Usuario</h1>

                            <Form onSubmit={onSubmit}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nombre}
                                        placeholder="Nombre"
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Correo Electronico</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        placeholder="Correo Electronico"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={password}
                                        placeholder="Contraseña"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Confirmar Contraseña</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={confirmar}
                                        placeholder="Contraseña"
                                        onChange={(e) => setConfirmar(e.target.value)}
                                    />
                                </Form.Group>
                                <Row className="justify-content-end mtop-20">
                                    <Button variant="primary" type="submit">
                                        Registrar
                                    </Button>
                                </Row>
                            </Form>
                            <Row className="justify-content-end mtop-40">

                                <Link to="/perfil" className="btn btn-link"> Perfil </Link>
                            </Row>
                        </div>
                    </Jumbotron>
                </Col>
            </Row>
        </Container>

    );
}

export default RegistroPage;