import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { analytic } from '../../server/firebase';
import FooterComponen from '../../components/footer';
import { URL_SERVICIO } from '../../constans/servidor';

const MensualidadesPage = () => {
  const { phone, identificacio, tipo, email } = useParams();

  const [loading, setLoading] = useState(false);
  const [acepto, setAcepto] = useState(false);
  const [dataUser, setDataUser] = useState();

  const enviarEmail = async (event) => {
    event.preventDefault();
    const data = {
      identificacion_nit: identificacio,
      celular: phone,
      ip: dataUser?.IPv4,
      correo: email,
    };
    try {
      const resultado = await axios.post(
        `${URL_SERVICIO}/servicioSMS/rest/mensaje/registrarTerminosCondiciones`,
        data
      );
      if (resultado.data.estado) {
        toast.success(resultado.data.mensaje);
      } else {
        toast.error(resultado.data.mensaje);
      }
    } catch (error) {
      toast.error('Algo salio mal intenta nuevamente');
    }
  };

  useEffect(() => {
    setAcepto(false);
    analytic.logEvent('page_view', { page: `Landing Mensualidades` });
    userData();
  }, []);

  const userData = async () => {
    setLoading(true);
    const rest = await axios('https://ipapi.co/json/');
    setDataUser(rest.data);
    setLoading(false);
  };

  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row
          className="justify-content-center mtop-40 mbottom-40"
          id="contacto"
        >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="texto-titulo">
              Terminos y condiciones mensualidad{' '}
              {tipo.toString() === '1' ? 'persona natural' : 'persona juridica'}
            </h3>
            {tipo.toString() === '1' ? (
              <>
                <p>
                  <strong>CLIENTE:</strong> {identificacio}
                  <br />
                  <strong>PAGO:</strong> Anticipado <br />
                  <strong>DEVOLUCIONES:</strong> No hay devoluciones.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  Derecho para estacionar ilimitadamente dentro del horario
                  establecido para cada parqueadero. *Registro hasta 2 placas
                  por mensualidad (carro),.. * Para motos y bicicletas solo se
                  podrá registrar una placa o identificación. *Por mensualidad
                  solo se podrá estacionar una sola placa al mismo tiempo. * No
                  se acepta ningún tipo de mezcla de placas por tipo de
                  vehículo. *Por fuera de la configuración acordada de la
                  mensualidad, el usuario debe pagar a la tarifa publicada para
                  el público. * La mensualidad iniciará una vez se realice el
                  pago incluso si el vehículo ya ingreso al estacionamiento y se
                  acepten los T&C. * Una vez compra tendrá una vigencia de 30
                  días calendario. * La mensualidad podrá cancelarse
                  inmediatamente en caso que se compruebe mal uso por parte del
                  usuario. *El usuario acepta la política de tratamiento de
                  datos que podrá ser consultada a través de la página web de
                  Parking. *El usuario acepta que el servicio de parqueo se
                  prestara de acuerdo con las obligaciones que la Ley exija a
                  Parking en esta materia. * Se aceptan cambios de placas una
                  sola vez dentro del mes calendario. * No acumula Be Parking. *
                  No se puede congelar. *Si no se toma el total de los días de
                  la mensualidad no existen suspensiones, devoluciones ni
                  congelamientos. * La mensualidad no da derecho a un cupo
                  exclusivo dentro del parqueadero. *En caso de abandono de
                  vehículos o bienes no sujetos a registro, se llevará a cabo el
                  procedimiento establecido en el Decreto 1413 de 2018 y el
                  Estatuto del Consumidor, así como las normas que complementen,
                  modifiquen y/o sustituyan estas disposiciones. * Aplican las
                  políticas de responsabilidad que obliga la Ley para el
                  servicio de estacionamiento.
                </p>
              </>
            ) : (
              <>
                <p>
                  <strong>CLIENTE:</strong> {identificacio}
                  <br />
                  <strong>VIGENCIA:</strong> 30 dias calendario
                  <br />
                  <strong>PAGO:</strong> 30 días
                  <br />
                  <strong>DEVOLUCIONES:</strong> No hay devoluciones.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  * Derecho para estacionar ilimitadamente dentro del horario
                  establecido para cada parqueadero.  *Registro hasta 2 placas
                  por mensualidad. - puede combinarse carro y moto - * Por
                  mensualidad solo se podrá estacionar una sola placa al mismo
                  tiempo.* Este derecho aplicará para un solo parqueadero salvo
                  casos excepcionales que se complementen con acuerdo
                  comercial.* Aplican las políticas de responsabilidad que
                  obliga la Ley para el servicio de estacionamiento. *Para motos
                  y bicicletas solo se podrá registrar una placa o
                  identificación * Por fuera de la configuración acordada de la
                  mensualidad, el usuario debe pagar a la tarifa publicada para
                  el público. * La mensualidad iniciará una vez sea emitida la
                  orden de compra / factura y se suscriba el contrato o
                  convenio. * La mensualidad podrá suspenderse cancelarse
                  inmediatamente en caso que se compruebe mal uso por parte del
                  usuario.* el usuario acepta la política de tratamiento de
                  datos que podrá ser consultada a través de la aplicación móvil
                  o en la página web de Parking. *el usuario acepta que el
                  servicio de parqueo se prestará de acuerdo con las
                  obligaciones que la Ley exija a Parking en esta materia.*La
                  cantidad de mensualidades acordada solo podrá disminuirse
                  antes que inicie el mes calendario siguiente, obligándose el
                  operador a garantizar la disponibilidad y el cliente a pagar
                  por dichos cupos. * La mensualidad podrá renovarse
                  automáticamente *Se aceptan cambios de placas una sola vez
                  dentro del mes calendario. * En caso que la mensualidad se
                  acuerde con restricciones de horario, el usuario deberá pagar
                  el tiempo adicional por cualquiera de los medios habilitados.
                  * en el caso que el cliente corporativo decida pagar por el
                  tiempo adicional, este valor se facturará y deberá pagarse
                  dentro de los 30 días siguiente. * No acumula Be Parking* No
                  se puede congelar*Si no se toma el total de los días de la
                  mensualidad no existen, suspensiones, ni devoluciones ni
                  congelamientos.
                </p>
              </>
            )}
            <Form.Check
              type="checkbox"
              checked={acepto}
              onChange={(value) => setAcepto(!acepto)}
              label="Acepto términos y condiciones"
            />

            <Button
              variant="primary"
              disabled={!acepto}
              onClick={enviarEmail}
              className="mtop-20"
            >
              Aceptar
            </Button>
          </Col>
        </Row>
      </Container>

      <FooterComponen />
    </>
  );
};

export default MensualidadesPage;
