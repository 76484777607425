import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, fun, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import CabezeraComponent from '../../components/header/cabezera';
import EditServicios from '../../components/modal/modalServicios';
import GoogleMaps from '../../components/mapa';
import { URL_AWS } from '../../constans/servidor';

import puntos from '../../asses/img/PuntosExcluidos.png';
import puntos2x from '../../asses/img/PuntosExcluidos@2x.png';
import puntos3x from '../../asses/img/PuntosExcluidos@3x.png';

const PuntosbePage = () => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const [loading, setLoading] = useState(false);
  const [historial, setHistorial] = useState([]);
  const [editBanner, setEditBanner] = useState(false);
  const [editServivios, setEditServicios] = useState(false);
  const [modalNoticia, setModalNoticia] = useState(false);
  const [editAction, setEditAction] = useState('');
  const [dataBaner, setDataBaner] = useState({
    id: null,
    url: null,
    nombre: '',
    nombre_original: '',
  });
  const [servicios, setServicios] = useState([]);
  const [alianzas, setAlianzas] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [parqueaderos, setParqueaderos] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [updateBanner, setUpdateBanner] = useState(false);
  const [updateServicio, setUpdateservicios] = useState(false);
  const [updateAlianzas, setUpdateAlianzas] = useState(false);
  const [updateNoticias, setUpdateNoticias] = useState(false);
  const [noticia, setNoticia] = useState();
  const [nombre, setNombre] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [mensaje, setMensaje] = useState();
  const [acepto, setAcepto] = useState(false);
  const [error, setError] = useState(false);
  const [key, setKey] = useState('home');
  const [windowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const cargarHistorial = async () => {
    setLoading(true);
    try {
      const result = await axios.post(
        `${URL_AWS}/serviciosBeparking/rest/beparking/getServicePoints`
      );
      console.log(result);
      setHistorial(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    analytic.logEvent('page_view', { page: 'Puntos excluidos' });
    cargarHistorial();
  }, []);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row
          className="justify-content-center mtop-50 mbottom-50"
          id="contacto"
        >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="texto-titulo">Puntos de servicio be parking</h3>
          </Col>
        </Row>

        <Row
          className="justify-content-center mtop-50 mbottom-50"
          id="contacto"
        >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Table bordered striped className="tabla">
              <thead>
                <tr>
                  <th>Puntos de servicio be parking</th>
                  <th>Dirección</th>
                  <th>Ciudad</th>
                </tr>
              </thead>
              <tbody>
                {historial?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item.nombre_comercial}</td>
                      <td>{item.direccion}</td>
                      <td>{item.Ciudad}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>

      <FooterBeparking />
    </>
  );
};

export default PuntosbePage;
