import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import CabezeraComponent from '../../components/header/cabezera';
import EditServicios from '../../components/modal/modalServicios';
import GoogleMaps from '../../components/mapa';

import contacto from '../../asses/img/contacto.png';
import contacto2x from '../../asses/img/contacto@2x.png';
import contacto3x from '../../asses/img/contacto@3x.png';
import contactoAzul from '../../asses/img/contacto-azul.png';
import contactoAzul2x from '../../asses/img/contacto-azul@2x.png';
import contactoAzul3x from '../../asses/img/contacto-azul@3x.png';

const TratamientobePage = () => {
    const authContex = useContext(AuthContex);
    const { usuario } = authContex;
    const [loading, setLoading] = useState(false);
    const [editBanner, setEditBanner] = useState(false);
    const [editServivios, setEditServicios] = useState(false);
    const [modalNoticia, setModalNoticia] = useState(false);
    const [editAction, setEditAction] = useState('');
    const [dataBaner, setDataBaner] = useState({id: null, url: null, nombre: '', nombre_original: ''});
    const [servicios, setServicios] = useState([]);
    const [alianzas, setAlianzas] = useState([]);
    const [noticias, setNoticias] = useState([]);
    const [parqueaderos, setParqueaderos] = useState([]);
    const [zonas, setZonas] = useState([]);
    const [updateBanner, setUpdateBanner] = useState(false);
    const [updateServicio, setUpdateservicios] = useState(false);
    const [updateAlianzas, setUpdateAlianzas] = useState(false);
    const [updateNoticias, setUpdateNoticias] = useState(false);
    const [noticia, setNoticia] = useState();
    const [nombre, setNombre] = useState();
    const [telefono, setTelefono] = useState();
    const [email, setEmail] = useState();
    const [mensaje, setMensaje] = useState();
    const [acepto, setAcepto] = useState(false);
    const [error, setError] = useState(false);
    const [key, setKey] = useState('home');
    const [windowWidth, setWindowWidth] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };


    const enviarEmail = async (event) => {
        event.preventDefault();
        if(nombre && telefono && email && mensaje && acepto){
            try {
                const resultado = await axios.get(`https://us-central1-parkingpaginawebproduccion.cloudfunctions.net/sendMail?nombre=${nombre}&telefono=${telefono}&correo=${email}&mensaje=${mensaje}`,);
                setNombre();
                setTelefono();
                setEmail();
                setMensaje();
                setAcepto(false);
                toast.success("Contacto exitoso, pronto nos contactaremos con usted...")
            } catch (error) {
                toast.error("Algo salio mal contactactando a parking...")
            }
        } else {
            setError(true)
        }  
    }
    useEffect(() => {
        analytic.logEvent('page_view', {page: `Politicas de tratamiento de datos Beparking`});
    }, []);


    return ( 
        loading ? 
            <div className="cargando">
                <div class="loader" id="loader">Loading...</div>
            </div>
        :
        <>
            
            <Container>
                    
                <Row className="justify-content-center mtop-50 mbottom-50" id="contacto">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className="texto-titulo">Política de Tratamiento de Datos</h3>
                    </Col>
                </Row>
                <Row className="justify-content-center mtop-50 mbottom-50" id="contacto">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h4>POLÍTICA PARA EL TRATAMIENTO DE LA INFORMACIÓN PARKING INTERNATIONAL S.A.S</h4>
                        <p>Las políticas contenidas en el presente documento se elaboraron teniendo en cuenta las disposiciones contenidas en los artículos 15 y 20 de la Constitución Política, la Ley 1581 de 2012 “Por la cual se dictan disposiciones generales para la protección de datos personales” y el Decreto 1377 de 2013 “por el cual se reglamenta parcialmente la ley 1581 de 2012.”<br/><br/>Las políticas que se describen a continuación se aplican al tratamiento de los datos de carácter personal que recoja y controle PARKING INTERNATIONAL S.A.S., en adelante genéricamente PARKING INTERNATIONAL con domicilio en la Carrera 14 No. 89 – 48 of 202 de Bogotá, correo electrónico servicioalcliente@parking.net.co y teléfono 312 450 7484<br/><br/>Las políticas y procedimientos contenidos en el presente documento aplican a las bases de datos contentivas de información de: afiliados al programa “Be Parking”, datos recolectados en actividades de mercadeo, a través de medios electrónicos, físicos o redes sociales. El período de vigencia de tales bases de datos va hasta el 31 de diciembre de 2050.</p>
                        <p><strong>1. TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS PERSONALES RECOGIDOS POR PARKING INTERNATIONAL</strong><br/>Los datos personales respecto de los cuales PARKING INTERNATIONAL ostenta el carácter de responsable son incluidos en bases de datos y serán utilizados para las siguientes finalidades:</p>
                        <ul>
                            <li>Envío de información sobre ofertas y servicios relacionados con el programa de lealtad “Be Parking”.</li>
                            <li>Envío de información sobre ofertas y beneficios de otros programas de lealtad que ofrezca en el futuro PARKING INTERNATIONAL.</li>
                            <li>Permitir la participación de los titulares de información en actividades de mercadeo y promocionales;</li>
                            <li>Dar cumplimiento a obligaciones contraídas con nuestros clientes al momento de afiliarse al programa “Be Parking” o a otros programas de lealtad;</li>
                            <li>Permitir que terceros aliados de PARKING INTERNATIONAL contacten al titular con el propósito de ofrecerle bienes o servicios de su interés. En todo caso la lista de Aliados se mantendrá actualizada en la página web www.parking.net.co</li>
                            <li>Informar sobre cambios en los servicios y características de los programas de fidelidad;</li>
                            <li>Evaluar la calidad del servicio;</li>
                            <li>Realizar estudios de mercado sobre hábitos de consumo;</li>
                            <li>Administrar y operar los procesos de selección de personal y recursos humanos dentro de la compañía.</li>
                        </ul>
                        <p><strong>2. DERECHOS QUE LE ASISTEN COMO TITULAR DE LA INFORMACIÓN CONTENIDA EN NUESTRAS BASES DE DATOS</strong><br/>De conformidad con lo establecido en el artículo 8 de la Ley 1581 de 2012 el titular de los datos personales tiene los siguientes derechos:</p>
                        <ul>
                            <li>Conocer, actualizar y rectificar sus datos personales frente a PARKING INTERNATIONAL en su condición de responsable del tratamiento.</li>
                            <li>Solicitar prueba de la autorización otorgada a PARKING INTERNATIONAL en su condición de responsable del Tratamiento.</li>
                            <li>Ser informado por PARKING INTERNATIONAL previa solicitud, respecto del uso que le ha dado a sus datos personales.</li>
                            <li>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley 1581 de 2012, una vez haya agotado el trámite de consulta o reclamo ante el Responsable del Tratamiento.</li>
                            <li>Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales.</li>
                            <li>Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</li>
                        </ul>
                        <p><strong>3. PROCEDIMIENTOS DE ACCESO, CONSULTA, RECTIFICACIÓN Y ACTUALIZACIÓN DE LA INFORMACIÓN</strong><br/>Los titulares de la información tienen derecho a acceder a sus datos personales y a los detalles del tratamiento de los mismos, así como a rectificarlos y actualizarlos en caso de ser inexactos o a solicitar su eliminación cuando considere que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al tratamiento de los mismos para fines específicos. Las vías que se han implementado para garantizar el ejercicio de dichos derechos a través de la presentación de la solicitud respectiva son:</p>
                        <ul>
                            <li>Oficinas principales ubicadas en la Carrera 14 No. 89-48 of 202</li>
                            <li>La dirección electrónica servicioalcliente@parking.net.co</li>
                        </ul>
                        <p>I. DERECHO DE ACCESO: El titular de la información podrá consultar de forma gratuita sus datos personales al menos una vez cada mes calendario. Para conocer los datos personales que son objeto de tratamiento por parte de PARKING INTERNATIONAL, el titular de la información podrá elevar una solicitud indicando la información que desea conocer a través de cualquiera de los mecanismos arriba señalados.<br/><br/>La petición será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. Cuando no fuere posible atender la petición dentro de dicho término, este hecho se informará al solicitante, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.<br/><br/>II. RECLAMOS. De conformidad con lo establecido en el artículo 14 de la Ley 1581 de 2012, cuando el Titular o sus causahabientes consideren que la información contenida en una base de datos debe ser objeto de corrección, actualización o supresión, o cuando deba ser revocada por advertirse el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley 1581 de 2012, podrán presentar un reclamo ante PARKING INTERNATIONAL , el cual será tramitado bajo las siguientes reglas:<br/><br/>Los derechos de rectificación, actualización o supresión o revocatoria del consentimiento únicamente se podrán ejercer por:</p>
                        <ul>
                            <li>El titular o sus causahabientes, previa acreditación de su identidad</li>
                            <li>Su representante, previa acreditación de la representación,</li>
                            <li>Por estipulación a favor de otro o para otro.</li>
                        </ul>
                        <p>Cuando la solicitud sea formulada por persona distinta del titular y no se acredite que la misma actúa en representación de aquél, se tendrá por no presentada. La solicitud de rectificación, actualización, supresión o revocatoria debe ser presentada a través de los medios habilitados por PARKING INTERNATIONAL indicados en los términos y condiciones (domicilio principal, servicioalcliente@parking.net.co) y contener, como mínimo, la siguiente información:</p>
                        <ol>
                            <li>El nombre y domicilio del titular o cualquier otro medio para recibir la respuesta.</li>
                            <li>Los documentos que acrediten la identidad del solicitante y en caso dado, la de su representante con la respectiva autorización.</li>
                            <li>La descripción clara y precisa de los datos personales respecto de los cuales el titular busca ejercer alguno de los derechos y la solicitud concreta.</li>
                            <li>En caso dado, otros elementos o documentos que faciliten la localización de los datos personales.</li>
                        </ol>
                        <p>El término máximo para atender esta solicitud será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atenderla dentro de dicho término, se informará al interesado sobre los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</p>
                        <p><strong>4. ÁREA RESPONSABLE DE LA ATENCIÓN DE LA PETICIONES, CONSULTAS, RECLAMOS</strong><br/>En PARKING INTERNATIONAL el área responsable de atender las peticiones de acceso, rectificación, actualización, supresión de datos o revocatoria del consentimiento o autorización otorgada para el tratamiento de sus datos personales, es el área de atención al cliente, ubicada en la Carrera 14 No. 89-48 of 202, correo electrónico servicioalcliente@parking.net.co o en la sección de “soporte” en el dominio www.parking.net.co/beparking.<br/><br/>La política de tratamiento de la información contenida en el presente documento entra en vigencia el 1 de marzo de 2014.</p>
                    </Col>
                </Row>
            </Container>


            <FooterBeparking />

        </>
     );
}
 
export default TratamientobePage;

