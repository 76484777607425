import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import * as ROUTES from "../../constans/rutas";
import { toast } from 'react-toastify';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';
import AuthContex from '../../context/autenticacion/authContex';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
 
const LoginRetention = ({ open, onOpen }) => {
  const authContext = useContext(AuthContex);
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const { loginSuppliers } = authContext;
  const [showPass, setShowPass] = useState(false);
  const [nit, setnit] = useState(''); //800675511
  const [password, setPassword] = useState('');
  const [loginLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
 
  const onSubmit = (e) => {
    e.preventDefault();
 
    if (!nit || !password || nit.trim() === '' || password.trim() === '') {
      toast.error('Todos los campos son obligatorios');
      return;
    }
 
    if (password.length < 6) {
      toast.error('La contraseña debe tener minimo 6 caracteres');
      return;
    }
    const typeUser = "Normal"
   
    loginSuppliers({ nit, password, typeUser });
  };
 
  useEffect(() => { }, []);
 
 
 
  useEffect(() => {
    setShowPass(false)
    // history.push(ROUTES.BEPARKING);
    setnit(usuario?.NIT);
  }, [usuario]);
 
 
  return (
    <>
      <Modal
        size="md"
        show={open}
        onHide={() => onOpen(false)}
        className="be-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Iniciar sesión</Modal.Title>
        </Modal.Header>
 
        <Modal.Body>
          <Form className="conten-login" onSubmit={onSubmit}>
            <Form.Group controlId="formBasicEmail" className='control-numero'>
              <Form.Label>NIT</Form.Label>
              <Form.Control
                type="text"
                value={nit}
                placeholder="Ingresa el NIT registrado"
                onChange={(e) => setnit(e.target.value)}
              />
            </Form.Group>
 
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Contraseña</Form.Label>
              <div style={{ position: 'relative' }}>
              <Form.Control
               type={showPassword ? 'text' : 'password'}
                value={password}
                placeholder="Ingresa tu contraseña"
                onChange={(e) => setPassword(e.target.value)}
               
              />                          
               <div
                style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
              onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
              </div>
            </Form.Group>
           
            <div className="text-center">
              <Button className="btn-borde-verde" size="sm" type="submit">
                {loginLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Iniciar sesion'
                )}
              </Button>
            </div>
           
           
            <div className="text-center">
              <Button
                onClick={() => onOpen(false)}
                variant="link"
                className="btn-enlace-gris"
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
 
export default LoginRetention;