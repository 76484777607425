import React, { useState, useContext, useEffect } from 'react';
import { Link, Router, useHistory } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import NavDropdown from "react-bootstrap/NavDropdown";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import { BsArrowLeft, BsPlusCircle } from "react-icons/bs";
import { BiKey } from "react-icons/bi";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as ROUTES from "../../constans/rutas";
import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditServicios from '../../components/modal/modalServicios';
import CabezeraComponent from '../../components/header/cabezera';
import {URL_SERVICIO, URL_AWS} from '../../constans/servidor';

import iconUser from '../../asses/img/icon-user.png';
import iconUser2x from '../../asses/img/icon-user@2x.png';
import iconUser3x from '../../asses/img/icon-user@3x.png';
import iconUserAzul from '../../asses/img/icon-user-azul.png';
import iconUserAzul2x from '../../asses/img/icon-user-azul@2x.png';
import iconUserAzul3x from '../../asses/img/icon-user-azul@3x.png';

const BeInformacionPage = () => {
    const authContex = useContext(AuthContex);
    const { usuario, actualizarUsuario } = authContex;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [editBanner, setEditBanner] = useState(false);
    const [editServivios, setEditServicios] = useState(false);
    const [modalNoticia, setModalNoticia] = useState(false);
    const [editAction, setEditAction] = useState('');
    const [dataBaner, setDataBaner] = useState({id: null, url: null, nombre: '', nombre_original: ''});
    const [servicios, setServicios] = useState([]);
    const [alianzas, setAlianzas] = useState([]);
    const [noticias, setNoticias] = useState([]);
    const [parqueaderos, setParqueaderos] = useState([]);
    const [zonas, setZonas] = useState([]);
    const [updateBanner, setUpdateBanner] = useState(false);
    const [updateServicio, setUpdateservicios] = useState(false);
    const [updateAlianzas, setUpdateAlianzas] = useState(false);
    const [updateNoticias, setUpdateNoticias] = useState(false);
    const [noticia, setNoticia] = useState();
    const [nombre, setNombre] = useState();
    const [telefono, setTelefono] = useState();
    const [email, setEmail] = useState();
    const [mensaje, setMensaje] = useState();
    const [acepto, setAcepto] = useState(false);
    const [error, setError] = useState(false);
    const [key, setKey] = useState('home');
    const [windowWidth, setWindowWidth] = useState(0);
    const { control, watch, handleSubmit, reset, formState: { errors } } = useForm();

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };


    const onSubmit = async (data) => {
        try {
            const f = data.fechaNacimiento;
            const mes = ['01','02','03','04','05','06','07','08','09','10','11','12']
            const dia = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
            const fecha = `${f.getFullYear()}-${mes[f.getMonth()]}-${dia[f.getDate()]} 00:00:00`
            const datos = {
                nombre: data.nombre,
                apellido: data.apellido,
                email: data.email,
                celular: data.celular,
                direccion: data.direccion,
                cedula: usuario.cedula,
                fechaNacimiento: fecha
            }


            await axios.post(`${URL_AWS}/beParking/editarClienteWeb`, datos);

            actualizarUsuario(usuario.cedula);

            toast.success("Se actualizo el usuario correctamente...");

        } catch (error) {
            console.log(error)
            toast.error(error.response.data.mensaje);
        }
    }

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    useEffect(() => {
        if(usuario) setLoading(false)
    }, [usuario]);
    
    useEffect(() => {
        analytic.logEvent('page_view', {page: `Tus datos Beparking`});
    }, []);
    //console.log('Valor de usuario.genero:', usuario.genero);
    return ( 
        loading ? 
            <div className="cargando">
                <div class="loader" id="loader">Loading...</div>
            </div>
        :
        <>
            

            <Container>

                <CabezeraComponent/>

                <div className={usuario?.extracto?.categoria === "GREEN" ? "be-info mtop-20" : "be-info mtop-20 azul"}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button onClick={() => { history.goBack(); }} className={usuario?.extracto?.categoria === "GREEN" ? "btn-primary" : "btn-primary azul"}><BsArrowLeft/> Regresa a tus datos</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                            <h3 className="texto-titulo mtop-20">Tus datos.</h3>
                        </Col>
                        {/** <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                            <div className="contenedor-agregar-limite mtop-20 float-right">
                                <Button variant="outline-success" onClick={ () => { history.push(ROUTES.BECONTRASENA) }}><BiKey/> Cambiar contraseña</Button>
                            </div>
                        </Col>*/}
                    </Row>
                    <Row>
                        
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="caja-perfil">
                                    <div className="caja-perfil-header">
                                        <p>Tu información</p>
                                        <div className="icon-content">
                                        { usuario?.extracto?.categoria === "GREEN" ?
                                        <img src={iconUser} className="icon-icon" srcSet={`${iconUser2x} 2x, ${iconUser3x} 3x`} alt="Informacion del usuario" />
                                        :
                                        <img src={iconUserAzul} className="icon-icon" srcSet={`${iconUserAzul2x} 2x, ${iconUserAzul3x} 3x`} alt="Informacion del usuario" />
                                        }
                                    </div>
                                </div>
                                <div className="caja-perfil-body">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col xs={12} sm={6} md={5} lg={5} xl={5}>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Nombres</Form.Label>
                                                    <Controller
                                                        name="nombre"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        defaultValue={usuario?.nombre}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <div className="control-icon">
                                                                <Form.Control 
                                                                    type="text" 
                                                                    onBlur={onBlur}
                                                                    onChange={value => onChange(value)}
                                                                    value={value}
                                                                    placeholder="Nombres"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                    {errors.nombre && <Form.Text id="passwordHelpBlock" className="error">El nombre es obligatorio</Form.Text>}
                                                </Form.Group>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Apellidos</Form.Label>
                                                    <Controller
                                                        name="apellido"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        defaultValue={usuario?.apellido}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <div className="control-icon">
                                                                <Form.Control 
                                                                    type="text" 
                                                                    onBlur={onBlur}
                                                                    onChange={value => onChange(value)}
                                                                    value={value}
                                                                    placeholder="Apellidos"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                    {errors.apellidos && <Form.Text id="passwordHelpBlock" className="error">El nombre es obligatorio</Form.Text>}
                                                </Form.Group>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Género</Form.Label>
                                                    <Controller
                                                        name="genero"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        defaultValue={usuario?.genero}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <div className="control-icon">
                                                                <Form.Control 
                                                                    as="select"
                                                                    onChange={value => onChange(value)}
                                                                    value={value}
                                                                    placeholder="Elige una opción"
                                                                >
                                                                    <option>Elige una opción</option>
                                                                    <option value="M">Másculino</option>
                                                                    <option value="F">Femenino</option>
                                                                </Form.Control>
                                                            </div>
                                                        )}
                                                    />
                                                    {errors.genero && <Form.Text id="passwordHelpBlock" className="error">El tipo de documento es obligatorio</Form.Text>}
                                                </Form.Group>
                                                {/* <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Tipo de identificación</Form.Label>
                                                    <Controller
                                                        name="documento"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        defaultValue={usuario?.documento}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <div className="control-icon">
                                                                <Form.Control 
                                                                    as="select"
                                                                    onChange={value => onChange(value)}
                                                                    value={value}
                                                                    placeholder="Elige una opción"
                                                                    disabled={true}
                                                                >
                                                                    <option>Elige una opción</option>
                                                                    <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                                                                    <option value="Cédula de extranjería">Cédula de extranjería</option>
                                                                    <option value="Pasaporte">Pasaporte</option>
                                                                </Form.Control>
                                                            </div>
                                                        )}
                                                    />
                                                    {errors.documento && <Form.Text id="passwordHelpBlock" className="error">El tipo de documento es obligatorio</Form.Text>}
                                                </Form.Group> */}

                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Número de identificación</Form.Label>
                                                        <div className="control-icon">
                                                            <Form.Control 
                                                                type="number"
                                                                value={usuario?.cedula}
                                                                disabled={true}
                                                                placeholder="Ingresa el número de tu identificación"
                                                            />
                                                        </div>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={6} md={5} lg={5} xl={5}>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Correo electrónico</Form.Label>
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        defaultValue={usuario?.email}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <div className="control-icon">    
                                                                <Form.Control 
                                                                    type="email" 
                                                                    onBlur={onBlur}
                                                                    onChange={value => onChange(value)}
                                                                    value={value}
                                                                    placeholder="Ingresa tu correo electrónico"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                    {errors.email && <Form.Text id="passwordHelpBlock" className="error">El correo electrónico es obligatorio</Form.Text>}
                                                </Form.Group>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Dirección</Form.Label>
                                                    <Controller
                                                        name="direccion"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        defaultValue={usuario?.direccion}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <div className="control-icon">    
                                                                <Form.Control 
                                                                    type="text" 
                                                                    onBlur={onBlur}
                                                                    onChange={value => onChange(value)}
                                                                    value={value}
                                                                    placeholder="Ingresa tu dirección"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                    {errors.direccion && <Form.Text id="passwordHelpBlock" className="error">La dirección es obligatorio</Form.Text>}
                                                </Form.Group>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Número de celular</Form.Label>
                                                    <Controller
                                                        name="celular"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        defaultValue={usuario?.celular}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <div className="control-icon"> 
                                                                <Form.Control 
                                                                    type="number" 
                                                                    onBlur={onBlur}
                                                                    onChange={value => onChange(value)}
                                                                    value={value}
                                                                    placeholder="Ingresa el número de tu celular"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                    {errors.celular && <Form.Text id="passwordHelpBlock" className="error">El número de celular es obligatorio</Form.Text>}
                                                </Form.Group>
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Fecha Nacimiento</Form.Label>
                                                    <Controller
                                                        name="fechaNacimiento"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        defaultValue={new Date(usuario?.fechaNacimiento)}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <div className="control-icon"> 
                                                                <DatePicker 
                                                                    className="form-control"
                                                                    selected={value} 
                                                                    dateFormat="yyyy-MM-dd"
                                                                    onChange={(date) => onChange(date)} 
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                    {errors.fechaNacimiento && <Form.Text id="passwordHelpBlock" className="error">La fecha de nacimiento es obligatorio</Form.Text>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={10} md={10} lg={10} xl={10}>
                                                <div className="contenedor-agregar-limite mtop-40">
                                                    <Button variant="outline-success" onClick={handleSubmit(onSubmit)}><BsPlusCircle/> Actualizar</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

            <FooterBeparking />

        </>
     );
}
 
export default BeInformacionPage;

