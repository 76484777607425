import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';

import BannerParkingMedia from '../../asses/img/Banner Parking Media.png';
import icono1 from '../../asses/img/Icono 01.png';
import icono2 from '../../asses/img/Icono 02.png';
import icono3 from '../../asses/img/Icono 03.png';
import BannerFooter from '../../asses/img/Banner Parking al Volante-02.png';

import FooterComponen from '../../components/footer';

export default function ParkingMedia() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row className="row-media">
          <img
            style={{ width: '100%' }}
            src={BannerParkingMedia}
            alt="Banner Parking Media"
          />
          <h2 style={{ color: '#90d400', fontWeight: 'bold' }}>
            ¿Qué es Parking Media?
          </h2>
          <p style={{ fontSize: '19px', fontWeight: 700, color: '#929292' }}>
            Es la unidad de alianzas y publicidad de Parking International, la
            cual cocrea con marcas anunciantes, agencias BTL, agencias de medios
            y productoras, diferentes estrategias de visibilidad y contenido
            ¡Dando un uso creativo a los parqueaderos!
          </p>
          <p style={{ fontSize: '19px', fontWeight: 700, color: '#929292' }}>
            Más de{' '}
            <strong
              style={{ fontSize: '19px', fontWeight: 'bold', color: '#929292' }}
            >
              125 mil personas
            </strong>{' '}
            en ecosistema digital y más de{' '}
            <strong
              style={{ fontSize: '19px', fontWeight: 'bold', color: '#929292' }}
            >
              3.4 millones de personas al mes
            </strong>{' '}
            en puntos de servicio
          </p>
          <div className="container-parking-media">
            <div className="content-media">
              <img src={icono1} />
              <strong
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                  color: '#90d400',
                }}
              >
                Interacciones digitales
              </strong>
            </div>
            <div className="content-media">
              <img src={icono2} />
              <strong
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                  color: '#90d400',
                }}
              >
                Interacciones digitales
              </strong>
            </div>
            <div className="content-media">
              <img src={icono3} />
              <strong
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                  color: '#90d400',
                }}
              >
                Interacciones digitales
              </strong>
            </div>
          </div>
          <div className="container-parking-media-2">
            <p
              style={{
                fontSize: '19px',
                fontWeight: 'bold',
                color: '#929292',
                textAlign: 'center',
              }}
            >
              Sabemos{' '}
              <strong
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                  color: '#90d400',
                }}
              >
                cómo, cuando y donde
              </strong>{' '}
              se mueve tu audiencia{' '}
              <strong
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                  color: '#929292',
                }}
              >
                ¡Segmenta como necesites!
              </strong>
            </p>
            <img style={{ width: '100%' }} src={BannerFooter} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              paddingTop: '2rem',
            }}
          >
            <p
              style={{
                fontSize: '19px',
                color: '#90d400',
              }}
            >
              Conecta{' '}
              <strong
                style={{
                  fontWeight: 'bold',
                  fontSize: '19px',
                  color: '#90d400',
                }}
              >
                generando emociones
              </strong>
            </p>
            <p
              style={{
                fontSize: '19px',
                color: '#90d400',
              }}
            >
              <a style={{ color: '#90d400' }} href="tel:312 4507513">
                Cel: 312 4507513
              </a>{' '}
              <a
                style={{ color: '#90d400' }}
                href="mailto:liz.cervera@parking.net.co"
              >
                liz.cervera@parking.net.co
              </a>
            </p>
          </div>
        </Row>
      </Container>
      <FooterComponen />
    </>
  );
}
