import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datepicker/dist/react-datepicker.css';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditServicios from '../../components/modal/modalServicios';
import GoogleMaps from '../../components/mapa';
import ciudades from '../../constans/ciudades.json';
import marcas from '../../constans/marcas.json';
import motosMarcas from '../../constans/motosmarcas.json';
import modelos from '../../constans/modelos.json';
import { URL_AWS } from '../../constans/servidor';

import registro from '../../asses/img/img-registro-terpel@3x.jpg';
import registro2x from '../../asses/img/img-registro-terpel@3x.jpg';
import registro3x from '../../asses/img/img-registro-terpel@3x.jpg';
import tab1 from '../../asses/img/icon-tab1.png';
import tab12x from '../../asses/img/icon-tab1@2x.png';
import tab13x from '../../asses/img/icon-tab1@3x.png';
import tab2 from '../../asses/img/icon-tab2.png';
import tab22x from '../../asses/img/icon-tab2@2x.png';
import tab23x from '../../asses/img/icon-tab2@3x.png';
import tab3 from '../../asses/img/icon-tab3.png';
import tab32x from '../../asses/img/icon-tab3@2x.png';
import tab33x from '../../asses/img/icon-tab3@3x.png';

const RegistrobePage = () => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [tab, setTab] = useState(1);
  const [marcaSelect, setMarcaSelect] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [mensaje, setMensaje] = useState();
  const [acepto, setAcepto] = useState(false);
  const [error, setError] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const f = data.fechaNacimiento;
    const mes = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    const dia = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ];
    const fecha = `${f.getFullYear()}-${mes[f.getMonth()]}-${
      dia[f.getDate()]
    } 00:00:00`;
    try {
      const datos = {
        cedula: data.cedula,
        nombre: data.nombre,
        apellido: data.apellido,
        fechaNacimiento: fecha,
        email: data.email,
        celular: data.celular,
        genero: data.genero,
        direccion: data.direccion,
        password: data.password,
        ciudad: data.ciudad,
        codigoAlianza: 13,
        vehiculo: [
          {
            cedula: data.cedula,
            marca: data.marca,
            modelo: data.modelo,
            placa: data.placa.toUpperCase(),
            tipoVehiculo: data.tipoVehiculo,
          },
        ],
      };
      setLoadingData(true);
      const resultado = await axios.post(
        `${URL_AWS}/beParking/clienteWEB`,
        datos
      );
      toast.success('Registro exitoso...');
      analytic.logEvent('registrar_usuario', {
        page: `Registro de clientes Beparking`,
      });
      setTab(1);
      reset({
        apellido: '',
        cedula: '',
        celular: '',
        cpassword: '',
        direccion: '',
        documento: '',
        email: '',
        genero: '',
        marca: '',
        modelo: '',
        nombre: '',
        password: '',
        placa: '',
        tipoVehiculo: '',
      });

      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.log(error.response);
      toast.error(error.response.data.mensaje);
    }
  };

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Registro de clientes Beparking` });
  }, []);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row className="pbottom-40">
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
            <img
              src={registro}
              className="img-fluid mtop-30"
              srcSet={`${registro2x} 2x, ${registro3x} 3x`}
              alt="Registro BeParking"
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="be-registro">
            <h3>Regístrate</h3>
            <p>Pronto serás parte de grandes beneficios</p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={1}
                onSelect={(k) => setTab(k)}
                activeKey={tab}
              >
                <Nav variant="pills" className="be-tabs-nav">
                  <Nav.Item>
                    <Nav.Link eventKey={1}>
                      <div className="be-tab-icon">
                        <img
                          className="be-tab-icon-1"
                          src={tab1}
                          srcSet={`${tab12x} 2x, ${tab13x} 3x`}
                          alt="Tab 1"
                        />
                      </div>
                      <span>Datos Generales</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={2} disabled={tab < 2 ? true : false}>
                      <div className="be-tab-icon">
                        <img
                          className="be-tab-icon-2"
                          src={tab2}
                          srcSet={`${tab22x} 2x, ${tab23x} 3x`}
                          alt="Tab 2"
                        />
                      </div>
                      <span>Datos de Contacto</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={3} disabled={tab < 3 ? true : false}>
                      <div className="be-tab-icon">
                        <img
                          className="be-tab-icon-3"
                          src={tab3}
                          srcSet={`${tab32x} 2x, ${tab33x} 3x`}
                          alt="Tab 3"
                        />
                      </div>
                      <span>Datos del vehículo</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="be-tab-content">
                  <Tab.Pane eventKey={1}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Nombres</Form.Label>
                      <Controller
                        name="nombre"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="text"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Ingresa tus nombres"
                          />
                        )}
                      />
                      {errors.nombre && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El nombre es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Apellidos</Form.Label>
                      <Controller
                        name="apellido"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="text"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Ingresa tus apellidos"
                          />
                        )}
                      />
                      {errors.apellido && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El apellido es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Fecha Nacimiento</Form.Label>
                      <Controller
                        name="fechaNacimiento"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={new Date()}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <DatePicker
                            className="form-control"
                            selected={value}
                            dateFormat="yyyy-MM-dd"
                            onChange={(date) => onChange(date)}
                          />
                        )}
                      />
                      {errors.fechaNacimiento && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          La fecha de nacimiento es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Tipo de identificación</Form.Label>
                      <Controller
                        name="documento"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            as="select"
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Elige una opción"
                          >
                            <option>Elige una opción</option>
                            <option>Cédula de ciudadanía</option>
                            <option>Cédula de extranjería</option>
                            <option>Pasaporte</option>
                          </Form.Control>
                        )}
                      />
                      {errors.documento && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El tipo de documento es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Número de identificación</Form.Label>
                      <Controller
                        name="cedula"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="number"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Ingresa el número de tu identificación"
                          />
                        )}
                      />
                      {errors.cedula && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El número de identificación es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Género</Form.Label>
                      <Controller
                        name="genero"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            as="select"
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Elige una opción"
                          >
                            <option>Elige una opción</option>
                            <option value="M">Másculino</option>
                            <option value="F">Femenino</option>
                          </Form.Control>
                        )}
                      />
                      {errors.genero && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El tipo de documento es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Col className="text-right p-0">
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => setTab(2)}
                      >
                        Siguiente
                      </Button>
                    </Col>
                  </Tab.Pane>

                  <Tab.Pane eventKey={2}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Correo electrónico</Form.Label>
                      <Controller
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="email"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Ingresa tu correo electrónico"
                          />
                        )}
                      />
                      {errors.email && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El correo electrónico es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Contraseña</Form.Label>
                      <Controller
                        name="password"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="password"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Ingresa tu correo electrónico"
                          />
                        )}
                      />
                      {errors.password && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          La contraseña es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Confirmar contraseña</Form.Label>
                      <Controller
                        name="cpassword"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="password"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Ingresa tu correo electrónico"
                          />
                        )}
                      />
                      {watch('password') !== watch('cpassword') && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          La contraseña deben coincidir
                        </Form.Text>
                      )}
                      {errors.cpassword && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          Confirmar contraseña es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Número de celular</Form.Label>
                      <Controller
                        name="celular"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="number"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Ingresa el número de tu celular"
                          />
                        )}
                      />
                      {errors.celular && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El número de celular es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Ciudad</Form.Label>
                      <Controller
                        name="ciudad"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            as="select"
                            onChange={(value) => onChange(value.target.value)}
                            value={value}
                            placeholder="Ingresa tu ciudad"
                          >
                            <option>Seleccionar ciudad</option>
                            {ciudades.map((item, index) => {
                              return (
                                <option value={item.id}>{item.ciudad}</option>
                              );
                            })}
                          </Form.Control>
                        )}
                      />
                      {errors.ciudad && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          La ciudad es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Dirección</Form.Label>
                      <Controller
                        name="direccion"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="text"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Ingresa tu dirección"
                          />
                        )}
                      />
                      {errors.direccion && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          La dirección es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Col className="text-right p-0">
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => setTab(3)}
                      >
                        Siguiente
                      </Button>
                    </Col>
                  </Tab.Pane>
                  <Tab.Pane eventKey={3}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Tipo de vehículo</Form.Label>
                      <Controller
                        name="tipoVehiculo"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            as="select"
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Elige una opción"
                          >
                            <option>Elige una opción</option>
                            <option value="C">Carro</option>
                            <option value="M">Moto</option>
                          </Form.Control>
                        )}
                      />
                      {errors.tipoVehiculo && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El tipo de vehículo es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Placa</Form.Label>
                      <Controller
                        name="placa"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="text"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value.toUpperCase()}
                            placeholder="Ingresa la placa de tu vehículo"
                          />
                        )}
                      />
                      {errors.placa && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          La placa es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Marca</Form.Label>
                      <Controller
                        name="marca"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            as="select"
                            onChange={(value, index) => {
                              onChange(value.target.value);
                              setMarcaSelect(
                                value.target.options[
                                  value.target.options.selectedIndex
                                ].id
                              );
                            }}
                            value={value}
                            placeholder="Ingresa la marca de tu vehículo"
                          >
                            <option>Selecciona la marca de tu vehículo</option>
                            {watch('tipoVehiculo') === 'C'
                              ? marcas.map((item, index) => {
                                  return (
                                    <option value={item.Nombre} id={item.id}>
                                      {item.Nombre}
                                    </option>
                                  );
                                })
                              : motosMarcas.map((item, index) => {
                                  return (
                                    <option value={item.Nombre} id={item.id}>
                                      {item.Nombre}
                                    </option>
                                  );
                                })}
                            <option value="Otra">Otra</option>
                          </Form.Control>
                        )}
                      />
                      {errors.marca && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          La marca es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Modelo</Form.Label>
                      <Controller
                        name="modelo"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Control
                            type="number"
                            onBlur={onBlur}
                            onChange={(value) => onChange(value)}
                            value={value}
                            placeholder="Ingresa el modelo"
                          />
                        )}
                      />
                      {errors.modelo && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          El modelo es obligatorio
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                      <Controller
                        name="terminos"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={false}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Check
                            type="checkbox"
                            checked={value}
                            onChange={(value) => onChange(value)}
                            label="Acepto términos y condiciones"
                          />
                        )}
                      />
                      {errors.terminos && (
                        <Form.Text id="passwordHelpBlock" className="error">
                          Debes aceptar los terminos y condiciones
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Col className="text-right p-0">
                      <Button variant="primary" size="sm" type="submit">
                        {loadingData ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          'Registrarse'
                        )}
                      </Button>
                    </Col>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </form>

            <hr />
            {/* <Col className="text-center p-0">
                            <span className="text-gris">¿Ya eres Be Parking?</span>
                            <Button variant="primary" className="btn-borde-verde ml-3" size="sm"  type="submit">
                                Inicia sesión
                            </Button>
                        </Col> */}
          </Col>
        </Row>
      </Container>

      <FooterBeparking />
    </>
  );
};

export default RegistrobePage;
