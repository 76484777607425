import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { analytic } from '../../server/firebase';
import FooterBeparking from '../../components/footer/beparking';

import datos from '../../asses/img/datos.png';
import datos2 from '../../asses/img/contraseña.png';

const RecuperarContrasena = () => {
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState();

  useEffect(() => {
    analytic.logEvent('page_view', { page: `Landing ayuda factura` });
    userData();
  }, []);

  const userData = async () => {
    setLoading(true);
    const rest = await axios('https://ipapi.co/json/');
    setDataUser(rest.data);
    setLoading(false);
  };

  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container className="justify-content-center mtop-40 ">
        <Row className="justify-content-center mtop-40 " id="contacto">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="texto-titulo centrado">
              ¿Cómo Recuperar tu Contraseña?
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <p className=" texto-slogan ">
            Para recuperar tu contraseña sigue esta guia.
          </p>
        </Row>
      </Container>
      <div className="banner-principal">
        <img
          className="d-block w-100"
          src={datos2}
          alt="Banner facturacion electronica"
        />
      </div>
      <FooterBeparking />
    </>
  );
};
export default RecuperarContrasena;
