import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import AuthContex from '../../context/autenticacion/authContex';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { FiPower } from 'react-icons/fi';
import axios from 'axios';
import { URL_EMPLOYEE } from '../../constans/servidor';
 
const ModalActivate = ({ activate, onActivate, data }) => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
 
  const [nit, setNit] = useState('');
  const [iconColors, setIconColors] = useState([]);
 
  useEffect(() => {
    if (data && data.length > 0) {
      setIconColors(new Array(data.length).fill('red'));
    }
  }, [data]);
 
  const handlePowerClick = async (index, nitUser) => {
    let typeUser = localStorage.getItem('typeUser');
    let token = localStorage.getItem('token');
 
    const headers = {
      Authorization: `Bearer ${token}`,
    };
 
    const newIconColors = [...iconColors];
    newIconColors[index] = newIconColors[index] === 'red' ? 'green' : 'red';
    setIconColors(newIconColors);
    const body = {
      Nit: nitUser,
      typeUser: typeUser,
    };
 
    try {
      const result = await axios.post(
        URL_EMPLOYEE + `/Suppliers/inactivateClient`,
        body,
        {
          headers: headers,
        }
      );
 
      console.log(result);
 
      if (result.data.status === true) {
        toast.success(result.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.log('Hubo un error al actualizar el cliente!', error.message);
      toast.error('Hubo un error al actualizar el cliente!', 'danger');
    }
  };
 
  const searchNit = (e) => {
    e.preventDefault();
    if (nit.trim() === '.NIT') {
      toast.error('Todos los campos son obligatorios');
      return;
    }
  };
 
  useEffect(() => {}, []);
  useEffect(() => {
    //history.push(ROUTES.PROFILEUSER);
    setNit(usuario?.nit);
  }, [usuario]);
 
  return (
    <>
      <Modal
        size="md"
        show={activate}
        onHide={() => onActivate(false)}
        className="be-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Activa proveedores</Modal.Title>
        </Modal.Header>
 
        <Modal.Body>
          <Form className="conten-login" searchNit={searchNit}>
            {data && (
              <Tab.Pane eventKey="second">
                <Table className="tabla-retention">
                  <thead>
                    <tr>
                      <th>NIT</th>
                      <th>Nombre</th>
                      <th>Correo </th>
                      <th>Activar</th>
                    </tr>
                  </thead>
                  {data.map((item, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>{item.NIT}</td>
                          <td>{item.Nombre}</td>
                          <td className='td-correo'>{item.Email}</td>
                          <td>
                            <FiPower
                              size={22}
                              color={iconColors[index]}
                              onClick={() => handlePowerClick(index, item.NIT)}
                            />{' '}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
              </Tab.Pane>
            )}
            <div className="text-center">
              <Button
                onClick={() => onActivate(false)}
                variant="link"
                className="btn-enlace-gris"
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
 
export default ModalActivate;