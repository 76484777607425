import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import Banner from '../../asses/img/banner.png'
import Banner2x from '../../asses/img/banner@2x.png'
import Banner3x from '../../asses/img/banner@3x.png'
import BannerFondo from '../../asses/img/banneratras.png'
import BannerFondo2x from '../../asses/img/banneratras@2x.png'
import BannerFondo3x from '../../asses/img/banneratras@3x.png'

const bannerComponent = props => {
    return (
        <>
            <div id="trigger" />
            <div className="parking-banner">
                <Controller>
                    <Scene
                        triggerElement="#trigger"
                        offset={20}
                        duration={1000}
                    >
                        {(progress) => (
                            <Tween            
                            from={{
                                css: {
                                left: '100px'
                                },
                                ease: 'Strong.easeOut',
                            }}
                            to={{
                                css: {
                                left: '-50px'
                                },
                                ease: 'Strong.easeOut',
                            }}
                            totalProgress={progress}
                            paused
                            >
                                <img src={BannerFondo}
                                    srcSet={`${BannerFondo2x} 2x, ${BannerFondo3x} 3x`}
                                    className="baner-img-fondo"
                                    alt="ilustracion Parking"
                                />
                            </Tween>    
                        )}
                    </Scene>
                </Controller>
                <img src={Banner}
                    srcSet={`${Banner2x} 2x, ${Banner3x} 3x`}
                    className="baner-img"
                    alt="ilustracion Parking"
                />
            </div>
        </>
    );
};

export default bannerComponent;