import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContex from '../../context/autenticacion/authContex';

import * as ROUTES from '../../constans/rutas';

const RutaPrivada = ({ component: Component, ...props }) => {
  const authContex = useContext(AuthContex);
  const { autenticado, cargando, usuarioAutenticado } = authContex;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  return (
    <Route
      {...props}
      render={(props) =>
        !autenticado && !cargando ? (
          <Redirect to={ROUTES.EMPRESA} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default RutaPrivada;
