import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { analytic } from '../../server/firebase';
import FooterComponen from '../../components/footer';
import afiliate from '../../asses/img/afiliate.png';
import contact from '../../asses/img/contact.png';
import factura from '../../asses/img/factura.png';
import certificado_ret from '../../asses/img/certificado_ret.png';
import pagodigital from '../../asses/img/pagodigital.png';
import click5 from '../../asses/img/Click_5.png';

import * as ROUTES from '../../constans/rutas';

const ProtalPage = () => {
  const [loading, setLoading] = useState(false);

  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container>
        <Row className="justify-content-center mtop-40 ">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="texto-titulo">
              TODO EN UN SOLO LUGAR
              <br />
              <small>Bienvenido/a a nuestro portal Parking</small>
            </h3>
            <p className="mtop-50">
              Elige a continuación la opción que estas buscando:
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center mbottom-40">
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className="mbottom-30">
            <div className="card-portal">
              <img
                className="d-block w-100"
                src={afiliate}
                alt="Afiliate a beparking"
              />
              <NavLink className="enlace-click" to={ROUTES.BEREGISTRO}>
                <div className="content-click">
                  <img src={click5} alt="Imagen click" />
                </div>
              </NavLink>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className="mbottom-30">
            <div className="card-portal">
              <img
                className="d-block w-100"
                src={factura}
                alt="Quiero mi factura electronica"
              />
              <NavLink className="enlace-click" to={ROUTES.LANDINGFACTURACION}>
                <div className="content-click">
                  <img src={click5} alt="Imagen click" />
                </div>
              </NavLink>
            </div>
          </Col>
          {/* <Col xs={12} sm={6} md={6} lg={6} xl={6} className="mbottom-30">
                        <div className="card-portal">
                            <img
                                className="d-block w-100"
                                src={pagodigital}
                                alt="Usar pago digital"
                            />
                            <a  
                            className="enlace-click"
                            target="_blank"
                            href="https://pagodigital.parking.net.co/">
                                <div className="content-click">
                                    <img
                                        src={click5}
                                        alt="Imagen click"
                                    />
                                </div>
                            </a>
                        </div>
                    </Col> */}

          <Col xs={12} sm={6} md={6} lg={6} xl={6} className="mbottom-30">
            <div className="card-portal">
              <img
                className="d-block w-100"
                src={contact}
                alt="Quiero mi factura electronica"
              />
              <NavLink className="enlace-click" to={ROUTES.CONTACTO}>
                <div className="content-click">
                  <img src={click5} alt="Imagen click" />
                </div>
              </NavLink>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className="mbottom-30">
            <div className="card-portal">
              <img
                className="d-block w-100"
                src={certificado_ret}
                alt="Solicita tu certificado de retención aquí"
              />
              <NavLink className="enlace-click" to={ROUTES.CREATEUSER}>
                <div className="content-click">
                  <img src={click5} alt="Imagen click" />
                </div>
              </NavLink>
            </div>
          </Col>
        </Row>
      </Container>

      <FooterComponen />
    </>
  );
};

export default ProtalPage;
